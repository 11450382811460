import React, {useEffect, useState} from 'react';
import styles from './BottomMenu.module.scss'
import {ReactComponent as HomeNotSelected} from "../../../assets/images/svg/HomeIconMobileNotSelected.svg";
import {ReactComponent as SearchNotSelected} from "../../../assets/images/svg/SearchIconMobileNotSelected.svg";
import {ReactComponent as SearchSelected} from "../../../assets/images/svg/SearchSelectedIcon.svg";
import {ReactComponent as FavoriteNotSelected} from "../../../assets/images/svg/FavoriteIconMobileNotSelected.svg";
import {ReactComponent as FavoriteSelected} from "../../../assets/images/svg/FavoriteSelected.svg";
import {ReactComponent as BookingNotSelected} from "../../../assets/images/svg/BookingIconMobileNotSelected.svg";
import {ReactComponent as BookingSelected} from "../../../assets/images/svg/BookingSelected.svg";
import {ReactComponent as AccountNotSelected} from "../../../assets/images/svg/AccountIconMobileNotSelected.svg";
import {ReactComponent as AccountSelected} from "../../../assets/images/svg/AccountSelected.svg";
import {ReactComponent as UnlockIcon} from "../../../assets/images/svg/UnlockKeyIcon.svg";
import {ReactComponent as FavoriteDisabled} from "../../../assets/images/svg/FavoriteIconMobileDisabled.svg";
import {ReactComponent as BookingDisabled} from "../../../assets/images/svg/BookingIconMobileDisabled.svg";
import {ReactComponent as HomeSelected} from "../../../assets/images/svg/SelectedIconMobileSelected.svg";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {fetchOrdersAsync} from "../../../reducers/orderSlice";
import LoaderComponent from "../Loader/Loader.component";
import {ReactComponent as Key} from "../../../assets/images/svg/KeyBlack.svg";

const UnlockButton = (props: {handler: any, nearestTimeStart: any}) => {
  return (
      <div className={styles.unlockButton} onClick={props.handler}>
          <UnlockIcon />
          <span>Open</span>
          {/*<span>{(Math.floor((props.nearestTimeStart - new Date().getTime())/1000))}</span>*/}
      </div>
  )
}

const BottomMenu = () => {
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const bookingState = useSelector((state: RootState)=>state.orders.futureOrders)
    const [showUnlockButton, setShowUnlockButton] = useState<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [nearest, setNearest] = useState<any>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    // Access the pathname from the location object
    const currentPath = location.pathname;

    useEffect(()=>{
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus())
        if (user && user._id) {
            // @ts-ignore
            dispatch(fetchOrdersAsync(user._id))
        }
    },[dispatch])

    useEffect(()=>{
        if (user && user._id) {
            // @ts-ignore
            dispatch(fetchOrdersAsync(user._id))
        }
    }, [user])

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (bookingState && bookingState.length > 0) {
            bookingState.filter(book=>book.slots[0].date)
            let nearest = bookingState[bookingState.length - 1].slots[0];
            // Convert '00' to 0 for start and '00' to 24 for end
            let startHour: number = nearest.start === '00' ? 0 : parseInt(nearest.start);
            let endHour: number = nearest.end === '00' ? 24 : parseInt(nearest.end);

            let nearestTimeStart = new Date(nearest.date);
            nearestTimeStart.setHours(startHour, 0, 0);

            setNearest(nearestTimeStart);

            let nearestTimeEnd = new Date(nearest.date);
            nearestTimeEnd.setHours(endHour, 0, 0);

            interval = setInterval(() => {
                const currentDateTime = new Date();

                const userTimeZoneOffset = -120;
                const userLocalDateTime = new Date(currentDateTime.getTime() - userTimeZoneOffset * 60 * 1000);


                if (Math.floor((Number(nearestTimeStart) - new Date().getTime()) / 1000) <= 5) {
                    setShowUnlockButton(true);
                }
                if (Math.floor((Number(nearestTimeEnd) - new Date().getTime()) / 1000) <= 300) {
                    setShowUnlockButton(false);
                }
            }, 1000);
        }
        return () => clearInterval(interval as NodeJS.Timeout);
    }, [bookingState]);




    function handleAccountClick() {
        if (user && user._id) {
            navigation('/private')
        } else {
            navigation('/signin')
        }
    }

    function handleBookingClick() {
        if (user && user._id) {
            navigation('/private/bookings')
        }
    }

    function handleFavoritesClick() {
        if (user && user._id) {
            navigation('/private/favorites')
        }
    }

    function unlockRoom() {
       try {
           let data = {
               userId: user._id,
               roomID: bookingState[bookingState.length-1].roomID._id,
               orderID: bookingState[bookingState.length-1]._id
           }
           setShowLoader(true)

           axios.post('https://gscoworking.com/api/room/unlock', data).then((answer: any)=>{
               setLoading(false)

           })
       } catch (e: any) {
           console.log(e.message)
       }

    }

    function closeLoader() {
        setShowLoader(false)
        setLoading(true)
    }

    const LoaderContent = () => {
        return (
            <div className={'col gap-10 align-items-center'}>
                <span className={'text-14 text-align-center text-white'}>Door opened</span>
                <span className={'text-14 text-align-center text-white'}>If door not open, please use passcode</span>
                <div className={'col align-items-center gap-10 pa-0'}>
                    <div className={'keycode-wrapper row align-items-center gap-15'}>
                        <Key />
                        <span className={'text-black'}>{bookingState[bookingState.length-1].keyCode}</span>
                    </div>
                    <span className={'magenta-text'} >Also you can find code on e-mail </span>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.menu}>
            <div className={'col justify-content-center align-items-center gap-10'} onClick={()=>navigation('/')}>
                {currentPath === '/' && (
                    <HomeSelected />
                )}
                {currentPath !== '/' && (
                    <HomeNotSelected />
                )}
                <span className={currentPath === '/' ? `${styles.menuText} ${styles.sea}` : `${styles.menuText}`}>Home</span>
            </div>
            <div className={'col gap-10 justify-content-center align-items-center'} onClick={()=>navigation('/search')}>
                {currentPath.includes('/search') && (
                    <SearchSelected />
                )}
                {!currentPath.includes('/search') && (
                    <SearchNotSelected />
                )}
                <span className={currentPath.includes('/search') ? `${styles.menuText} ${styles.sea}` : `${styles.menuText}`}>Search</span>
            </div>
            {(!showUnlockButton || !user) && (
                <div className={`col gap-10 justify-content-center align-items-center ${!user ? styles.disabledItem : ''}`} onClick={handleFavoritesClick}>
                    {currentPath === '/private/favorites' && (
                        <FavoriteSelected />
                    )}
                    {currentPath !== '/private/favorites' && (
                        <FavoriteNotSelected />
                    )}
                    <span className={currentPath === '/private/favorites' ? `${styles.menuText} ${styles.sea}` : `${styles.menuText}`}>Favorite</span>
                </div>
            )}
            {showUnlockButton && user && (
                <UnlockButton handler={()=>unlockRoom()}  nearestTimeStart={nearest}/>
            )}
            <div className={`col gap-10 justify-content-center align-items-center  ${!user ? styles.disabledItem : ''}`} onClick={handleBookingClick}>
                {currentPath === '/private/bookings' && (
                    <BookingSelected />
                )}
                {currentPath !== '/private/bookings' && (
                    <BookingNotSelected />
                )}
                <span className={currentPath === '/private/bookings' ? `${styles.menuText} ${styles.sea}` : `${styles.menuText}`}>Booking</span>
            </div>
            <div className={'col gap-10 justify-content-center align-items-center'}  onClick={handleAccountClick}>
                {currentPath === '/private' && (
                    <AccountSelected />
                )}
                {currentPath !== '/private' && (
                    <AccountNotSelected />
                )}
                <span className={currentPath === '/private' ? `${styles.menuText} ${styles.sea}` : `${styles.menuText}`}>Account</span>
            </div>
            {showLoader && (
                <LoaderComponent callback={closeLoader} button={'Ok'} loading={loading}>
                    <LoaderContent />
                </LoaderComponent>
            )}
        </div>
    );
};

export default BottomMenu;
export {};
