import React from 'react';
import BreadcrumbsComponent from "../components/common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";

const CancelationPolicyPage = () => {
    const crumbs = [
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Cancellation and refund policy',
            link: '/cancellation'
        }
    ]
    return (
        <div className={'wrapper col gap-30 '}>
            <BreadcrumbsComponent crumbs={crumbs}/>
            <PageTitleComponent backLink={'/'} title={'Cancellation and refund policy'} />
           <div className={'col-12 col gap-10 mt-20'}>
               <p className={'text-14'}>Please be aware that once we've confirmed your appointment, we allocate specific time slots in our schedule to accommodate your needs. You have the flexibility to edit your booking up to 24 hours prior to the scheduled start time. </p>
               <p className={'text-14'}>However, if you need to cancel your appointment with less than 24 hours' notice, a 100% cancellation fee will be applied.</p>
               <p className={'text-14'}>To avoid incurring the cancellation fee, we kindly request that you provide us with at least 24 hours' notice if you need to cancel your booking.
               </p>
               <p className={'text-14'}>You have the option to cancel your booking through the 'Bookings' section in your personal account, or you can contact us via email or <a className={'blue-text pointer'} href={'https://t.me/gosound_coworking'}>Telegram</a>.
               </p>
               <p className={'text-14'}>Once the cancellation is processed, the refunded amount will be credited back to the card used for the initial payment.
               </p>
           </div>
        </div>
    );
};

export default CancelationPolicyPage;
export {};
