import React, {useState} from 'react';
import styles from './RoomCard.module.scss'
import {ReactComponent as ChevronOutline} from '../../../../assets/images/svg/ChevroneRightOutlined.svg';
import {ReactComponent as ChevronRight} from '../../../../assets/images/svg/ChevronRight.svg';
import {ReactComponent as ChevronLeft} from '../../../../assets/images/svg/ChevronLeft.svg';

const InCardImageSliderComponent = (props:{images: any[]}) => {
    const [currentImage, setCurrentImage] = useState<number>(0);
    function nextImage() {
        if (currentImage===props.images.length-1) {
            setCurrentImage(0)
            return
        }
        setCurrentImage(old=>old+1)
    }
    function prevImage() {
        if (currentImage===0) {
            setCurrentImage(props.images.length-1)
            return
        }
        setCurrentImage(old=>old-1)
    }

    return (
        <div className={`relative ${styles.inCardImage}`} style={{ backgroundImage: `url(https://gscoworking.com/${props.images[currentImage]})` }}>
            <div className={`${styles.buttonLeft} center-content`} onClick={prevImage}>
                <ChevronLeft />
            </div>
            <div className={`${styles.buttonRight} center-content`} onClick={nextImage}>
                <ChevronRight />
            </div>
            <div className={`row gap-4 justify-content-center ${styles.dots}`}>
                {props.images.map((dot, idx) => (
                    <div
                        key={idx}
                        className={`${idx === currentImage ? styles.dot : styles.inactiveDot} pointer`}
                        onClick={() => setCurrentImage(old => idx)}
                    />
                ))}
            </div>
        </div>
    );
};

export default InCardImageSliderComponent;
export {};
