import React from 'react';
import BreadcrumbsComponent from "../components/common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import {Frame} from "../components/desktop/Privacy.component.tsx/Privacy.component";

const PrivacyPage = () => {
    const crumbs = [
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Privacy policy',
            link: '/privacy'
        }
    ]
    return (
        <div className={'wrapper col gap-30'}>
            <BreadcrumbsComponent crumbs={crumbs}/>
            <PageTitleComponent backLink={'/'} title={'Privacy policy'} />
            <div className={'col-15'}>
                <Frame />
            </div>
        </div>
    );
};

export default PrivacyPage;
export {};
