import React from 'react';
import styles from './PageTitle.module.scss'
import {ReactComponent as BackArrow} from "../../../assets/images/svg/BackChevron.svg";
import {useNavigate} from 'react-router-dom'

const PageTitleComponent = (props: {backLink: string, title: string}) => {
    const navigation = useNavigate()
    return (
        <div className={'row align-items-center'} >
            <BackArrow className={'mr-20 pointer'} onClick={()=>navigation(props.backLink)}/>
            <span className={'h2-text'}>{props.title}</span>
        </div>
    );
};

export default PageTitleComponent;
export {};
