import {io, Socket} from "socket.io-client";

const socket: Socket = io('https://gscoworking.com', {
    path: '/socket.io/',
    transports: ['websocket'],
    forceNew: false,
    reconnection: true

}); // Подключение к серверу Socket.IO

export default socket
