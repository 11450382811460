import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from './types'; // Import the RootState type


export interface Slot {
    start: number;
    end: number;
    date: string;
    amOrPm: string;
}


export interface BookingState {
        buildingID: string | null;
        roomID: string | null;
        userID: string | null;
        date: Date;
        slots: Slot[],
        amount: number | null
}

const initialState: BookingState =  {
        buildingID: null,
        roomID: null,
        userID: null,
        date: new Date(),
        slots: [],
        amount: 0
}

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        saveBookedSlots: (state, action: PayloadAction<BookingState>) => {
            state.buildingID = action.payload.buildingID;
            state.roomID = action.payload.roomID;
            state.userID = action.payload.userID;
            state.date = action.payload.date;
            state.slots = action.payload.slots;
            state.amount = action.payload.amount;
        },
        clearBooking: (state) => {
            state.buildingID = null;
            state.roomID = null;
            state.date = new Date();
            state.slots = [];
            state.amount = 0
        },
    },
})

export const { saveBookedSlots, clearBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
 export {};
