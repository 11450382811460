import React from 'react';
import BreadcrumbsComponent from "../components/common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import styles from "../components/desktop/Footer/Footer.module.scss";
import {ReactComponent as Telegram} from "../assets/images/svg/TelegramWhite.svg";
import {ReactComponent as Whatsapp} from "../assets/images/svg/Whatsapp.svg";
import {ReactComponent as MapPin} from "../assets/images/svg/PinWhite.svg";
import {ReactComponent as PhoneIcon} from "../assets/images/svg/PhoneWhite.svg";
import {useNavigate} from "react-router-dom";
import MapComponent from "../components/common/Map/Map.component";

const ContactsPage = () => {
    const crumbs = [
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Contacts',
            link: '/contacts'
        }
    ]
    const navigation = useNavigate()
    return (
        <div className={'wrapper col gap-30'}>
            <BreadcrumbsComponent crumbs={crumbs} />
            <PageTitleComponent backLink={'/'} title={'Contacts'} />
            <div className={'row gap-30'}>
                <div className={'col col-11'}>
                    <span className={'mt-25'}>For faster communication write us on telegram, we’re looking forward for your questions</span>
                    <div className={'row gap-15'}>
                        <a className={`mt-20 row align-items-center justify-content-center ${styles.telegram}`} onClick={()=>{window.location.href='https://t.me/gosound_coworking'}}>
                            <span>Telegram</span>
                            <Telegram />
                        </a>
                        <a className={`mt-20 row align-items-center justify-content-center ${styles.whatsapp}`} onClick={()=>{window.location.href='https://wa.me/35795965784'}}>
                            <span>Whatsapp</span>
                            <Whatsapp />
                        </a>
                    </div>
                    <div className={'row align-items-center gap-20 mt-50'}>
                        <MapPin />
                        <span className={'text-16'}>Kolokotroni 28, Limassol</span>
                    </div>
                    <a href={'mailto:hello@gscoworking.com'} className={'row align-items-center gap-20 mt-20 text-white'} style={{textDecoration: "none"}}>
                        <PhoneIcon />
                        <span className={'text-16'}>hello@gscoworking.com</span>
                    </a>
                    <div className={'red-button row center-content pointer mt-21'} style={{width: 220}} onClick={()=>navigation(`/spots`)}>
                        <span className={'red-button-text'}>Book</span>
                    </div>
                </div>
                <div className={'col col-12 pt-30 mb-30'}>
                    <MapComponent width={572} height={466} />
                </div>
            </div>
        </div>
    );
};

export default ContactsPage;
export {};
