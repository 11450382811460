import React from 'react';
import styles from './MobileApp.module.scss'
import BottomMenu from "../BottomMenu/BottomMenu";
import TopBarComponent from "../TopBar/TopBar";
import {ReactComponent as ScrollToTop} from "../../../assets/images/svg/ScrollToTop.svg";

import AppContent from "./AppContent";
import RouteChangeTracker from "../../RouteChangeTracker";

const MobileApp = () => {
    function scrollToTop() {
        window.scroll({top: 0, behavior: 'smooth'})
    }

    return (
        <div>
            <RouteChangeTracker />
            <AppContent />
            <ScrollToTop className={styles.scrollToTop} onClick={scrollToTop}/>
            <BottomMenu/>
        </div>
    );
};

export default MobileApp;
export {};
