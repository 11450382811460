import React from 'react';
import styles from './OnMapInfoWindow.module.scss'
import {ReactComponent as PinIcon} from "../../../assets/images/svg/PinBlack.svg";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconBlack.svg";
import {ReactComponent as MusicIcon} from "../../../assets/images/svg/MusicIcon.svg";

const OnMapInfoWindowComponent = (props: {address: string, options: any[], handleBook: any, handleClose?:any}) => {
    return (
        <div className={`${styles.container} col relative`}>
            <CloseIcon style={{position: 'absolute', right: 20, top: 20}} className={'pointer'} onClick={props.handleClose}/>
            <div className={'row align-items-center mb-20'}>
                <PinIcon className={'mr-15'} />
                <span className={'text-black main-text'}>{props.address}</span>
            </div>
            <div className={'row align-items-center'}>
                <MusicIcon  className={'mr-15'} />
                <span className={'text-black main-text'}>{props.options.join(', ')}</span>
            </div>
            <div style={{width: '100%'}} className={'mt-30 row justify-content-center'}>
                <div className={'red-button row center-content pointer'} style={{width: 154}}>
                    <span className={'red-button-text'}>Book</span>
                </div>
            </div>

        </div>
    );
};

export default OnMapInfoWindowComponent;
export {};
