import React, {useState} from 'react';
import TopBar from "../TopBar/TopBar";
import LoginRegisterComponent from "./LoginRegister/LoginRegister.component";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    const navigation = useNavigate()
    const [loginRegisterMode, setLoginRegisterMode] = useState<any>('login')

    function handleChangeMode(mode: string) {
        setLoginRegisterMode(mode)
    }
    return (
        <div>
            <TopBar backLink={'/'} />
            <div className={'col wrapper px-20 justify-content-center'}>
                    <LoginRegisterComponent mode={loginRegisterMode} loginSuccess={()=>navigation('/')}  changeMode={handleChangeMode}/>
                <div></div>
            </div>
        </div>
    );
};

export default LoginPage;
export {};
