import React from 'react';
import styles from './Header.module.scss'
import {useNavigate} from "react-router-dom";

const HeaderComponent = () => {
    const navigation = useNavigate()
    return (
        <div className={styles['overlay-image']}>
            {/*<div className={`col align-items-center justify-content-center ${styles['comingSoon']}`}>*/}
            {/*    <p className={'text-price ma-0'}>Gift Certificates</p>*/}
            {/*</div>*/}
            <img className={`col gap-5 align-items-center justify-content-center ${styles['header-image']}`} src={require('../../../assets/images/headerImageClean.png')}/>
            <div className={`col justify-content-center ${styles['head-wrapper']}`}>
                <span className={`${styles['head-phrase']} heading`}>Rent music-point of your dreams</span>
                <div className={'row align-items-center gap-20'}>
                    <div className={`mt-20 ${styles['header-button']} red-button pointer row justify-content-center align-items-center`} style={{width: 154, boxShadow: 'none'}} onClick={()=>navigation('/spots/')}>
                        <span className={'red-button-text'}>Choose spot</span>
                    </div>
                    <div className={`mt-20  sea-button pointer row justify-content-center align-items-center`} style={{width: 154, boxShadow: 'none'}} onClick={()=>navigation('/buy-certificate')}>
                        <span className={'red-button-text'}>Gift Certificates</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderComponent
export {};
