import React from 'react';
import MyFundsComponent from "./funds/MyFunds.component";

const MyFundsPage = () => {
    return (
        <MyFundsComponent />
    );
};

export default MyFundsPage;
