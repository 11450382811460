import React, {useEffect, useState} from 'react';
import HeaderComponent from "../components/desktop/MainPage/Header.component";
import SearchComponent from "../components/common/SearchComponent/Search.component";
import AboutUsComponent from "../components/desktop/MainPage/AboutUs.component";
import PopularSpotsComponent from "../components/desktop/MainPage/PopularSpots.component";
import MapSectionComponent from "../components/desktop/MainPage/MapSection.component";
import GDPRComponent from "../components/common/GDPR/GDPR.component";
import ForgotPasswordPopup from "../components/common/Popup/ForgotPassword.popup";
import {useLocation} from "react-router-dom";
import RestorePasswordPopup from "../components/common/Popup/RestorePassword.popup";
import PopupComponent from "../components/common/Popup/Popup.component";

const MainPage = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const recovery = searchParams.get('recovery')
    const [token, setToken] = useState<string>('')
    const [successRecovery, setSuccessRecovery] = useState<boolean>(false)
    const [errorRecovery, setErrorRecovery] = useState<boolean>(false)
    const [gdpr, setGdpr] = useState(true)
    useEffect(()=>{
        getGDPRStatus();
        if (recovery) {
            setToken(recovery)
        }
    }, [])

    function getGDPRStatus() {
        try {
            const gdprValue = localStorage.getItem('@gdpr');
            // Do something with the gdprValue
            if (gdprValue === 'accepted') {
                setGdpr(true)
            } else {
                setGdpr(false)
            }
        } catch (e: any) {
            console.log(e.message)
            setGdpr(false)
        }
    }

    function _handleAcceptGDPR() {
        try {

        } catch (e: any) {

        }
        setGdpr(true)
    }

    function _handleDenyGDPR() {
        try {
            localStorage.setItem('@gdpr', 'declined')
            setGdpr(true)
        }  catch (e: any) {
            console.log(e.message)
        }

    }

    return (
        <div style={{marginLeft: 0}}>
            <HeaderComponent />
            <SearchComponent />
            <AboutUsComponent />
            <PopularSpotsComponent />
            <MapSectionComponent />
            {!gdpr && (
                <GDPRComponent  accept={_handleAcceptGDPR} deny={_handleDenyGDPR}/>
            )}
            {token && (
                <RestorePasswordPopup onClose={()=>setToken('')}  token={token} error={()=>setErrorRecovery(true)} success={()=>setSuccessRecovery(true)}/>
            )}
            {successRecovery && (
                <PopupComponent title={'Password changed'} text={'You password was changed success'} onClose={()=>setSuccessRecovery(false)} buttonText1={'Ok'} onButton1Click={()=>setSuccessRecovery(false)} />
            )}
            {errorRecovery && (
                <PopupComponent title={'Password recovery error'} text={'Error occurred during password recovery. Check provided data and try again'} onClose={()=>setErrorRecovery(false)} buttonText1={'Ok'} onButton1Click={()=>setErrorRecovery(false)} />
            )}
        </div>
    );
};

export default MainPage;
export {};
