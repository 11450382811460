import React, {Component} from 'react';
import styles from '../assets/scss/NotFound.module.scss'
import { ReactComponent as Four } from '../assets/images/svg/FourIcon.svg'
import { ReactComponent as Zero } from '../assets/images/svg/ZeroIcon.svg'
import { ReactComponent as LeftPlug } from '../assets/images/svg/LeftPlug.svg'
import { ReactComponent as RightPlug } from '../assets/images/svg/RightPlug.svg'
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigation = useNavigate()
    function handleClick() {
        navigation('/')
    }
        return (
            <div className={`${styles.container} center-content col-24 col full-height`}>
                <div className={'row'}>
                    <Four />
                    <Zero />
                    <Four />
                </div>
                <div className={`row ${styles.plugsWrapper} mb-50`}>
                    <LeftPlug />
                    <RightPlug />
                </div>
                <span className={styles.text}>Sorry, Page Not Found</span>
                <div className={'red-button row align-content-center justify-content-center mt-70 pointer'} onClick={handleClick}>
                    <span className={'red-button-text'}>Back to main page</span>
                </div>
            </div>
        );
}

export default NotFoundPage;
export {};
