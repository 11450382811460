import React, {useEffect, useState} from 'react';
import styles from './TopBar.module.scss'
import {ReactComponent as Logo} from '../../../assets/images/svg/Logo.svg';
import {ReactComponent as Bell} from '../../../assets/images/svg/BellIcon.svg';
import {ReactComponent as BackIcon} from '../../../assets/images/svg/MobileBack.svg';
import {ReactComponent as Hamburger} from '../../../assets/images/svg/Hamburger.svg';
import {ReactComponent as HamburgerClose} from '../../../assets/images/svg/CloseIconWhite.svg';
import {useNavigate, useNavigation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice";
import {ReactComponent as MapPin} from "../../../assets/images/svg/PinWhite.svg";
import {ReactComponent as PhoneIcon} from "../../../assets/images/svg/PhoneWhite.svg";
import {ReactComponent as Telegram} from "../../../assets/images/svg/TelegramWhite.svg";
import {ReactComponent as Whatsapp} from "../../../assets/images/svg/Whatsapp.svg";
import {ReactComponent as Instagram} from "../../../assets/images/svg/InstaWhite.svg";
import {ReactComponent as Facebook} from "../../../assets/images/svg/FaceBookWhite.svg";
import {ReactComponent as VK} from "../../../assets/images/svg/VK.svg";
import {ReactComponent as FooterCards} from "../../../assets/images/svg/CardsMobile.svg";
import {ReactComponent as Clock} from "../../../assets/images/svg/ClockIconWhite.svg";


const TopBar = (props: {backLink?: string, MiddlePart?: any, backPressHandler?: any}) => {
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const bookingState = useSelector((state: RootState)=>state.orders.futureOrders)
    const navigation = useNavigate()
    const [notReadCount, setNotReadCount] = useState(0)
    const [menuShown, setMenuShown] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        if (menuShown) {
            document.body.classList.add('popup-open');
        } else {
            document.body.classList.remove('popup-open')
        }
    }, [menuShown])

    useEffect(()=>{
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus())
    },[dispatch])

    useEffect(()=>{
        if (user && user.notifications) {
            const unreadNotifications = user.notifications.filter(
                (notification: { isRead: any }) => !notification.isRead
            );
            setNotReadCount(unreadNotifications.length)
        }
    }, [user])



    return (
        <>
            <div className={`justify-content-end align-items-center ${styles.topBar} row`}>
                <div className={'row col-24 justify-content-between align-items-center px-10'}>
                    <div>
                        {props.backLink && (
                            <BackIcon className={'mt-20 ml-10'} onClick={()=> {
                                if (props.backPressHandler !== undefined) {
                                    props.backPressHandler()
                                } else {
                                    if (props.backLink) {
                                        navigation(props.backLink)
                                    } else {
                                        navigation('/')
                                    }
                                }

                            }} />
                        )}
                        {!props.backLink && !menuShown && (
                            <Hamburger className={'mt-20 ml-10'} onClick={()=> setMenuShown(true)} />
                        )}
                        {!props.backLink && menuShown && (
                            <HamburgerClose className={'mt-20 ml-10'} onClick={()=> setMenuShown(false)} />
                        )}
                    </div>
                    {!props.MiddlePart && (
                        <Logo className={''} onClick={()=>navigation('/')}/>
                    )}
                    {props.MiddlePart && (
                        <span className={'text-price mt-20'}>{props.MiddlePart}</span>
                    )}
                    <div className={'relative mt-20'} onClick={()=>{
                        if (user && user._id) {
                            navigation('/private?tab=3')
                        } else {
                            navigation('/signin')
                        }
                    }}>
                        {notReadCount > 0 && (
                            <div style={{width: 16, height: 16, background: '#D53B5C', borderRadius: 8, position: 'absolute', right: 10, top: -8}} className={'row align-items-center justify-content-center'} >
                                <span className={'text-10'}>{notReadCount}</span>
                            </div>
                        )}
                        <Bell className={'pointer mr-20'} />
                    </div>
                </div>
                {menuShown && (
                    <div className={styles.menu}>
                        <div className={'col gap-30 justify-content-between'}>
                            <span className={'pointer sea-text'} onClick={()=>navigation('/spots')}>All spots</span>
                            <span className={'pointer sea-text'} onClick={()=>navigation('/partners')}>Partners</span>
                            <span className={'pointer sea-text'} onClick={()=>navigation('/buy-certificate')}>Certificates & Deposits</span>
                            {/*new menu 1*/}
                            <span className={'pointer sea-text'} onClick={()=>navigation('/how-it-works')}>How it works</span>
                            <span className={'pointer sea-text'} onClick={()=>navigation('/about-us')}>About us</span>
                            <span className={'pointer sea-text'} onClick={()=>navigation('/contacts')}>Contacts</span>
                            <div className={'mt-0'}>
                                <div className={'row align-items-center gap-20  mt-8'}>
                                    <MapPin />
                                    <span className={'text-16'}>Kolokotroni 28, Limassol</span>
                                </div>
                                <a href={'mailto:hello@gscoworking.com'} className={'row align-items-center gap-20 mt-18 text-white'} style={{textDecoration: "none"}}>
                                    <PhoneIcon />
                                    <span className={'text-16'}>hello@gscoworking.com</span>
                                </a>
                                <div className={'row align-items-center mt-20 gap-20'}>
                                    <Clock/>
                                    <span>08:00 - 23:00</span>
                                </div>
                                <div className={'row mt-30 gap-10'}>
                                    <div onClick={()=>{window.location.href='https://t.me/gosound_coworking'}} className={`mt-10  row align-items-center justify-content-center ${styles.telegram}` }>
                                        <span>Telegram</span>
                                        <Telegram />
                                    </div>
                                    <div onClick={()=>{window.location.href='https://wa.me/35795965784'}} className={`mt-10  row align-items-center justify-content-center ${styles.whatsapp}` }>
                                        <span>Whatsapp</span>
                                        <Whatsapp />
                                    </div>
                                </div>
                                <div className={`mt-20 row align-items-center gap-20`}>
                                    <Instagram className={'pointer'} />
                                    <Facebook className={'pointer'}  onClick={()=>{window.location.href = 'https://www.facebook.com/profile.php?id=100093960093270'}}/>
                                    <VK className={'pointer'} />
                                </div>
                                <div className={'row pointer mt-20'}  onClick={()=>navigation(`/spots`)}>
                                    <FooterCards />
                                </div>
                            </div>
                            <div className={'col mt-10 gap-10'}>
                                <div className={'row justify-content-between'}>
                                    <span className={'text-14 light-gray underline pointer'} onClick={()=>navigation('/privacy')}>Privacy policy </span>
                                    <span className={'text-14 light-gray underline pointer'} onClick={()=>navigation('/terms')}>Terms of use </span>
                                    <span className={'text-14 light-gray underline pointer'} onClick={()=>navigation('/cancellation')}>Cancellation policy </span>
                                </div>
                                <span className={'text-14 light-gray pointer'} onClick={()=>navigation('/')}>© GoSound 2023 </span>
                                <p className={'text-12 light-gray pointer'} >GoSound is a music spaces rental service with simple convenient booking system. Rooms are automatically accessed through the smart locks controlled by web application. We guarantee the security of payments and the quality of our services.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/*<div style={{width: '100vw', height: 48, display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-around', alignItems: 'center', background: '#76CCCC'}}>*/}
            {/*    <span className={'text-price'}>Opening</span>*/}
            {/*    <span className={'main-text text-16'}>September 17</span>*/}
            {/*</div>*/}
        </>
    );
};

export default TopBar;
export {};
