import React, {useEffect, useState} from 'react';
import styles from './events.module.scss'
import {ReactComponent as RightArrow} from "../../../assets/images/svg/RightArrow.svg";
import {ReactComponent as LeftArrow} from "../../../assets/images/svg/LeftArrowTransparent.svg";
import InputMask from "react-input-mask";
import LoaderComponent from "../Loader/Loader.component";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconWhite.svg";
import axios from "axios";

interface EventsComponentProps {
    events: any[];
}

const EventsComponent: React.FC<EventsComponentProps> = ({ events })  => {
    const [currentEvent, setCurrentEvent] = useState<any>(null)
    const [index, setIndex] = useState<any>(0)
    const [showPopup, setShowPopup] = useState<boolean>(false)
// for git
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [booked, setBooked] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');
    const [loader, setLoader] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>(null)
    function getFormattedDate(time:any) {
        const date = new Date(time);

        const day = String(date.getDate()).padStart(2, '0');

        // Array of month names
        const monthNames = [
            'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL',
            'MAY', 'JUNE', 'JULY', 'AUGUST',
            'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
        ];

        const month = monthNames[date.getMonth()];
        const formattedDate = `${day} ${month}`;

        return formattedDate;
    }

    function getFormattedTime(time:any) {
        const date = new Date(time);

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    function prevEvent() {
        if (index===0) {
            setIndex(events.length-1)
            return
        }
        setIndex((oldIndex: number)=>oldIndex-1)
    }

    function nextEvent() {
        if (index===events.length-1) {
            setIndex(0)
            return
        }
        setIndex((oldIndex: number)=>oldIndex+1)
    }
    useEffect(()=>{
        if (events && events.length > 0) {
            setCurrentEvent(events[index])
        }
    }, [index])

    useEffect(()=>{
        if (events && events.length > 0) {
            setCurrentEvent(events[index])
        }
    }, [])

    async  function sendEventEmail() {
        setLoader(true)
        const apiUrl = 'https://gscoworking.com/api/events/request';

        const answer:any = await axios.post(apiUrl, {
            email, phone, message, name
        }).catch((e: any)=>{
            setError({message: 'Some error occurred'})
        })
        if (answer && answer.data) {
            setError({message: 'Your request was sent successfully'})
            setLoading(false)
        }
    }

    return (
        <>
            <span className={styles.title}>Events</span>
            {currentEvent && (
                <div className={styles.container}>
                    <img className={styles.eventImage} src={'https://gscoworking.com'+currentEvent.image}/>
                    <div className={styles.content}>
                        <div className={'row align-items-center justify-content-between col-24'}>
                            <div className={'col align-items-start'}>
                                <span className={styles.date}>{getFormattedDate(currentEvent.time)}</span>
                                <span className={styles.time}>{getFormattedTime(currentEvent.time)}</span>
                            </div>
                            <div className={'col gap-10 align-items-center'}>
                                <span className={styles.price}>{currentEvent.price} {currentEvent.suffix}</span>
                                <span className={styles.oldPrice}>{currentEvent.oldPrice} {currentEvent.suffix}</span>
                            </div>
                        </div>
                        <span className={styles.eventTitle}>{currentEvent.title}</span>
                        <span className={styles.description}>{currentEvent.description}</span>
                        <div className={'col align-items-center justify-content-between mt-15'}>
                            <div className={styles.cta}>
                                Join the Event
                            </div>
                            <div className={'row align-items-end gap-15 mt-80 ml-190'} >
                                <div  className={styles.left} onClick={prevEvent}>
                                    <LeftArrow  />
                                </div>
                                <div  className={styles.right} onClick={nextEvent}>
                                    <LeftArrow />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={`col ${styles.eventAdd}`}>
                <div className={'col gap-10 align-items-center'}>
                    <span className={'spot-title text-black'} style={{textAlign: 'end'}}>Submit your event</span>
                    <span className={'text-14 text-black'}>You can hold an event at GoSound</span>
                </div>
                <div className={'red-button row pointer justify-content-center align-items-center'} onClick={()=>{setShowPopup(true)}}>
                    <span className={'red-button-text'}>Hold an event</span>
                </div>
            </div>
            {showPopup && (
                <div className={styles['popup-overlay']}>
                    <div className={`pointer relative ${styles['popup-container']}`}>
                        <div className={styles['popup-header']}>
                            <span className={'text-price text-align-center col-24'}>Hold an event</span>
                        </div>
                        <CloseIcon className={styles['close-icon']} onClick={() => {
                            setShowPopup(false)
                        }}/>
                        <div className={styles['popup-content']}>
                            <p>{'Fill out this form to reserve the room for the event'}</p>
                            <input
                                className={`${styles.inputField} mb-17 ${!isNameValid ? styles.invalid : ''}`}
                                placeholder={'your name'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => setIsNameValid(name.length >= 2)}
                            />
                            <input
                                className={`${styles.inputField} mb-17 ${!isNameValid ? styles.invalid : ''}`}
                                placeholder={'your email'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => setIsNameValid(email.length >= 2)}
                            />
                            <InputMask
                                mask="+99999999999"
                                className={`${styles.inputField} mb-17 ${!isPhoneValid ? styles.invalid : ''}`}
                                maskChar="_"
                                value={phone}
                                placeholder={'phone number'}
                                onChange={(e) => setPhone(e.target.value)}
                                onBlur={() => setIsPhoneValid(!!maskedPhone)}
                            >
                            </InputMask>
                            <textarea
                                rows={3}
                                className={`${styles.textareaField} mb-17 ${!isNameValid ? styles.invalid : ''}`}
                                placeholder={'your message'}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onBlur={() => setIsNameValid(message.length >= 2)}
                            />
                        </div>
                        <div className={styles['popup-footer']}>
                            {booked && (
                                <span className={'magenta-text'}>Success booked</span>
                            )}
                            {!booked && (
                                <div className={styles.buttonOne} style={{fontWeight: 700}} onClick={() => {
                                    sendEventEmail()
                                }}>
                                    Submit Request
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            )}
            {loader && (
                <LoaderComponent text={'Please wait. Your booking is processing'}
                                 loading={loading}>
                    <span>{error?.message}</span>
                    <div
                        className={'red-button row justify-content-center align-items-center'}
                        onClick={() => {
                            setLoader(false)
                            setLoading(true)
                            setError(null)
                            setShowPopup(false)
                        }}>Ok
                    </div>
                </LoaderComponent>
            )}
        </>
    );
};

export default EventsComponent;
