import React from 'react';
import styles from './MapSection.module.scss'
import MapComponent from "../../common/Map/Map.component";

const MapSectionComponent = () => {
    return (
        <div className={`${styles.container} mb-100`}>
            <p className={'h2-text mb-30'}>Music studios on map</p>
            <MapComponent />
        </div>
    );
};

export default MapSectionComponent;
export {};
