import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import NoSearchResultsComponent from "./NoSearchResults.component";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import RoomCardComponent from "../../desktop/SpotsPage/RoomCard/RoomCard.component";
import SearchComponent from "../SearchComponent/Search.component";
import BreadcrumbsComponent from "../../common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../../common/PageTitle/PageTitle.component";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconBlack.svg";
import TopBar from "../TopBar/TopBar";

const SpecialBadge = (props: {item: any, onClick: any}) => {
    return (
        <div className={`row align-items-center justify-content-between sea-badge gap-5`} >
            <span className={'text-black'}>{props.item}</span>
            <CloseIcon className={'pointer'} onClick={()=>props.onClick(props.item)}/>
        </div>
    )
}

const SearchResultPage = () => {
    const location = useLocation();
    const searchQuery = location.state?.searchQuery || { special: [] };
    const buildings = useSelector((state: RootState)=>state.buildings.buildings)
    const [bages, setBages] = useState<any>([])


    const filteredBuildings = () => {
        //let test = [...buildings, ...buildings]
        let rooms = buildings.flatMap(obj => obj.rooms).reduce((result, room) => {
            result.push(room);
            return result;
        }, []);
        if (searchQuery?.room && searchQuery.room.length>0) {
            rooms = rooms.filter((room:any)=>{
                return searchQuery.room.includes(room.title)
            })
        }
        if (searchQuery?.special && searchQuery.special.length>0) {
            rooms = rooms.filter((room: any) => {
                return searchQuery.special.some((special: string) => {
                    return room.includes.some((include: { name: string; }) => include.name.toLowerCase() === special.toLowerCase());
                });
            });
        }
        if (searchQuery && searchQuery.date !== null && searchQuery.date !== undefined) {
            const dateComponents = searchQuery.date.split('.'); // Split the string into day, month, and year
            const day = parseInt(dateComponents[0], 10);
            const month = parseInt(dateComponents[1], 10) - 1; // Months are 0-indexed in JavaScript
            const year = parseInt(dateComponents[2], 10);

            const searchDate = new Date(year, month, day);
            const searchTime = searchQuery.time;

            rooms = rooms.filter((room: { bookedSlots: any[]; }) => {
                return !room.bookedSlots.some(slot => {

                    const slotDate = new Date(slot.date);
                    return (
                        slotDate.getDate() === searchDate.getDate() &&
                        slotDate.getMonth() === searchDate.getMonth() &&
                        slotDate.getFullYear() === searchDate.getFullYear() &&
                        slot.start <= searchTime &&
                        slot.end > searchTime
                    );
                });
            });
        }
        return rooms
    };

    const crumbs = [
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Search',
            link: '/search'
        }
    ]

    function handleDeleteBadgeClick(name: string) {

        const idx = searchQuery?.special.indexOf(name)
        if (idx > -1) {
            searchQuery?.special.splice(idx, 1)
        }
        let tmp = [...bages]
        const bIdx = tmp.indexOf(name)
        if (bIdx > -1) {
            tmp.splice(bIdx, 1)
        }
        setBages((old: any)=>[...tmp])
    }

    useEffect(() => {
        setBages([...(searchQuery?.special || [])]);
    }, [searchQuery]);

    useEffect(() => {
        filteredBuildings()
    }, [bages]);

    return (
        <>
            <TopBar backLink={'/'} />
            <div className={'col wrapper'} style={{paddingTop: 0}}>
                {filteredBuildings() && filteredBuildings().length>0 && (
                    <>
                        <div className={'mt-30'}>
                            <BreadcrumbsComponent crumbs={crumbs} />
                        </div>
                        <div style={{marginLeft: -40}}>
                            <PageTitleComponent backLink={'/'} title={'Search'} />
                        </div>
                        <SearchComponent />
                        <div className={'row wrap align-items-center my-40 gap-10 justify-content-center'}>
                            {bages.map((s: string)=>(
                                <SpecialBadge item={s}  onClick={handleDeleteBadgeClick}/>
                            ))}
                        </div>
                    </>
                )}
                {(!filteredBuildings() || filteredBuildings().length===0) && (
                    <NoSearchResultsComponent />
                )}
                <div className={'col wrap gap-20'} style={{marginInline: 'auto'}}>
                    {filteredBuildings().map((room: any) => (
                        <RoomCardComponent room={room} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default SearchResultPage;
export {};
