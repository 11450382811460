import React from 'react';
import styles from './Message.module.scss'

const MessageComponent = (props: {message: string, read: boolean}) => {
    return (
        <div className={props.read ? styles.message : styles.messageUnread}>
            <span className={'text-14'}>{props.message}</span>
        </div>
    );
};

export default MessageComponent;
export {};
