import React, {useEffect, useState} from 'react';
import TopBar from "../TopBar/TopBar";
import RoomCardComponent from "../../desktop/SpotsPage/RoomCard/RoomCard.component";
import FavoritesOnMapComponent from "../../desktop/Private/FavoritesOnMap.component";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice";
import {fetchOrdersAsync} from "../../../reducers/orderSlice";

const FavoritesPage = () => {
    const dispatch = useDispatch()
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);
    const [userObject, setUserObject] = useState<any>(null)
    const [mapShow, setMapShow] = useState(false)

    const handleMapClose = () => {
        setMapShow(false)
    }

    useEffect(() => {
        // Диспатчим действие для проверки статуса входа в систему и загрузки данных пользователя с сервера
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        // @ts-ignore
    }, [dispatch]);

    useEffect(() => {
        if (fromStore) {
            setUserObject(fromStore);
        }
    }, [fromStore]);

    return (
        <div>
            <TopBar MiddlePart={'Favorite spots'} backLink={'/private'} />
            <div className={'col justify-content-center wrapper pt-0 px-20 mt-1'}>
                <div className={'row align-items-center justify-content-center mt-60'} style={{backgroundImage: `url(${require('../../../assets/images/ShowOnMapBackground.png')})`, backgroundPosition: 'center', minWidth: 290, height: 100, borderRadius: 8}}>
                    {!mapShow && (<div className={'red-button row align-content-center justify-content-center pointer'}
                                       style={{width: 154}} onClick={() => setMapShow(true)}>
                        <span className={'red-button-text'}>Show on map</span>
                    </div>)}
                    {mapShow && (<div className={'sea-button row align-content-center justify-content-center pointer'}
                                      style={{width: 154}} onClick={() => setMapShow(false)}>
                        <span className={'red-button-text'}>Show cards</span>
                    </div>)}

                </div>
                {mapShow && (
                    <FavoritesOnMapComponent favorites={userObject.favoriteRooms} closeClick={handleMapClose} width={'90vw'} height={'250px'} openPopup={false}/>
                )}
                <div className={'col col-19 mt-50 gap-25 wrap'}>
                    <span className={'spot-title'}>Favorite spots:</span>
                    <span style={{width: '90%'}}>The rooms you marked as favorites are saved here. By clicking on the heart you can save or delete rooms

</span>
                    {userObject && userObject.favoriteRooms && (
                        <div className={'row wrap mt-50 gap-25'}>
                            {userObject.favoriteRooms.map((room: any)=>(
                                <RoomCardComponent room={room} />
                            ))}
                        </div>
                    )}

                </div>

            </div>
        </div>
    );
};

export default FavoritesPage;
export {};
