import React, {useEffect, useState} from 'react';
import ActiveBookingComponent from "../../common/ActiveBooking/ActiveBooking.component";
import TopBar from "../TopBar/TopBar";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {fetchOrdersAsync} from "../../../reducers/orderSlice";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice";

const BookingPage = () => {
    const dispatch = useDispatch()
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);
    const futureOrdersFromStore = useSelector((state: RootState) => state.orders.futureOrders);
    const [userObject, setUserObject] = useState<any>(null)
    const allOrdersFromStore = useSelector((state: RootState) => state.orders.allOrders);

    const [orders, setOrders] = useState<any>([])
    const [displayAllOrders, setDisplayAllOrders] = useState<boolean>(false)

    useEffect(() => {
        // Диспатчим действие для проверки статуса входа в систему и загрузки данных пользователя с сервера
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        // @ts-ignore
        dispatch(fetchOrdersAsync(fromStore._id))
    }, [dispatch]);

    useEffect(() => {
        if (fromStore) {
            setUserObject(fromStore);
        }
    }, [fromStore]);

    useEffect(() => {
        if (allOrdersFromStore && displayAllOrders) {
            setOrders(allOrdersFromStore);
        }
        if (futureOrdersFromStore && !displayAllOrders) {
            setOrders(futureOrdersFromStore);
        }
    }, [futureOrdersFromStore, allOrdersFromStore, displayAllOrders]);

    function formatDateToDDMMYYYY(date: any): string {
        date = new Date(date)
        const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zeros if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based index) and add 1, then pad with leading zeros if needed
        const year = date.getFullYear().toString(); // Get the full year

        return `${day}.${month}.${year}`;
    }

    function handleOrderChange() {
        // @ts-ignore
        dispatch(fetchOrdersAsync(fromStore._id))
    }

    const calculateAmount = (order: any) => {
        if (!order?.paymentIntent?.amount) return 0; // Ensures there is an amount to process, else return 0

        switch (order.typeOfPayment) {
            case 'deposit':
                return order.paymentIntent.amount; // No division needed for 'deposit'
            case 'money':
                return order.paymentIntent.amount/100; // Divide by 100 for 'money'
            default:
                return 0; // Return 0 if typeOfPayment is undefined or any other unexpected value
        }
    };

    return (
        <div>
            <TopBar backLink={'/private'} MiddlePart={'Bookings'} />
            <div className={'col gap-30 mt-50 col-24 px-20 wrapper'}>
                <div className={'row align-items-center justify-content-between'}>
                    <span className={'spot-title'}>{displayAllOrders ? 'All' : 'Active'} Bookings:</span>
                    {!displayAllOrders && (
                        <span className={'blue-text pointer'} onClick={()=>setDisplayAllOrders(true)}>All bookings</span>
                    )}
                    {displayAllOrders && (
                        <span className={'blue-text pointer'} onClick={()=>setDisplayAllOrders(false)}>Active bookings</span>
                    )}
                </div>
                {orders.map((order: any, idx: number)=>(
                    <div className={'mb-70'}>
                        <ActiveBookingComponent order={order} canceled={order.status === 'Canceled'} width={24} withDeletion={true} amount={calculateAmount(order)} date={new Date(order.slots[0].date).toDateString()} slot={order.slots} address={order.buildingID.address} keyCode={order.keyCode}  withPrice={true} id={order._id} handleOrdersChange={handleOrderChange} title={order.roomID.title}/>
                    </div>
                )) }
            </div>
        </div>
    );
};

export default BookingPage;
export {};
