import React from 'react';
import {Routes, Route, useNavigate, RouteProps, Navigate} from "react-router-dom";
import MainPage from "../Pages/MainPage";
import SearchPage from "../SearchResults/SearchPage";
import AllSpotsPage from "../AllSpots/AllSpotsPage";
import BuildingPage from "../Building/BuildingPage";
import RoomDetailsPage from "../RoomDetailsPage/RoomDetailsPage";
import ConfirmationPage from "../Confirmation/ConfirmationPage";
import PaymentPage from "../PaymentPage/PaymentPage";
import BookingCompletePage from "../Complete/BookingCompletePage";
import AccountPage from "../Account/AccountPage";
import PersonalInfoPage from "../Account/PesonalInfoPage";
import BookingPage from "../Account/BookingPage";
import FavoritesPage from "../Account/FavoritesPage";
import NotificationsPage from "../Account/NotificationsPage";
import LoginPage from "../Entrance/LoginPage";
import SettingsPage from "../Account/SettingsPage";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import ContactsPage from "../Contacts/ContactsPage";
import {PrivacyPage} from "../Policies/PrivacyPage";
import TermsPage from "../Policies/TermsPage";
import CancellationPolicyPage from "../Policies/CancellationPolicyPage";
import HiwPage from "../Hiw/HiwPage";
import AboutUsPageComponent from "../AboutUs/AboutUsPage.component";
import TeachersPageComponent from "../Teachers/Teachers.page.component";
import BuyCertificatePage from "../BuyCertificate/BuyCertificatePage";
import MyFundsPage from "../Account/MyFundsPage";


const AppContent = () => {
    const userFull = useSelector((state: RootState) => state.auth.userFull);
    const isAuthenticated = !!userFull?._id; // Check if _id exists
    return (
        <Routes>
            <Route path={'/'} element={<MainPage />} />
            <Route path={'/search'} element={<SearchPage />} />
            <Route path={'/signin'} element={<LoginPage />} />
            <Route path={'/spots'} element={<AllSpotsPage />} />
            <Route path={'/contacts'} element={<ContactsPage />} />
            <Route path={'/confirmation'} element={<ConfirmationPage />} />
            <Route path={'/complete'} element={<BookingCompletePage />} />
            <Route path={'/private'} element={isAuthenticated ? <AccountPage /> : <Navigate to="/signin" />} />
            <Route path={'/private/account'} element={isAuthenticated ? <PersonalInfoPage /> : <Navigate to="/signin" />} />
            <Route path={'/private/funds'} element={isAuthenticated ? <MyFundsPage /> : <Navigate to="/signin" />} />
            <Route path={'/private/bookings'} element={isAuthenticated ? <BookingPage /> : <Navigate to="/signin" />} />
            <Route path={'/private/favorites'} element={isAuthenticated ? <FavoritesPage /> : <Navigate to="/signin" />} />
            <Route path={'/private/messages'} element={isAuthenticated ? <NotificationsPage /> : <Navigate to="/signin" />} />
            <Route path={'/private/settings'} element={isAuthenticated ? <SettingsPage /> : <Navigate to="/signin" />} />
            <Route path={'/payment'} element={<PaymentPage />} />
            <Route path={'/building/:id'} element={<BuildingPage />} />
            <Route path={'/rooms/:id'} element={<RoomDetailsPage />} />
            <Route path={'/privacy'} element={<PrivacyPage />} />
            <Route path={'/cancellation'} element={<CancellationPolicyPage />} />
            <Route path={'/how-it-works'} element={<HiwPage />} />
            <Route path={'/about-us'} element={<AboutUsPageComponent />} />
            <Route path={'/terms'} element={<TermsPage />} />
            <Route path={'/partners'} element={<TeachersPageComponent />} />
            <Route path={'/buy-certificate'} element={<BuyCertificatePage />} />
        </Routes>
    );
};

export default AppContent;
export {};
