import React, {useEffect, useState} from 'react';
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import {useSelector} from "react-redux";
import {selectAllBuildings} from "../reducers/buildingSlice";
import AllSpotsBuildingSectionComponent from "../components/desktop/SpotsPage/AllSpotsBuildingSection.component";
import MapComponent from "../components/common/Map/Map.component";

const AllSpotsPage = () => {
    const buildings = useSelector(selectAllBuildings)
    const [mapMode, setMapMode] = useState(false)
    const [locations, setLocations] = useState<any[]>([])
    useEffect(()=>{
        if (buildings) {
            const locations = buildings.map((building) => {
                // Extracting the properties from each building
                const { id, coords, address, rooms } = building;

                // Extracting the 'title' from each room and putting them into an array
                const options = rooms.map((room: { title: any; }) => room.title);

                // Creating a new object with the required properties
                return { id, lat: coords.lat, lng: coords.lng, address, options };
            });
            setLocations(locations)
        }
    }, [buildings])
    useEffect(() => {
        // Ваш код для получения данных о комнате из API

        // Установка заголовка и описания страницы
        document.title = 'GoSound Coworking: Music Rooms & Studios for All Abilities in Cyprus'; // Устанавливаем заголовок страницы
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Book your spot at GoSound, Cyprus\'s leading music co-working space. Perfect for musicians and vocalists of all levels. Rent soundproofed rooms equipped with top-tier equipment. Choose your coach or bring your own. Dive in and express yourself in a safe, professional environment.'); // Устанавливаем описание страницы
        }
    }, []);
    return (
        <div className={'wrapper col'}>
            <div className={'row justify-content-between align-items-center'}>
                <PageTitleComponent backLink={'/'} title={!mapMode ? 'All spots' : 'Music studios on map'} />
                <span className={'blue-text pointer'} onClick={()=>setMapMode(old=>!old)}>{!mapMode ? 'Show on map' : 'View all spots'}</span>
            </div>

            {!mapMode && (
                <>
                    <p className={'main-text text-white mt-25 col-16'}>
                        Music co-working is a place where everyone shows any musical abilities, tries different instruments and vocals, both alone, just with a friend or with your coach. You can choose and invite your own teacher or contact us for an advice. The cost of each room includes a professional unique soundproofed space, the usage of high-quality music equipment, your safety and access to digital locks.
                    </p>
                    <p className={'main-text text-white mb-50'} >Express yourself with us!</p>
                    {buildings.map(building=>(
                        <AllSpotsBuildingSectionComponent rooms={building.rooms} title={building.address} buildingId={building.id}/>
                    ))}
                </>
            )}
            {mapMode && (
                <div style={{background: '#959855', width: '100vw', height:  '80vh', position: 'absolute', left: 0, top: 200}}>
                    <MapComponent height={'100%'} locations={locations} />
                </div>
            )}
        </div>
    );
};

export default AllSpotsPage;
export {};
