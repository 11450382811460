import React, {useEffect, useState} from 'react';
import {ReactComponent as SelectedIcon} from '../../../assets/images/svg/PrivateMenuItemIconSelected.svg';
import {ReactComponent as Icon} from '../../../assets/images/svg/PrivateMenuItemIcon.svg';
import {ReactComponent as IconBlue} from '../../../assets/images/svg/PrivateMenuBlueIcon.svg';
import  styles from './PrivateMenu.module.scss'
import ActiveBookingComponent from "../../common/ActiveBooking/ActiveBooking.component";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrdersAsync} from "../../../reducers/orderSlice";
import {RootState} from "../../../reducers/types";

const PrivateMenuComponent = (props: {selected?: number, handleMenuItemClick: any, handleLogout: any}) => {
    const dispatch = useDispatch()
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const fromStore = useSelector((state: RootState)=>state.orders.futureOrders)
    const [actualBooking, setActualBooking] = useState<any>(null)

    const menuItems = ['Personal information', 'My Funds', 'Favorites', 'Booking', 'Notifications', 'Reviews', 'Settings']


    useEffect(() => {
        // Проверяем, что 'fromStore' не равен null перед установкой 'userObject'
        if (fromStore && fromStore.length > 0) {
            setActualBooking({
                slot: fromStore[fromStore.length-1].slots,
                date: fromStore[fromStore.length-1].slots[0].date,
                address: fromStore[fromStore.length-1].buildingID.address,
                keyCode: fromStore[fromStore.length-1].keyCode,
                title: fromStore[fromStore.length-1].roomID.title
            });
        }
    }, [fromStore]);

    useEffect(()=>{
        // @ts-ignore
        if (user && user._id) {
            // @ts-ignore
            dispatch(fetchOrdersAsync(user._id))
        }
    },[dispatch, user])

    function formatDateToDDMMYYYY(date: any): string {
        date = new Date(date)
        const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zeros if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based index) and add 1, then pad with leading zeros if needed
        const year = date.getFullYear().toString(); // Get the full year
        return `${day}.${month}.${year}`;
    }


    return (
        <div style={{minHeight: 800, paddingBottom: 100}}>
            <div className={`col gap-20 ${styles['menu-container']}`}>
                {menuItems.map((item: string, idx: number)=>(
                    <>
                        {idx === props.selected && (
                            <div className={'row pointer gap-10'} onClick={()=>props.handleMenuItemClick(idx)}>
                                <SelectedIcon />
                                <span className={styles['selected-item']}>{item}</span>
                            </div>
                        )}
                        {idx !== props.selected && (
                            <div className={'row pointer gap-10'} onClick={()=>props.handleMenuItemClick(idx)}>
                                <Icon />
                                <span className={'main-text'}>{item}</span>
                            </div>
                        )}
                        {idx < menuItems.length-1 && (
                            <div className={styles.divider} ></div>
                        )}
                    </>
                ))}
                <div className={'row pointer gap-10 mt-40'} onClick={props.handleLogout}>
                    <IconBlue />
                    <span className={styles.logout}>{`Log out`}</span>
                </div>
                <span className={'main-text pointer'} style={{color: '#525460'}}>Delete account</span>
                {actualBooking && (
                    <div className={'col gap-30'}>
                        <span className={'spot-title'}>Active Booking:</span>
                        <ActiveBookingComponent date={formatDateToDDMMYYYY(actualBooking?.date)} slot={actualBooking.slot} address={actualBooking.address} keyCode={actualBooking.keyCode}  title={actualBooking.title}/>

                    </div>
                )}
            </div>
        </div>
    );
};

export default PrivateMenuComponent;
export {};
