import React, {useState} from 'react';
import styles from "./Popup.module.scss";
import axios from "axios";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconWhite.svg";
import {clearBooking} from "../../../reducers/bookingSlice";

const ForgotPasswordPopup = (props: {onClose: any, error: any,  success: any}) => {
    const [email, setEmail] = useState<string>('')
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    function handleForgot() {
        if (!emailRegex.test(email)) {
            console.error('Please enter a valid email address.');
            return;
        }

        const apiUrl = 'https://gscoworking.com/api/user/forgot';

        axios.post(apiUrl, {email})
            .then((response) => {
                props.success()
                props.onClose()
                // You can perform any additional actions after placing the order here
            })
            .catch((error) => {
                props.error()
                props.onClose()
                console.error('Error placing the order:', error);
            });
    }

    return (
        <div className={styles['popup-overlay']}>
            <div className={`pointer relative ${styles['popup-container']}`}>
                <div className={styles['popup-header']}>
                    <span className={'text-price text-align-center col-24'}>Password recovery</span>
                </div>
                <CloseIcon className={styles['close-icon']} onClick={props.onClose} />
                <div className={styles['popup-content']}>
                    <p>Indicate the email that you used to enter. We will send a link to it, moving on which you can set a new password</p>
                    <div className={'booking-input-wrapper col'} style={{width: '90%', border: '1px solid #D3D3D3'
                    }}>
                        <span>Email</span>
                        <div className={'row align-items-center'}>
                            <input
                                type={'email'}
                                className={'booking-input col-24'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                    </div>
                </div>
                <div className={styles['popup-footer'] }>
                    <div className={'red-button row center-content mb-10 pointer'} style={{width: '100%'}} onClick={handleForgot}>
                        <span className={'red-button-text'}>Send</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPopup;
export {};
