import React, {useEffect, useState} from 'react';
import styles from './PopupEditReview.module.scss';
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/CloseIconWhite.svg';
import {ReactComponent as RatingStar} from '../../../assets/images/svg/RedStar.svg';
import {ReactComponent as EmptyRatingStar} from '../../../assets/images/svg/EmptyStar.svg';
interface UniversalPopupProps {
    title: string;
    text?: string;
    review: any;
    buttonText1?: string;
    buttonText2?: string;
    onClose: () => void;
    onButton1Click: (data: any) => void;
    onButton2Click?: () => void;
}

const UniversalPopup: React.FC<UniversalPopupProps> = ({
                                                           title,
                                                           text='',
                                                           buttonText1,
                                                           buttonText2,
                                                           onClose,
                                                           onButton1Click,
                                                           onButton2Click,
                                                           review,
                                                       }) => {
    const [stateReview, setStateReview] = useState<any>(null)

    useEffect(()=>{
        setStateReview(review)
    },[])

    const handleStarClick = (selectedRating: number) => {
        setStateReview((old: any)=>({...old, rating: selectedRating}));
    };

    const renderStars = () => {
        const maxStars = 5; // Maximum number of stars
        const roundedRating = Math.round(stateReview?.rating); // Round the rating to the nearest whole number

        const stars = [];
        for (let i = 1; i <= maxStars; i++) {
            const isSelected = i <= (stateReview?.rating || roundedRating);
            const StarComponent = isSelected ? RatingStar : EmptyRatingStar;

            stars.push(
                <StarComponent
                    key={i}
                    onClick={() => handleStarClick(i)}
                />
            );
        }

        return stars;
    };

    return (
        <div className={styles['popup-overlay']} style={{zIndex: 10}}>
            <div className={`pointer relative ${styles['popup-container']}`}>
                <div className={styles['popup-header']}>
                    <span className={'text-price text-align-center col-24'}>{title}</span>
                </div>
                <CloseIcon className={styles['close-icon']} onClick={onClose} />
                <div className={styles['popup-content']}>
                    {renderStars()}
                </div>
                <div className={`row justify-content-center align-items-center ${styles.textareaInput}`}>
                    <textarea rows={10} className={'col-24'} value={stateReview?.review} onChange={(e)=>{setStateReview((old: any)=>({...old, review: e.target.value}))}}/>
                </div>
                <div className={styles['popup-footer'] }>

                        <div className={styles.buttonOne} onClick={()=>onButton1Click(stateReview)}>
                            Publish review
                        </div>

                        <div className={'magenta-text  text-16'} style={{fontWeight: 700}} onClick={onClose}>
                            Cancel
                        </div>

                </div>
            </div>
        </div>
    );
};

export default UniversalPopup;
export {};
