import React from 'react';
import styles from './NoSearchResults.module.scss'
import SearchComponent from "../../common/SearchComponent/Search.component";
import BreadcrumbsComponent from "../../common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../../common/PageTitle/PageTitle.component";
import {ReactComponent as BackgroundPattern} from "../../../assets/images/svg/NotFoundBackground.svg";

const NoSearchResultsComponent = () => {
    const crumbs = [
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Search',
            link: '/search'
        }
    ]
    return (
        <div className={`${styles.container} col`}>
            <div className={'my-30'}>
                <BreadcrumbsComponent crumbs={crumbs} />
            </div>
            <div style={{marginLeft: -40}}>
                <PageTitleComponent backLink={'/'} title={'Search'} />
            </div>
            <SearchComponent />
            <span className={'text-price text-white mt-100'}>
                Sorry, nothing found. Try to change request
            </span>
            <BackgroundPattern className={styles.backGround}/>
        </div>
    );
};

export default NoSearchResultsComponent;
export {};
