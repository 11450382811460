import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice";
import TopBar from "../TopBar/TopBar";
import NotificationsComponent from "../../common/Notifications/Notifications.component";

const NotificationsPage = () => {
    const dispatch = useDispatch()
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);
    const [userObject, setUserObject] = useState<any>(null)

    useEffect(() => {
        // Диспатчим действие для проверки статуса входа в систему и загрузки данных пользователя с сервера
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        // @ts-ignore
    }, [dispatch]);

    useEffect(() => {
        if (fromStore) {
            setUserObject(fromStore);
        }
    }, [fromStore]);

    return (
        <div>
            <TopBar MiddlePart={'Notifications'} backLink={'/private'} />
            {userObject && (
                <div className={'wrapper px-20'}>
                    <NotificationsComponent user={userObject?._id} notifications={userObject?.notifications}/>
                </div>
            )}
        </div>
    );
};

export default NotificationsPage;
export {};
