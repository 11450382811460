import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectAllBuildings} from "../../../reducers/buildingSlice";
import AllSpotsBuildingSectionComponent from "../SpotsPage/AllSpotsBuildingSection.component";
import TopBar from "../TopBar/TopBar";

const AllSpotsPage = () => {
    const buildings = useSelector(selectAllBuildings)
    useEffect(() => {
        // Ваш код для получения данных о комнате из API

        // Установка заголовка и описания страницы
        document.title = 'GoSound Coworking: Music Rooms & Studios for All Abilities in Cyprus'; // Устанавливаем заголовок страницы
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Book your spot at GoSound, Cyprus\'s leading music co-working space. Perfect for musicians and vocalists of all levels. Rent soundproofed rooms equipped with top-tier equipment. Choose your coach or bring your own. Dive in and express yourself in a safe, professional environment.'); // Устанавливаем описание страницы
        }
    }, []);
    return (
       <>
           <TopBar backLink={'/'} />
           <div className={'wrapper col'} style={{paddingInline: '10%'}}>
               <p className={'main-text text-white mt-25 col-24'}>
                   Music co-working is a place where everyone shows any musical abilities, tries different instruments and vocals, both alone, just with a friend or with your coach. You can choose and invite your own teacher or contact us for an advice. The cost of each room includes a professional unique soundproofed space, the usage of high-quality music equipment, your safety and access to digital locks.
               </p>
               <p className={'main-text text-white mb-50'} >Express yourself with us!</p>
               {buildings.map(building=>(
                   <AllSpotsBuildingSectionComponent rooms={building.rooms} title={building.address} buildingId={building.id}/>
               ))}
           </div>
       </>
    );
};

export default AllSpotsPage;
export {};
