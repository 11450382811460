// src/reducers/authSlice.ts

import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from './types'; // Import the RootState type

export interface RoomsState {
    rooms: any[];
}

const initialState: RoomsState = {
    rooms: [
        {
            title: 'Office main',
            subtitle: 'Big hall',
            id: '0',
            image: require('../assets/images/populateSpot1.png'),
            images: [
                require('../assets/images/populateSpot1.png'),
                require('../assets/images/populateSpot1.png'),
                require('../assets/images/populateSpot1.png'),
                require('../assets/images/populateSpot1.png'),
            ],
            draft: require('../assets/images/RoomTestDraft.png'),
            bookedSlots: [{ start: 14, end: 16, date: '2023-07-27' },{ start: 4, end: 19, date: '2023-07-28' }],
            description: 'Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. Some description text with every important information. ',
            address: 'Kolokotroni 28, Limassol',
            coords: {lat: 34.680573, lng: 33.047663},
            reviews: [
                {
                    author: 'Charles Lindfors',
                    date: '15.06.2023',
                    rating: 5,
                    review: 'Perfect coworking centre, best rooms with all you need and ever wanted. Great acoustics and location. I will definitely book again. Me and my band very excited playing here.'
                },
                {
                    author: 'Margareth Moss',
                    date: '15.06.2023',
                    rating: 5,
                    review: 'Perfect coworking centre, best rooms with all you need and ever wanted. Great acoustics and location. I will definitely book again. Me and my band very excited playing here.'
                },
                {
                    author: 'Elizabeth Lovejoy',
                    date: '15.06.2023',
                    rating: 5,
                    review: 'Perfect coworking centre, best rooms with all you need and ever wanted. Great acoustics and location. I will definitely book again. Me and my band very excited playing here.'
                },
                {
                    author: 'Charles Lindfors',
                    date: '15.06.2023',
                    rating: 5,
                    review: 'Perfect coworking centre, best rooms with all you need and ever wanted. Great acoustics and location. I will definitely book again. Me and my band very excited playing here.'
                },
                {
                    author: 'Charles Lindfors',
                    date: '15.06.2023',
                    rating: 5,
                    review: 'Perfect coworking centre, best rooms with all you need and ever wanted. Great acoustics and location. I will definitely book again. Me and my band very excited playing here.'
                },

            ],
            includes: [
                {
                    name: 'Guitar',
                    content: 'Electro 1, Electro 2, Acoustic',
                    icon: 'Guitar'
                },
                {
                    name: 'Piano',
                    content: 'Piano, Digital piano',
                    icon: 'Piano'
                },
                {
                    name: 'Drums',
                    content: 'Marching Drums, Bass Drum, Marching Drums, Bass Drum',
                    icon: 'Drums'
                },
                {
                    name: 'Microphone',
                    content: 'Dynamic Microphone, Large Diaphram Condensor Microphone, Small Diaphram Condensor Microphones',
                    icon: 'Microphone'
                },

            ],
            price: '20',
            priceSuffix: '€/h',
            rating: 4.95
        },
        {
            title: 'Rehearsal room',
            id: '1',
            image: require('../assets/images/populateSpot2.png')
        },
        {
            title: 'Lecture hall',
            id: '2',
            image: require('../assets/images/populateSpot3.png')
        },
        {
            title: 'Office main',
            id: '3',
            image: require('../assets/images/populateSpot1.png')
        },
        {
            title: 'Rehearsal room',
            id: '4',
            image: require('../assets/images/populateSpot2.png')
        }
    ],
};

const authSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        // loginSuccess: (state) => {
        //     state.isLoggedIn = true;
        // },
        // logout: (state) => {
        //     state.isLoggedIn = false;
        // },
    },
});

// export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
export const selectRoomById = (id: string | undefined) =>
    createSelector(
        (state: RootState) => state.rooms.rooms,
        (rooms) => (id ? rooms.find((room) => room.id === id) : undefined)
    );
