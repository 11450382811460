import React, {useState} from 'react';
import styles from './ImageSlider.module.scss'
import {ReactComponent as ChevronRight} from '../../../assets/images/svg/ChevronRight.svg';
import {ReactComponent as ChevronLeft} from '../../../assets/images/svg/ChevronLeft.svg';

interface ImageSliderProps {
    images: any[];
    width?: number | string;
    height?: number | string;
    positioning?: string,
    backgroundMode?: string,
    className?: string;
    withFavoriteIcon?: boolean;
    addToFavorite?: any;
    withDots?: boolean;
}

const ImageSliderComponent: React.FC<ImageSliderProps> = ({
                                                              images,
                                                              width = '100%',       // Default width if not provided
                                                              height = 'auto',      // Default height if not provided,
                                                              positioning = 'center',
                                                              backgroundMode= 'cover',
                                                              className = '',       // Default className if not provided
                                                              withFavoriteIcon = false, // Default value for withFavoriteIcon if not provided
                                                              addToFavorite = () => {}, // Default value for addToFavorite if not provided
                                                              withDots = false        // Default value for withDots if not provided
                                                          })  => {
    const [currentImage, setCurrentImage] = useState<number>(0);
    function nextImage() {
        if (currentImage===images.length-1) {
            setCurrentImage(0)
            return
        }
        setCurrentImage(old=>old+1)
    }
    function prevImage() {
        if (currentImage===0) {
            setCurrentImage(images.length-1)
            return
        }
        setCurrentImage(old=>old-1)
    }

    return (
        <div className={`relative ${styles.inCardImage}`} style={{ backgroundImage: `url(${images[currentImage]})`, width, height, backgroundPosition: positioning, backgroundSize: backgroundMode, backgroundRepeat: 'no-repeat' }}>
            <div className={`${styles.buttonLeft} center-content`} onClick={prevImage}>
                <ChevronLeft />
            </div>
            <div className={`${styles.buttonRight} center-content`} onClick={nextImage}>
                <ChevronRight />
            </div>
            {withDots && (
                <div className={`row gap-4 justify-content-center ${styles.dots}`}>
                    {images.map((dot, idx) => (
                        <div
                            key={idx}
                            className={`${idx === currentImage ? styles.dot : styles.inactiveDot} pointer`}
                            onClick={() => setCurrentImage(old => idx)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ImageSliderComponent;
export {};
