import React from 'react';
import {ReactComponent as FullMuicKey} from '../../assets/images/svg/FullMusicKey.svg';
import {ReactComponent as FoldIcon} from '../../assets/images/svg/fold.svg';

const FullCertificateComponent = (props: {amount: number, fold: any, sendEmail: any,  download: any, cert: any, copyHandle: any}) => {
    return (
        <div
            style={{
                width: '595px',
                height: '842px',
                backgroundPosition: 'center',
                background: '#76CCCC',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                overflow: 'hidden',
                margin: '0 auto',
            }}
            className='main-container'
        >
            <FullMuicKey style={{position: 'absolute', right: 0}}/>
            <FoldIcon style={{position: 'absolute', right: 20, top: 20, zIndex: 99}} className={'pointer'} onClick={props.fold}/>
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    backgroundPosition: 'center',
                    backgroundImage:
                        'url(../assets/images/c9b25e56-6510-4a96-8b51-eb8a9ee3c549.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    zIndex: '15',
                    margin: '20px 0 0 552px',
                }}
            />
            <span
                style={{
                    display: 'block',
                    height: '45px',
                    fontFamily:
                        'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                    fontSize: '38px',
                    fontWeight: '700',
                    lineHeight: '44.531px',
                    color: '#ffffff',
                    position: 'relative',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    zIndex: '20',
                    margin: '16px 0 0 35px',
                }}
            >
        Congratulations
      </span>
            <div
                style={{
                    display: 'flex',
                    width: '531px',
                    flexDirection: 'column',
                    gap: '30px',
                    alignItems: 'flex-start',
                    flexWrap: 'nowrap',
                    position: 'relative',
                    zIndex: '1',
                    margin: '36px 0 0 35px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '531px',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'flex-start',
                        flexShrink: '0',
                        flexWrap: 'nowrap',
                        position: 'relative',
                        zIndex: '2',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: '294px',
                            flexDirection: 'column',
                            gap: '5px',
                            alignItems: 'flex-start',
                            flexShrink: '0',
                            flexWrap: 'nowrap',
                            position: 'relative',
                            zIndex: '3',
                        }}
                    >
            <span
                style={{
                    height: '30px',
                    flexShrink: '0',
                    flexBasis: 'auto',
                    fontFamily:
                        'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                    fontSize: '26px',
                    fontWeight: '700',
                    lineHeight: '30px',
                    color: '#343643',
                    position: 'relative',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    zIndex: '4',
                }}
            >
              your certificate
            </span>
                        <div
                            style={{
                                display: 'flex',
                                width: '137px',
                                gap: '10px',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                flexShrink: '0',
                                flexWrap: 'nowrap',
                                position: 'relative',
                                zIndex: '5',
                            }}
                        >
              <span
                  style={{
                      height: '28px',
                      flexShrink: '0',
                      flexBasis: 'auto',
                      fontFamily:
                          'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                      fontSize: '20px',
                      fontWeight: '700',
                      lineHeight: '23.438px',
                      color: '#343643',
                      position: 'relative',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                      zIndex: '6',
                  }}
              >
                for
              </span>
                            <span
                                style={{
                                    height: '45px',
                                    flexShrink: '0',
                                    flexBasis: 'auto',
                                    fontFamily:
                                        'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                                    fontSize: '38px',
                                    fontWeight: '700',
                                    lineHeight: '44.531px',
                                    color: '#343643',
                                    position: 'relative',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    zIndex: '7',
                                }}
                            >
                {props.amount} €
              </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '531px',
                            gap: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexShrink: '0',
                            flexWrap: 'nowrap',
                            position: 'relative',
                            zIndex: '8',
                        }}
                    >
                        <div
                            style={{
                                width: '320px',
                                height: '60px',
                                flexShrink: '0',
                                position: 'relative',
                                zIndex: '9',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '10px',
                                    border: '1px solid #ffffff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '10',
                                }}
                            />
                            <span
                                style={{
                                    display: 'flex',
                                    height: '23px',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    fontFamily:
                                        'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                    lineHeight: '23px',
                                    color: '#343643',
                                    position: 'absolute',
                                    top: 'calc(50% - 11px)',
                                    left: '20px',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    zIndex: '13',
                                }}
                            >
                {props.cert.code}
              </span>
                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '278px',
                                    transform: 'translate(0, -50%)',
                                    zIndex: '11',
                                }}
                                onClick={()=>props.copyHandle(props.cert.code)}
                            >
                                <div
                                    style={{
                                        width: '19.895px',
                                        height: '20px',
                                        backgroundPosition: 'center',
                                        backgroundImage:
                                            'url(../assets/images/9c160ae2-8656-4dc3-929b-52d0cf70f08a.png)',
                                        backgroundSize: '100% 100%',
                                        backgroundRepeat: 'no-repeat',
                                        position: 'relative',
                                        zIndex: '12',
                                        margin: '0 0 0 0',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                width: '191px',
                                flexShrink: '0',
                                fontFamily:
                                    'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '18.75px',
                                position: 'relative',
                                textAlign: 'left',
                                zIndex: '14',
                            }}
                        >
              <span
                  style={{
                      fontFamily:
                          'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '18.75px',
                      color: '#ffffff',
                      position: 'relative',
                      textAlign: 'left',
                  }}
              >
                Use this code to deposit  funds at 
              </span>
                            <span
                                style={{
                                    fontFamily:
                                        'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    lineHeight: '18.75px',
                                    color: '#ffffff',
                                    position: 'relative',
                                    textAlign: 'left',
                                }}
                            >
                 gscoworking.com
              </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '359px',
                    flexDirection: 'column',
                    gap: '20px',
                    alignItems: 'flex-start',
                    flexWrap: 'nowrap',
                    position: 'relative',
                    zIndex: '21',
                    margin: '60px 0 0 35px',
                }}
            >
        <span
            style={{
                height: '23px',
                flexShrink: '0',
                flexBasis: 'auto',
                fontFamily:
                    'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '23px',
                color: '#343643',
                position: 'relative',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                zIndex: '22',
            }}
        >
          Unlock Your GoSound Gift: Quick Guide!
        </span>
                <div
                    style={{
                        width: '358px',
                        flexShrink: '0',
                        fontFamily:
                            'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '18.75px',
                        position: 'relative',
                        textAlign: 'left',
                        zIndex: '23',
                    }}
                >
          <span
              style={{
                  fontFamily:
                      'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '18.75px',
                  color: '#343643',
                  position: 'relative',
                  textAlign: 'left',
              }}
          >
            Open the website gscoworking.com:
            <br />
            <br />
            Register or log in to your account.
            <br />
            <br />
            Activate the certificate in the 'Personal Information' section of your User
            Account. <br />
            <br />
            The certificate amount will be credited to your deposit account.
            <br />
            <br />
          </span>
                    <span
                        style={{
                            fontFamily:
                                'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18.75px',
                            color: '#343643',
                            position: 'relative',
                            textAlign: 'left',
                        }}
                    >
            Done! Now you can use the certificate funds to book our rehearsal
            studios.
          </span>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '200px',
                    height: '46px',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(90deg, #D83E59 0%, #C32C66 99.78%), #D83E59',
                    borderRadius: '30px',
                    position: 'relative',
                    zIndex: '18',
                    margin: '100px 0 0 35px',
                }}
            >
        <a
            style={{
                display: 'flex',
                width: '187.5px',
                height: '14.567px',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                fontFamily:
                    'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei, Source Han Sans CN, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '14.567px',
                color: '#ffffff',
                position: 'relative',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                zIndex: '18',
            }}
            href={`https://gscoworking.com/storage/output/certificates/${props.cert.code}.pdf`}
            target={'_blank'}
        >
          Download PDF
        </a>
            </div>
        </div>
    );
};

export default FullCertificateComponent;
