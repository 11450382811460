import React from 'react';
import styles from './Footer.module.scss'
import '../../../assets/scss/styleTerms.scss'
import {ReactComponent as ScrollToTop} from '../../../assets/images/svg/ScrollToTop.svg';
import {ReactComponent as LogoFooter} from '../../../assets/images/svg/LogoFooter.svg';
import {ReactComponent as MapPin} from '../../../assets/images/svg/PinWhite.svg';
import {ReactComponent as PhoneIcon} from '../../../assets/images/svg/PhoneWhite.svg';
import {ReactComponent as Instagram} from '../../../assets/images/svg/InstaWhite.svg';
import {ReactComponent as Telegram} from '../../../assets/images/svg/TelegramWhite.svg';
import {ReactComponent as Whatsapp} from '../../../assets/images/svg/Whatsapp.svg';
import {ReactComponent as VK} from '../../../assets/images/svg/VK.svg';
import {ReactComponent as FooterCards} from '../../../assets/images/svg/FooterCards.svg';
import {ReactComponent as Facebook} from '../../../assets/images/svg/FaceBookWhite.svg';
import {ReactComponent as Clock} from '../../../assets/images/svg/ClockIconWhite.svg';
import {useNavigate} from "react-router-dom";

const FooterComponent = () => {
    const navigation = useNavigate()
    function scrollToTop() {
        window.scroll({top: 0, behavior: 'smooth'})
    }

    return (
        <div className={`col relative ${styles.footer} mt-30 pb-20`}>
            <ScrollToTop style={{position: 'absolute', top: '-36px', right: 20}} className={'pointer'} onClick={scrollToTop}/>
            <div className={'row justify-content-between gap-10'}>
                <LogoFooter className={'mt-8'}  onClick={()=>navigation('/')} />
                <div className={'row align-items-center gap-10 mt-8'}>
                    <MapPin />
                    <span className={'text-14'}>Kolokotroni 28, Limassol</span>
                </div>
                <a href={'tel:+357 95 965 784'} className={'row align-items-center gap-10 mt-8 text-white'} style={{textDecoration: "none"}}>
                    <PhoneIcon />
                    <span className={'text-14'}>hello@gscoworking.com</span>
                </a>
                <div className={'row align-items-center mt-10 gap-10'}>
                    <Clock/>
                    <span className={'text-14'}>08:00 - 23:00</span>
                </div>
                <div className={`mt-15 row gap-5 align-items-center justify-content-center ${styles.telegram}`} onClick={()=>{window.location.href='https://t.me/gosound_coworking'}}>
                    <span>Telegram</span>
                    <Telegram />
                </div>
                <div className={`mt-15 gap-5 row align-items-center justify-content-center ${styles.whatsapp}`} onClick={()=>{window.location.href='https://wa.me/35795965784'}}>
                    <span>Whatsapp</span>
                    <Whatsapp />
                </div>
                <div className={`mt-5 row align-items-center gap-10`}>
                    <Instagram className={'pointer'} onClick={()=>{window.location.href = 'https://instagram.com/go.sound_coworking?igshid=OGQ5ZDc2ODk2ZA=='}}/>
                    <Facebook className={'pointer'} onClick={()=>{window.location.href = 'https://www.facebook.com/profile.php?id=100093960093270'}}/>
                    <VK className={'pointer'} />
                </div>
                <div className={'row center-content pointer ml-100'} style={{width: 220, marginLeft: 'auto'}} onClick={()=>navigation(`/spots`)}>
                    <FooterCards />
                </div>
            </div>
            <div className={'row mt-20 gap-40'}>
                <span className={'text-14 light-gray pointer'} onClick={()=>navigation('/')}>© GoSound 2023 </span>
                <div className={'col-12'}>
                    <span className={'text-12 light-gray'}>GoSound is a music spaces rental service with simple convenient booking system. Rooms are automatically accessed through the smart locks controlled by web application. We guarantee the security of payments and the quality of our services.</span>
                </div>
                <span className={'text-14 light-gray underline pointer'} onClick={()=>navigation('/privacy')}>Privacy policy </span>
                <span className={'text-14 light-gray underline pointer'} onClick={()=>navigation('/terms')}>Terms of use </span>
                <span className={'text-14 light-gray underline pointer'} onClick={()=>navigation('/cancellation')}>Cancellation policy</span>
            </div>
        </div>
    );
};

export default FooterComponent;
export {};
