import React, {useRef, useState} from 'react';
import {GoogleMap, InfoWindow, LoadScript, Marker} from '@react-google-maps/api';
import OnMapInfoWindowComponent from "./OnMapInfoWindow.component";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";

const mapStyles = {
    height: '400px',
    width: '100%',
};

const defaultCenter = {
    lat: 34.680573,
    lng: 33.047663,
};

const zoomControlStyles: React.CSSProperties = {
    position: "absolute",
    top: "10px",
    left: "10px",
    backgroundColor: "black",
    color: "white",
    padding: "5px",
    borderRadius: "2px",
    cursor: "pointer",
    zIndex: 1,
};

const customMapStyle =[
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#3f4e66"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#e8e8e8"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#1b1b1b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#92aad6"
            },
            {
                "weight": 5
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#92aad6"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#92aad6"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "weight": 1
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
]

const locations = [
    { id: 1, name: 'Место 1', lat: 34.680573, lng: 33.047663, address: 'Kolokotroni 28, Limassol', options: ['guitar', 'piano', 'mic', 'drums'] },
    { id: 2, name: 'Место 2', lat: 40.712776, lng: -73.997418, address: 'Kolokotroni 28, Limassol', options: ['guitar', 'piano', 'mic', 'drums'] },
    { id: 3, name: 'Место 3', lat: 40.721776, lng: -74.005974, address: 'Kolokotroni 28, Limassol', options: ['guitar', 'piano', 'mic', 'drums'] },
];

interface MapPropsInterface {
    width?: string | number;
    height?: string | number;
    locations?: any[];
    openPopup?: boolean;
    handleMarkerClick?: any
}

const App: React.FC<MapPropsInterface> = ({
                                              handleMarkerClick = ()=>{},
                                              openPopup = true,
                                            height = '400px',
                                            width= '100%',
                                            locations= []
                                          }) => {
    const [selectedLocation, setSelectedLocation] = useState<any | null>(null);
    const mapRef = useRef<any>(null);


    const onMarkerClick = (location: any) => {
        setSelectedLocation(location);
        handleMarkerClick(location)
    };

    const renderMarkers = () => {
        return locations.map((location) => (
            <Marker
                key={location.id}
                position={{ lat: location.lat, lng: location.lng }}
                onClick={() => onMarkerClick(location)}
            />
        ));
    };

    function handleBook () {
    }

    const onLoad = (map: any) => {
        mapRef.current = map;
        map.setZoom(15); // Set the initial zoom level once the map is loaded
    };

    function getMapStyles() {
        return {
            height,
            width,
        }
    }

    return (
        <LoadScript googleMapsApiKey="AIzaSyBePGxNqTaL5HljGHwiLX13xXNYct7sM6k">
            <GoogleMap mapContainerStyle={getMapStyles()} zoom={16} center={defaultCenter} options={{ styles: customMapStyle, fullscreenControl: false, mapTypeControl: false, streetViewControl: false }}  onLoad={onLoad} // Callback to get access to the map instance
            >
                {renderMarkers()}

                {selectedLocation && openPopup && (
                    <InfoWindow
                        position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                        onCloseClick={() => setSelectedLocation(null)}
                    >
                       <OnMapInfoWindowComponent address={selectedLocation.address} options={selectedLocation.options} handleBook={handleBook} />
                    </InfoWindow>
                )}

            </GoogleMap>
        </LoadScript>
    );
};

export default App;
