import React, { ReactNode } from 'react';
import styles from './Loader.module.scss';
import LoaderAnimation from '../../../assets/loader.json';
import Lottie from 'lottie-react';

interface LoaderComponentProps {
    text?: string;
    loading?: boolean;
    button?: string;
    callback?: () => void;
    children?: ReactNode;
}

const LoaderComponent: React.FC<LoaderComponentProps> = ({loading = true, text, button, callback, children }) => {

    return (
        <div className={`gap-30 ${styles.loader}`}>
            {loading && (
                <Lottie animationData={LoaderAnimation} loop={true} />
            )}
            {!loading && (
                <>
                    {children}
                    {button && callback && (
                        <div
                            className={'red-button row align-content-center justify-content-center mt-10 pointer wrap'}
                            onClick={callback}
                        >
                            <span className={'red-button-text'}>{button}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default LoaderComponent;
