import React, {FC} from 'react';
import styles from './DepositeCard.module.scss'
import {ReactComponent as EmptyRadio} from '../../../../assets/images/svg/EmptyRadio.svg';
import {ReactComponent as FilledRadio} from '../../../../assets/images/svg/FilledRadio.svg';

interface Deposite {
    _id: string;
    amount: number;
    type: string;
    bonus: number;
}

interface DepositeCardProps {
    item: Deposite;
    handlePress: any;
    currentItem: string
}

const DepositeCardComponent:React.FC<DepositeCardProps> = ({currentItem, item,handlePress}) => {
    return (
        <div className={`row gap-10 pointer ${styles.depositCard}`} onClick={() => handlePress(item)}>
            {item._id === currentItem ? (<FilledRadio className={'pointer'}/>) : (<EmptyRadio className={'pointer'}/>)}
            <span className={`ml-15 sea-text ${styles.depositTitle}`}>Buy {item.amount} € deposit</span>
            <span className={`${styles.bonus}`}>{item.amount + item.amount * item.bonus / 100}€</span>
            <span className={`${styles.free}`}>get<br/></span>
        </div>
    );
};

export default DepositeCardComponent;
