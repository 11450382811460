import React, {useEffect, useState} from 'react';
import styles from './RoomSelect.module.scss'
import {ReactComponent as SelectedIcon} from "../../../assets/images/svg/SelectedIcon.svg";
import {ReactComponent as NotSelected} from "../../../assets/images/svg/NotSelected.svg";

const RoomsSelectComponent = (props: {items: any[], onChange: any, selected?: any}) => {
    const [selected, setSelected] = useState<number[]>([])
    useEffect(()=>{
        props.onChange(selected)
    }, [selected])

    useEffect(()=>{
        if (props.selected) {
            setSelected(props.selected)
        }
    }, [])

    function handleClick(idx: number) {
        if (selected.includes(idx)===false) {
            setSelected(old=>([...old, idx]))
        } else {
            let tmp = [...selected]
            let id = selected.indexOf(idx)
            if (id>-1) {
                tmp.splice(id, 1)
            }
            setSelected(old=>tmp)
        }
    }
    return (
        <div className={styles['container']}>
            {props.items.map((item, idx)=>(
                <div className={'row align-items-center justify-content-start mb-16 pointer'} onClick={()=>handleClick(idx)}>
                    {selected.includes(idx) && (
                        <SelectedIcon className={'mr-15'} />
                    )}
                    {!selected.includes(idx) && (
                        <NotSelected  className={'mr-15'} />
                    )}
                    <span className={'main-text text-black'}>{item}</span>
                </div>
            ))}
        </div>
    );
};

export default RoomsSelectComponent;
export {};
