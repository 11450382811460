import React, {useEffect, useState} from 'react';
import styles from './Search.module.scss'
import {ReactComponent as CalendarIcon} from "../../../assets/images/svg/CalendarIcon.svg";
import {ReactComponent as RoomIcon} from "../../../assets/images/svg/RoomIcon.svg";
import {ReactComponent as MusicIcon} from "../../../assets/images/svg/MusicIcon.svg";
import {ReactComponent as SelectIcon} from "../../../assets/images/svg/OpenSelectIcon.svg";
import {ReactComponent as InactiveClock} from "../../../assets/images/svg/InactiveClock.svg";
import {ReactComponent as ActiveClock} from "../../../assets/images/svg/ActiveClock.svg";
import Calendar from "./Calendar.component";
import SpecialSelectComponent from "./SpecialSelect.component";
import RoomsSelectComponent from "./RoomsSelect.component";
import {useNavigate} from "react-router-dom";
import TimeSelectComponent from "./TimeSelect.component";
import {useSelector} from "react-redux";
import {selectAllIncludeNames, selectAllRoomNames} from "../../../reducers/buildingSlice";

//const specials = ['drums', 'piano', 'microphone', 'guitar']
//const rooms = ['Rehearsal room', 'Lecture hall', 'Office one', 'Office two']

const SearchComponent = (props: {query? :any}) => {
    const navigation = useNavigate()
    const specials = useSelector(selectAllIncludeNames)
    const rooms = useSelector(selectAllRoomNames)
    const [openedSelects, setOpenedSelects] = useState({
        date: false,
        special: false,
        room: false,
        time: false
    });
    const [searchQuery, setSearchQuery] = useState({
        date: null,
        room: [],
        special: [],
        time: null
    })

    useEffect(()=>{
        if (props.query) {
            setSearchQuery(props.query)
        }
    }, [])

    function formatDateToMonthYear(dateString: any) {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        if (!dateString) {
            dateString = `${new Date().getDate()}.${new Date().getMonth()+1}.${new Date().getFullYear()}`
        }


        const [day, monthIndex, year] = dateString.split('.');
        const monthName = months[Number(monthIndex) - 1];

        return `${day}, ${monthName}`;
    }


    function dateSelected(date: any) {
        setOpenedSelects(old=>({...old, date: false, time: true}))
        setSearchQuery(old=>({...old, date}))
    }

    function handleSelectSpecialChange(array: number[]) {
        // @ts-ignore
        setSearchQuery(old=>({...old, special: array.map(index => specials[index])}))
    }
    function handleSelectRoomChange(array: number[]) {
        // @ts-ignore
        setSearchQuery(old=>({...old, room: array.map(index => rooms[index])}))
    }

    function getRoomsInputContent() {
        if (searchQuery.room && searchQuery.room.length > 0) {
            if (searchQuery.room.join(', ').length>35) {
                return searchQuery.room.join(', ').substring(0, 35) + '...'
            }
            return searchQuery.room.join(', ')
        }
        return 'rooms'
    }

    function getSpecialInputContent() {
        if (searchQuery.special && searchQuery.special.length > 0) {
            if (searchQuery.special.join(', ').length>35) {
                return searchQuery.special.join(', ').substring(0, 35) + '...'
            }
            return searchQuery.special.join(', ')
        }
        return 'special'
    }

    const handleFindButtonClick = () => {
        setOpenedSelects({
            date: false,
            special: false,
            room: false,
            time: false
        })
        // Navigate to the search page and pass the searchQuery as state
        navigation('/search', { state: { searchQuery }});
    };

    function convertTo24HourFormat(timeObj: { time: any; amPm: any; }) {
        const { time, amPm } = timeObj;

        if (amPm.toLowerCase() === 'pm' && time !== 12) {
            return time + 12;
        } else if (amPm.toLowerCase() === 'am' && time === 12) {
            return 0;
        } else if (time === 12 && amPm.toLowerCase() === 'pm') {
            return 12; // noon
        }

        return time;
    }

    function convertTo12HourFormat(hour: number) {
        if (hour === 0) {
            return `${12} am`
        } else if (hour === 12) {
            return `${12} pm`
        } else if (hour > 12) {
             return `${hour - 12} pm`
        } else {
            return `${hour} am`
        }
    }

    function handleSetTime(data: any) {
        setSearchQuery(old=>({...searchQuery, time: convertTo24HourFormat(data)}))
        setOpenedSelects(old=>({...old, time: false}))
    }

    return (
        <>
            <div className={styles.container} style={{zIndex: 99}}>
            <div className={`justify-content-between ${styles['input-wrapper']} align-items-center row pointer`} >
                <div className={'row align-items-center'} onClick={()=>{
                    setOpenedSelects(old=>({...old, date: !old.date}))
                }}>
                    <CalendarIcon />
                    <span className={`main-text ml-10  ${searchQuery.date ? 'text-black' : styles['input-button']}`}>{searchQuery.date ? searchQuery.date : 'date'}</span>
                    <SelectIcon className={'ml-10'} />
                </div>
                <div className={'row align-items-center gap-5'} onClick={()=>{
                    setOpenedSelects(old=>({...old, time: !old.time}))
                }}>
                    {searchQuery.time && (
                        <span className={'text-black'}>{convertTo12HourFormat(searchQuery.time)}</span>
                    )}
                    {searchQuery.time && (
                        <ActiveClock />
                    )}

                    {!searchQuery.time && (
                        <InactiveClock />
                    )}
                </div>
            </div>
            <div className={styles.divider} />
            <div className={`${styles['input-wrapper']} align-items-center row pointer`} onClick={()=>{
                setOpenedSelects(old=>({...old, special: !old.special}))
            }}>
                <MusicIcon />
                <span className={`main-text ml-10 ${searchQuery.special && searchQuery.special.length > 0 ? 'text-black' : styles['input-button']}`}>{getSpecialInputContent()}</span>
                <SelectIcon className={'ml-10'} />
            </div>
            <div className={styles.divider} />
            <div className={`${styles['input-wrapper']} align-items-center row pointer`} onClick={()=>setOpenedSelects(old=>({...old, room: !old.room}))}>
                <RoomIcon />
                <span className={`main-text ml-10 ${searchQuery.room && searchQuery.room.length > 0 ? 'text-black' : styles['input-button']}`}>{getRoomsInputContent()}</span>
                <SelectIcon className={'ml-10'} />
            </div>
            <div className={'red-button pointer row justify-content-center align-items-center'} style={{width: 154, boxShadow: 'none'}} onClick={handleFindButtonClick}>
                <span className={'red-button-text'}>Find</span>
            </div>
        </div>
            <div className={styles.unfoldedContainer} style={{zIndex: 10}}>
                {openedSelects.date && (
                    <Calendar
                        handleCancelButton={()=>setOpenedSelects((old=>({...old, date: false})))}
                        handleDateSelect={dateSelected}
                    />
                )}
                {openedSelects.special && (
                    <SpecialSelectComponent  items={specials} onChange={handleSelectSpecialChange} selected={searchQuery.special.map((name: any) => specials.indexOf(name))}/>
                )}
                {openedSelects.room && (
                    <RoomsSelectComponent  items={rooms} onChange={handleSelectRoomChange} selected={searchQuery.room.map((name: any) => rooms.indexOf(name))}/>
                )}
                {openedSelects.time && (
                    <TimeSelectComponent  date={formatDateToMonthYear(searchQuery.date)} ok={handleSetTime} skip={()=> {
                        setOpenedSelects(old => ({...old, time: !old.time}))
                        setSearchQuery(old=>({...old, time: null}))
                    }}/>
                )}
                {(openedSelects.time || openedSelects.date || openedSelects.special || openedSelects.room) && (
                    <div className={styles.overlay} onClick={()=>{setOpenedSelects({time: false, date: false, room: false, special: false})}}/>
                )}
            </div>
        </>
    );
};

export default SearchComponent;
export {};
