import React from 'react';
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import styles from '../assets/scss/hiw.module.scss'
import {ReactComponent as Path} from '../assets/images/hiw/path.svg';
import {ReactComponent as First} from '../assets/images/hiw/hiw_d_1.svg';
import {ReactComponent as Second} from '../assets/images/hiw/hiw_d_2.svg';
import {ReactComponent as Third} from '../assets/images/hiw/hiw_d_3.svg';

const SeaDot = () => (
    <div className={styles['sea-dot']}>{''}</div>
)



const HowItWorksItem = (props: {item: any}) => {
  return (
          <div className={`row align-items-start gap-20 ${styles['hiw-card']}`}>
              <div style={{width: 90, height: 90, borderRadius: 45, background: '#343643'}} className={'row align-items-center justify-content-center'}>
                  <span className={'h2-text sea-text'}>{props.item.number}</span>
              </div>

              <div className={`col gap-8 ${styles.content}`}>
                  <div className={'col'}>
                    <span className={'text-price'}>{props.item.title}</span>
                  </div>

                  {props.item.content.map((item: any)=>(
                      <div className={`row align-items-center gap-10 ${styles['hiw-block-items']}`}>
                          <SeaDot />
                          <span style={{wordWrap: 'break-word'}}>{item}</span>
                      </div>
                  ))}
              </div>
          </div>
  )
}

const HowItWorks = () => {
    const hiwItems = [
        {
            number: '01',
            title: 'Start',
            content: [
                    'Book and pay for the required office, after which you will receive a personal code',
                    'As soon as your time comes, from the first minute the code will apply to the main door of the building and your office'
            ]
        },
        {
            number: '02',
            title: 'Process',
            content: [
                'If you are uncomfortable with the temperature in the office, contact the administrator in any of the ways',
                'You are not allowed to eat or leave trash inside the office. Please use the trash can',
                'We have a wonderful kitchen that you can use free of charge comfortably',
                'If you are missing cables or something doesn\'t work, contact the admin'
            ]
        },
        {
            number: '03',
            title: 'Finish',
            content: [
                'After your time, make sure you clean up after yourself and turn off the lights',
                'You have 55 minutes for your lesson and the rest 5 minutes for packing and getting ready to leave'
            ]
        }
    ]
    return (
        <div className={`wrapper relative col ${styles.page}`}>
            <Path className={styles.path} />
            <div className={'row justify-content-between align-items-center mb-40'}>
                <PageTitleComponent backLink={'/'} title={'How it works'} />
            </div>

            <div className={'row align-items-center justify-content-between'}>
                <HowItWorksItem item={hiwItems[0]} />
                <First style={{position: 'absolute', top: 50, right: 0}} />
            </div>
            <div className={`row ${styles.second}`}>
                <Second style={{position: 'absolute', top: '-60%', right: '100%'}} />
                <HowItWorksItem item={hiwItems[1]} />
            </div>
            <div className={`row ${styles.third}`}>
                <HowItWorksItem item={hiwItems[2]} />
                <Third  style={{position: 'absolute', top: '-60%', left: '100%', zIndex: 2}} />
            </div>
            <div className={'col gap-20'} style={{position: "absolute", bottom: 0}}>
                <span className={'text-price'}>Plan of the building</span>
                <img height={704} width={1164} src={require('../assets/images/hiw/draft.png')}/>
            </div>
        </div>
    );
};

export default HowItWorks;
export {};
