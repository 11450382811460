import React, {useEffect, useState} from 'react';
import styles from './AboutUsPage.module.scss'
import {ReactComponent as TopPic} from "./topPic.svg";
import {ReactComponent as ArrowButton} from "./Arrow.svg";
import {ReactComponent as DJ} from "./DJ.svg";
import {ReactComponent as Passcode} from "./Passcode.svg";
import TopBar from "../TopBar/TopBar";
import {useNavigate} from "react-router-dom";

const AboutUsGallery = (props: {items: any[], width: number}) => {
  const [galleryOffset, setGalleryOffset] = useState<number>(0)
  const [imageWidth, setImageWidth] = useState<number>(190)
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(1)

  useEffect(()=>{
      setImageWidth(props.width)
  }, [])

    function handleGalleryNext() {
        if (currentImageIndex === props.items.length) {
            setCurrentImageIndex(1)
            setGalleryOffset(0)
        } else {
            setCurrentImageIndex(old=>old+1)
            setGalleryOffset(currentImageIndex * imageWidth * -1)
        }
    }

  return (
      <div className={'col'}>
          <div className={`row gap-20 ${styles.gallery} ${styles['gallery-row']}`} style={{marginLeft: galleryOffset}}>
              {props.items.map((image:any)=>(
                  <img width={imageWidth}  src={image} />
              ))}
          </div>
          <ArrowButton className={styles.arrow} onClick={handleGalleryNext}/>
      </div>
  )
}

const AboutUsPageComponent = () => {
    const imageSet = [
        require('./images/phone1.png'),
        require('./images/phone2.png'),
        require('./images/phone3.png')
    ]
    const equipmentImageSet = [
        require('./images/equipment1.png'),
        require('./images/equipment2.png')
    ]
    const placesImageSet = [
        require('./images/places1.png'),
        require('./images/places2.png'),
        require('./images/places3.png')
    ]
    const renovationsImageSet = [
        require('./images/renov1.png'),
        require('./images/renov2.png'),
        require('./images/renov3.png')
    ]
    const locksImageSet = [
        require('./images/locks1.png'),
        require('./images/locks2.png')
    ]
    const navigation = useNavigate()
    return (
       <>
           <TopBar backLink={'/'} MiddlePart={'About Us'} />
           <div className={`${styles.container} wrapper col`}>
               <TopPic className={styles.pic} />
               <p>Music co-working is a place where everyone shows any musical abilities, tries different instruments and vocals, both alone, just with a friend or with your coach. You can choose and invite your own teacher or contact us for an advice. The cost of each room includes a professional unique soundproofed space, the usage of high-quality music equipment, your safety and access to digital locks.</p>
                <p>
                    Also, you have an opportunity to provide lectures in our lecture hall, where you will find a pleasant atmosphere with fireplace and TV. Our beautiful kitchen with coffee machine is also at your service. We do everything for your convenience and are always open to your recommendations to improve and upgrade our quality and request any new services.
                </p>
                <p>Our studios are perfect for musicians, vocalists, DJs and actors looking for a space to practise their craft.</p>

                <p>Express yourself with us!</p>
               <div className={'mb-40 mt-20 red-button row center-content pointer mr-20'} style={{width: 220}} onClick={()=>navigation(`/spots`)}>
                   <span className={'red-button-text'}>View our spots</span>
               </div>
               <div className={`col gap-15 ${styles.greyContainer}`}>
                   <span className={'text-price'}>App</span>
                   <p>Try our new web application and you will be amazed how simple and intuitive it is. It has friendly users booking system. You can select rooms on the map, easily pay for your reservation with a card, view your previous bookings, save rooms as favorites and open the studio door with a button from your phone</p>
                   <AboutUsGallery items={imageSet}  width={190}/>
               </div>

               <div className={`mt-40 col gap-15 ${styles.greyContainer}`}>
                   <DJ />
                   <span className={'text-price'}>
                       Professional equipment
                   </span>
                   <p>GoSound rooms feature professional music equipment from famous brands. Guitar amps, drums, keyboards, do console, mixers and other equipment are brand new and ready to use. There are rooms for music groups, drums or vocal rehearsals. Whatever style you play, there is equipment suits your needs</p>
                   <AboutUsGallery items={equipmentImageSet} width={220} />
               </div>

               <div className={`mt-40 col gap-15 ${styles.greyContainer}`}>
                   <span className={'text-price'}>
                       Cozy place
                   </span>
                   <p>We thought about the comfort and relaxation for musicians. All our musicians have access to kitchen with coffee and tea, and a lounge zones with comfortable sofas</p>
                   <AboutUsGallery items={placesImageSet} width={225} />
               </div>

               <div className={`mt-40 col gap-15 ${styles.greyContainer}`}>
                   <Passcode />
                   <span className={'text-price'}>
                       Smart locks
                   </span>
                   <p>We use smart locks connected to our booking system. After website booking, each user receives a personal password for the lock of the room you chose. The same code is available for the main door entrance. Password is valid for the whole duration of your booking time and no one else will be able to enter to the room. All locks are connected to their own network and passwords for new bookings are automatically placed in the corresponding rooms</p>
                   <AboutUsGallery items={locksImageSet} width={220} />
               </div>

               <div className={`mt-40 col gap-15 ${styles.greyContainer}`}>
                   <span className={'text-price'}>
                       Renovation
                   </span>
                   <p>We developed the project and renovation plan in consultation with music producers experienced in the construction of music studios. The walls, floor and ceiling contain a thick layer of special soundproofing materials more than 30 cm and it’s like box.</p>
                   <p>The rehearsal room has additional sound-absorbing panels to adjust the optimal reverberation and echo of the sound</p>
                   <AboutUsGallery items={renovationsImageSet} width={225} />
               </div>
           </div>
       </>
    );
};

export default AboutUsPageComponent;
