import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../reducers/types";
import {ReactComponent as Key} from '../assets/images/svg/KeyBlack.svg';
import {selectBuildingById, selectRoomById} from "../reducers/buildingSlice";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import {ReactComponent as RoomIcon} from "../assets/images/svg/RoomSeaIcon.svg";
import {ReactComponent as Calendar} from "../assets/images/svg/CalendarIconSea.svg";
import {ReactComponent as ClockIcon} from "../assets/images/svg/ClockIconSea.svg";
import {ReactComponent as PinIcon} from "../assets/images/svg/PinSea.svg";
import {ReactComponent as CashIcon} from "../assets/images/svg/CardsIcon.svg";
import {ReactComponent as CopyIcon} from "../assets/images/svg/СopyIcon.svg";

const BookingCompletePage = () => {
    const navigation = useNavigate()
    const location = useLocation();
    const responseData = location.state && location.state.data;
    const building = useSelector(selectBuildingById(responseData[0].buildingID))
    const room = useSelector(selectRoomById(responseData[0].roomID))
    const [confirmation, setConfirmation] = useState<any>([])
    const [copiedStates, setCopiedStates] = useState<boolean[]>(() =>
        confirmation.map(() => false)
    );

    function formatTimeTo12HourFormat(hour: any) {
        const amOrPm = hour >= 12 ? 'pm' : 'am';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const formattedHourWithLeadingZero = formattedHour.toString().padStart(2, '0');


        return `${formattedHourWithLeadingZero} ${amOrPm}`;
    }

    async function copyToClipboard(code: string, index: number) {
        try {
            await navigator.clipboard.writeText(code);

            // Update the copied state for the corresponding item
            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true;
            setCopiedStates(newCopiedStates);

            setTimeout(() => {
                // Reset the copied state after 3 seconds
                const resetCopiedStates = [...copiedStates];
                resetCopiedStates[index] = false;
                setCopiedStates(resetCopiedStates);
            }, 3000);
        } catch (error) {
            console.error('Не удалось скопировать текст: ', error);
        }
    }

    useEffect(()=>{
        if (!responseData) {
            navigation('/')
        } else {
            fillConfirmation()
        }
    }, [])

    function fillConfirmation() {
        let tmp:any = []
        if (responseData) {
            responseData.forEach((rd: any) => {
                // Attach building and room information to each booking
                const bookingWithDetails = {
                    ...rd,
                    buildingID: building,
                    roomID: room
                };
                tmp.push(bookingWithDetails);
            });
        }
        setConfirmation((old:any)=>tmp)
    }

    useEffect(()=>{
        fillConfirmation()
    }, [responseData, building, room])

    function getDayName(dateStr: any) {
        const date = new Date(dateStr);
        // Array of day names
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        // Get the day of the week from the date object
        const dayOfWeek = date.getDay();
        // Return the day name
        return days[dayOfWeek];
    }

    const calculateAmount = (order: any) => {
        if (!order?.paymentIntent?.amount) return 0; // Ensures there is an amount to process, else return 0

        switch (order.typeOfPayment) {
            case 'deposit':
                return order.paymentIntent.amount; // No division needed for 'deposit'
            case 'money':
                return order.paymentIntent.amount / 100; // Divide by 100 for 'money'
            default:
                return 0; // Return 0 if typeOfPayment is undefined or any other unexpected value
        }
    };

    return (
        <div className={'wrapper col gap-10'}>
            <PageTitleComponent backLink={'/private'} title={'Booking complete'} />
            <div className={'row'}>
                <div>
                    {confirmation.map((item: any, index: number)=>(
                        <div className={'col gap-30'}>

                            <div className={'col pa-0'}>
                                <span className={'mt-50 text-12 mb-10'} style={{color: '#d3d3d3'}}>Your pass</span>
                                <div className={'row align-items-start gap-50'}>
                                    <div className={'keycode-wrapper row align-items-center gap-15  justify-content-between'}>
                                        <div className={'row align-items-center gap-15'} style={{justifyContent: 'space-between'}}>
                                            <Key />
                                            <span className={'text-black'}>{item.keyCode}#</span>
                                        </div>
                                        {!copiedStates[index] && (
                                            <CopyIcon className={'pointer'} onClick={() => copyToClipboard(item.keyCode + '#', index)} />
                                        )}
                                        {copiedStates[index] && (
                                            <span className={'sea-text'}>Copied</span>
                                        )}
                                    </div>
                                    <span className={'magenta-text'} style={{width: 260}}>We already sent you this instructions on e-mail </span>
                                </div>
                                <span className={'text-14 mt-10'} style={{width: 280}}>Enter this password on the door lock to open the room</span>

                            </div>
                            <div className={'row align-items-center gap-18'}>
                                <RoomIcon />
                                <span>{item?.roomID?.title || 'Loading...'}</span>
                            </div>
                            <div className={'row align-items-center gap-18'}>
                                <Calendar />
                                <span>{`${new Date(item?.slots[0].date).toDateString()}`}</span>
                            </div>
                            <div className={'row align-items-center gap-18'}>
                                <ClockIcon />
                                <span>{`${formatTimeTo12HourFormat(item?.slots[0].start)} - ${formatTimeTo12HourFormat(item.slots[0].end)}`}</span>
                            </div>
                            <div className={'row align-items-center gap-18'}>
                                <PinIcon />
                                <span className={'review-address'}>{item?.buildingID?.address}</span>
                            </div>
                            {item.typeOfPayment === 'deposit' && (
                                <div className={'row align-items-center gap-18'}>
                                    <CashIcon />
                                    {<span>{item?.amount > 0 ? item?.amount  : 0} €</span>}
                                </div>
                            )}
                            {item.typeOfPayment !== 'money' && (
                                <div className={'row align-items-center gap-18'}>
                                    <CashIcon />
                                    {<span>{item?.paymentIntent?.amount / 100 > 0 ? item?.paymentIntent?.amount / 100 : 0} €</span>}
                                </div>
                            )}
                        </div>
                    ))}
                    <div className={'mb-10 red-button row align-content-center justify-content-center mt-36 pointer wrap'} onClick={()=>navigation('/')}>
                        <span className={'red-button-text'}>Back to main page </span>
                    </div>
                    <div className={'button-dark'} style={{width: 270}} onClick={()=>navigation('/private?tab=2')}>
                        Previous booking
                    </div>
                </div>
                <div>
                    <div className={'mt-60'}>
                        <img width={320} src={require('../assets/images/completeImage.png')} />
                        <p className={'mt-40'}>Take a look for our rules and information <span className={'blue-text pointer'} onClick={()=>{navigation('/how-it-works')}}>How it works</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingCompletePage;
export {};
