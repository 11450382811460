import React, {useEffect, useState} from 'react';
import {ReactComponent as RatingStar} from '../../../assets/images/svg/SeaStar.svg';
import {ReactComponent as SeaPin} from '../../../assets/images/svg/SeaPin.svg';
import styles from './LeftColumn.module.scss'
import RoomDetailsGalleryComponent from "./RoomDetailsGallery.component";
import ImageSliderComponent from "../../common/ImageSlider/ImageSlider.component";
import DynamicIcon from "../../common/DynamicIcon.component";
import {useNavigate} from "react-router-dom";

const ReviewItem = (props: {review: any}) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < Math.round(props.review.rating); i++) {
            stars.push(<RatingStar key={i} />);
        }
        return stars;
    };
    return (
        <div className={'col gap-10 mb-30'}>
            <div className={'row align-items-center justify-content-between'}>
                <span className={'menu-name-chosen'}>{`${props.review.author.name} ${props.review.author.lastName}`}</span>
                <div className={'row align-items-center gap-20'}>
                    <span>{props.review.date}</span>
                    <div className={'row align-items-center'}>
                        {renderStars()}
                    </div>
                </div>

            </div>
            <span className={'main-text'}>{props.review.review}</span>
        </div>
    )
}
const IncludesSection = (props: {includes: any[]}) => {

    return (
        <div className={'row wrap gap-20 mb-50 mt-30'}>
            {props.includes.map((item: any)=>(
                <div className={`row align-items-center gap-20 col-9 mr-40 ${styles['include-item']}`}>
                    <DynamicIcon iconName={item.icon} />
                    <span>{item.quantity && item.quantity > 1 ? '2 x' : ''} {item.name}</span>
                </div>
            ))}
        </div>

    )
}
const LeftColumnComponent = (props: {rating: any, reviews: any[], address: string, title: string, subtitle: string, includes: any[], images: any[], description: string}) => {
    const [moreReviews, setMoreReviews] = useState<number>(props.reviews && props.reviews.length > 3 ? 3 : props.reviews.length)
    const [displayedReviews, setDisplayedReviews] = useState<any>([])
    const navigation = useNavigate()
    function increaseReviewsCount() {
        setMoreReviews(old=>old+3)
    }
    useEffect(() => {
        if (props.reviews && props.reviews.length > 0) {
            // Use the slice method to get the desired reviews
            const displayedReviews = props.reviews.slice(0, moreReviews);
            setDisplayedReviews(displayedReviews);
        }
    }, [moreReviews, props.reviews]);
    return (
        <div className={'col-12 col relative gap-10'}>
            <div className={'row align-items-center'}>
                <RatingStar className={'mr-5'}/>
                <span className={'main-text mr-10'}>{props.rating}</span>
                <span className={styles['review-address']}>{props.reviews.length} reviews</span>
                <SeaPin className={'ml-25 mr-5'} />
                <span className={styles['review-address']}>{props.address}</span>
            </div>

            <ImageSliderComponent images={props.images} withDots={true} width={570} height={380}/>
            <div className={'row mt-40 align-items-center gap-20'}>
                <span className={'sea-text h3-text'}>{props.title}</span>
                <span className={'spot-title'}>{props.subtitle}</span>
            </div>
            <span className={'spot-title mt-20'}>Include: </span>
            <IncludesSection includes={props.includes} />
            <span className={'mb-20'}>{props.description}</span>
            {props.title === 'Rehearsal Point' && (
                <span className={'mb-50'} style={{fontWeight: 'bold', color: 'red'}}>Your rehearsal after 14.00 must be at least 2 hours or more</span>
            )}
            <div className={'col-24 col pa-0'}>
                <span className={'spot-title mb-20'}>Reviews: </span>
                {displayedReviews.length === 0 && (
                    <>
                        <span className={'mb-20 '}>There are no reviews yet.
                               <span className={'ml-10 blue-text pointer'} onClick={()=>navigation('/private?tab=2')}>Leave the first one</span>
                        </span>
                    </>
                )}
                        {displayedReviews.map((r: any)=>(
                            <ReviewItem review={r} />
                        ))}
                {displayedReviews.length>0 && (<span className={'pointer blue-text'} onClick={increaseReviewsCount}>More reviews</span>)}
            </div>
        </div>
    );
};

export default LeftColumnComponent;
export {};
