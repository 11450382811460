import React from 'react';
import BreadcrumbsComponent from "../components/common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";

const PrivacyPage = () => {
    const crumbs = [
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Terms of use',
            link: '/terms'
        }
    ]
    return (
        <div className={'wrapper col gap-30'}>
            <BreadcrumbsComponent crumbs={crumbs}/>
            <PageTitleComponent backLink={'/'} title={'Terms of use'} />

            <div className="frame">
                <div className="div">
                    <p className="text-wrapper">Agreement to our legal terms</p>
                    <div className="div-2">
                        <p className="p">
                            We are GoSound (&#39;Company&#39;, &#39;we&#39;, &#39;us&#39;, or &#39;our&#39;), a company registered in
                            Cyprus at Kolokotroni 28, Limassol, Limassol 3032.
                            <br />
                            <br />
                            We operate the website http://gscoworking.com/ (the &#39;Site&#39;), as well as any other related products
                            and services that refer or link to these legal terms (the &#39;Legal Terms&#39;) (collectively, the
                            &#39;Services&#39;).
                            <br />
                            <br />
                            We are the music spaces rental service with simple convenient booking system. Rooms are automatically
                            accessed through the smart locks controlled by web application.
                            <br />
                            <br />
                            You can contact us by phone at +357 959 657 84, email at hello@gscoworking.com, or by mail to Kolokotroni
                            28, Limassol, Limassol 3032, Cyprus.
                            <br />
                            <br />
                            These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf
                            of an entity (&#39;you&#39;), and GoSound, concerning your access to and use of the Services. You agree that
                            by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF
                            YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
                            AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                            <br />
                            <br />
                            Supplemental terms and conditions or documents that may be posted on the Services from time to time are
                            hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make
                            changes or modifications to these Legal Terms from time to time. We will alert you about any changes by
                            updating the &#39;Last updated&#39; date of these Legal Terms, and you waive any right to receive specific
                            notice of each such change. It is your responsibility to periodically review these Legal Terms to stay
                            informed of updates. You will be subject to, and will be deemed to have been made aware of and to have
                            accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such
                            revised Legal Terms are posted.
                            <br />
                            <br />
                            The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not
                            permitted to use or register for the Services.
                            <br />
                            <br />
                            We recommend that you print a copy of these Legal Terms for your records.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">Table of contents</div>
                    <div className="div">
                        <div className="div-2">
                            <div className="text-wrapper">1. Our services</div>
                            <p className="text-wrapper-2">
                                The information provided when using the Services is not intended for distribution to or use by any person
                                or entity in any jurisdiction or country where such distribution or use would be contrary to law or
                                regulation or which would subject us to any registration requirement within such jurisdiction or country.
                                Accordingly, those persons who choose to access the Services from other locations do so on their own
                                initiative and are solely responsible for compliance with local laws, if and to the extent local laws are
                                applicable.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">2. Intellectual property rights</div>
                    <div className="div-2">
                        <div className="text-wrapper-3">Our intellectual property</div>
                        <p className="text-wrapper-2">
                            We are the owner or the licensee of all intellectual property rights in our Services, including all source
                            code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in
                            the Services (collectively, the &#39;Content&#39;), as well as the trademarks, service marks, and logos
                            contained therein (the &#39;Marks&#39;).
                            <br />
                            <br />
                            Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property
                            rights and unfair competition laws) and treaties in the United States and around the world.
                            <br />
                            <br />
                            The Content and Marks are provided in or through the Services &#39;AS IS&#39; for your personal,
                            non-commercial use only.
                        </p>
                    </div>
                    <div className="div-2">
                        <p className="text-wrapper-3">Your use of our Services</p>
                        <p className="text-wrapper-2">
                            Subject to your compliance with these Legal Terms, including the &#39;PROHIBITED ACTIVITIES&#39; section
                            below, we grant you a non-exclusive, non-transferable, revocable licence to:
                            <br />
                            <br />
                            access the Services; and
                            <br />
                            <br />
                            download or print a copy of any portion of the Content to which you have properly gained access.
                            <br />
                            <br />
                            solely for your personal, non-commercial use.
                            <br />
                            <br />
                            Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or
                            Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
                            translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
                            whatsoever, without our express prior written permission.
                            <br />
                            <br />
                            If you wish to make any use of the Services, Content, or Marks other than as set out in this section or
                            elsewhere in our Legal Terms, please address your request to: hello@gscoworking.com. If we ever grant you
                            the permission to post, reproduce, or publicly display any part of our Services or Content, you must
                            identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or
                            proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                            <br />
                            <br />
                            We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                            <br />
                            <br />
                            Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and
                            your right to use our Services will terminate immediately.
                        </p>
                    </div>
                    <div className="div-2">
                        <div className="text-wrapper-3">Your submissions</div>
                        <p className="text-wrapper-2">
                            Please review this section and the &#39;PROHIBITED ACTIVITIES&#39; section carefully prior to using our
                            Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any
                            content through the Services.
                            <br />
                            <br />
                            Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information
                            about the Services (&#39;Submissions&#39;), you agree to assign to us all intellectual property rights in
                            such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and
                            dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to
                            you.
                            <br />
                            <br />
                            You are responsible for what you post or upload: By sending us Submissions through any part of the Services
                            you:
                            <br />
                            <br />
                            <ul className={'col gap-10 pl-30'}>
                                <li>confirm that you have read and agree with our &#39;PROHIBITED ACTIVITIES&#39; and will not post, send,
                                    publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful,
                                    harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group,
                                    sexually explicit, false, inaccurate, deceitful, or misleading;</li>
                                <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission;</li>
                                <li>warrant that any such Submission are original to you or that you have the necessary rights and licences to
                                    submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation
                                    to your Submissions; and</li>
                                <li>warrant and represent that your Submissions do not constitute confidential information.</li>
                            </ul>
                            <br />
                            You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all
                            losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual
                            property rights, or (c) applicable law.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">3. User representations</div>
                    <div className="div-2">
                        <p className="p">
                            By using the Services, you represent and warrant that: (1) all registration information you submit will be
                            true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly
                            update such registration information as necessary; (3) you have the legal capacity and you agree to comply
                            with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not
                            access the Services through automated or non-human means, whether through a bot, script or otherwise; (6)
                            you will not use the Services for any illegal or unauthorised purpose; and (7) your use of the Services will
                            not violate any applicable law or regulation.
                            <br />
                            <br />
                            If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
                            suspend or terminate your account and refuse any and all current or future use of the Services (or any
                            portion thereof).
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">4. User registration</div>
                    <div className="div-2">
                        <p className="p">
                            You may be required to register to use the Services. You agree to keep your password confidential and will
                            be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change
                            a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene,
                            or otherwise objectionable.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">5. Purchases and payment</div>
                    <div className="div-2">
                        <p className="p">
                            We accept the following forms of payment:
                            <ul className={'col gap-10 pl-30'}>
                                <li>Visa</li>
                                <li>Mastercard</li>
                            </ul>
                            <br />
                            You agree to provide current, complete, and accurate purchase and account information for all purchases made
                            via the Services. You further agree to promptly update account and payment information, including email
                            address, payment method, and payment card expiration date, so that we can complete your transactions and
                            contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may
                            change prices at any time. All payments shall be in Euros.
                            <br />
                            <br />
                            You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping
                            fees, and you authorise us to charge your chosen payment provider for any such amounts upon placing your
                            order. If your order is subject to recurring charges, then you consent to our charging your payment method
                            on a recurring basis without requiring your prior approval for each recurring charge, until such time as you
                            cancel the applicable order. We reserve the right to correct any errors or mistakes in pricing, even if we
                            have already requested or received payment.
                            <br />
                            <br />
                            We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit
                            or cancel quantities purchased per person, per household, or per order. These restrictions may include
                            orders placed by or under the same customer account, the same payment method, and/or orders that use the
                            same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole
                            judgement, appear to be placed by dealers, resellers, or distributors.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">6. Cancellation</div>
                    <div className="div-2">
                        <p className="p">
                            Please note that once we’ve confirmed your appointment, we reserve time in our schedule to attend to you.
                            But if you wish to cancel our appointment less then 24 hours before booking, you’ll be charged a
                            cancellation fee of 100%. To avoid our cancellation charge, we recommend you provide cancellation notice at
                            least 24 before your booking. You can cancel your booking in the &#34;bookings&#34; section in your personal
                            account, or by writing to us in telegram
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">7. Prohibited activities</div>
                    <div className="div-2">
                        <p className="p">
                            You may not access or use the Services for any purpose other than that for which we make the Services
                            available. The Services may not be used in connection with any commercial endeavours except those that are
                            specifically endorsed or approved by us.
                            <br />
                            <br />
                            As a user of the Services, you agree not to:
                            <br />
                            <br />
                            <ul className={'col gap-10 pl-30'}>
                                <li> Systematically retrieve data or other content from the Services to create or compile, directly or
                                    indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                                <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                                    information such as user passwords.</li>
                                <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including
                                    features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the
                                    Services and/or the Content contained therein.</li>
                                <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
                                <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
                                <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                                <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                                <li>Engage in unauthorised framing of or linking to the Services.</li>
                                <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material,
                                    including excessive use of capital letters and spamming (continuous posting of repetitive text), that
                                    interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts,
                                    alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
                                <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any
                                    data mining, robots, or similar data gathering and extraction tools.</li>
                                <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                                <li>Attempt to impersonate another user or person or use the username of another user.</li>
                                <li> Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                                    information collection or transmission mechanism, including without limitation, clear graphics interchange
                                    formats (&#39;gifs&#39;), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as
                                    &#39;spyware&#39; or &#39;passive collection mechanisms&#39; or &#39;pcms&#39;).</li>
                                <li> Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to
                                    the Services.</li>
                                <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of
                                    the Services to you.</li>
                                <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or
                                    any portion of the Services.</li>
                                <li>Copy or adapt the Services&#39; software, including but not limited to Flash, PHP, HTML, JavaScript, or
                                    other code.</li>
                                <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
                                    software comprising or in any way making up a part of the Services.</li>
                                <li>  Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
                                    distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
                                    offline reader that accesses the Services, or use or launch any unauthorised script or other software.</li>
                                <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
                                <li>Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by
                                    electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
                                    automated means or under false pretences.</li>
                                <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content
                                    for any revenue-generating endeavour or commercial enterprise.</li>
                                <li>Use the Services to advertise or offer to sell goods and services.</li>
                                <li>Sell or otherwise transfer your profile.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">8. User generated contributions</div>
                    <div className="div-2">
                        <p className="p">
                            The Services does not offer users to submit or post content. We may provide you with the opportunity to
                            create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to
                            us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics,
                            comments, suggestions, or personal information or other material (collectively, &#39;Contributions&#39;).
                            Contributions may be viewable by other users of the Services and through third-party websites. As such, any
                            Contributions you transmit may be treated in accordance with the Services&#39; Privacy Policy. When you
                            create or make available any Contributions, you thereby represent and warrant that:
                            <ul>
                                <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
                                    copying of your Contributions do not and will not infringe the proprietary rights, including but not limited
                                    to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                                <li>You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions
                                    to use and to authorise us, the Services, and other users of the Services to use your Contributions in any
                                    manner contemplated by the Services and these Legal Terms.</li>
                                <li>You have the written consent, release, and/or permission of each and every identifiable individual person in
                                    your Contributions to use the name or likeness of each and every such identifiable individual person to
                                    enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal
                                    Terms.</li>
                                <li>Your Contributions are not false, inaccurate, or misleading.</li>
                                <li>Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid schemes,
                                    chain letters, spam, mass mailings, or other forms of solicitation.</li>
                                <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or
                                    otherwise objectionable (as determined by us).</li>
                                <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                                <li>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person
                                    and to promote violence against a specific person or class of people.</li>
                                <li> Your Contributions do not violate any applicable law, regulation, or rule.</li>
                                <li> Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                                <li> Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to
                                    protect the health or well-being of minors.</li>
                                <li> Your Contributions do not include any offensive comments that are connected to race, national origin,
                                    gender, sexual preference, or physical handicap.</li>
                                <li> Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal
                                    Terms, or any applicable law or regulation.</li>
                            </ul>

                            <br />
                            Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among
                            other things, termination or suspension of your rights to use the Services.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">9. Contribution licence</div>
                    <div className="div-2">
                        <p className="p">
                            You and Services agree that we may access, store, process, and use any information and personal data that
                            you provide following the terms of the Privacy Policy and your choices (including settings).
                            <br />
                            <br />
                            By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such
                            feedback for any purpose without compensation to you.
                            <br />
                            <br />
                            We do not assert any ownership over your Contributions. You retain full ownership of all of your
                            Contributions and any intellectual property rights or other proprietary rights associated with your
                            Contributions. We are not liable for any statements or representations in your Contributions provided by you
                            in any area on the Services. You are solely responsible for your Contributions to the Services and you
                            expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against
                            us regarding your Contributions.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">10. Guidelines for reviews</div>
                    <div className="div-2">
                        <p className="p">
                            We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply
                            with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed;
                            (2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language;
                            (3) your reviews should not contain discriminatory references based on religion, race, gender, national
                            origin, age, marital status, sexual orientation, or disability; (4) your reviews should not contain
                            references to illegal activity; (5) you should not be affiliated with competitors if posting negative
                            reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you may not post any
                            false or misleading statements; and (8) you may not organise a campaign encouraging others to post reviews,
                            whether positive or negative.
                            <br />
                            <br />
                            We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen
                            reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not
                            endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or
                            partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from
                            any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free,
                            fully paid, assignable, and sublicensable right and licence to reproduce, modify, translate, transmit by any
                            means, display, perform, and/or distribute all content relating to review.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">11. Services management</div>
                    <div className="div-2">
                        <p className="p">
                            We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal
                            Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or
                            these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in
                            our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
                            disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in
                            our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise
                            disable all files and content that are excessive in size or are in any way burdensome to our systems; and
                            (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate
                            the proper functioning of the Services.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">12. Privacy policy</div>
                    <div className="div-2">
                        <p className="p">
                            <span className="span">We care about data privacy and security. Please review our Privacy Policy: </span>
                            <a href="http://gscoworking.com/privacy" rel="noopener noreferrer" target="_blank">
                                <span className="text-wrapper-4">http://gscoworking.com/privacy</span>
                            </a>
                            <span className="span">
              . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these
              Legal Terms. Please be advised the Services are hosted in Cyprus. If you access the Services from any
              other region of the world with laws or other requirements governing personal data collection, use, or
              disclosure that differ from applicable laws in Cyprus, then through your continued use of the Services,
              you are transferring your data to Cyprus, and you expressly consent to have your data transferred to and
              processed in Cyprus.
            </span>
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">13. Term and termination</div>
                    <div className="div-2">
                        <p className="p">
                            These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY
                            OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                            LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
                            FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
                            COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
                            PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
                            TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                            <br />
                            <br />
                            If we terminate or suspend your account for any reason, you are prohibited from registering and creating a
                            new account under your name, a fake or borrowed name, or the name of any third party, even if you may be
                            acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the
                            right to take appropriate legal action, including without limitation pursuing civil, criminal, and
                            injunctive redress.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">14. Modifications and interruptions</div>
                    <div className="div-2">
                        <p className="p">
                            We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason
                            at our sole discretion without notice. However, we have no obligation to update any information on our
                            Services. We also reserve the right to modify or discontinue all or part of the Services without notice at
                            any time. We will not be liable to you or any third party for any modification, price change, suspension, or
                            discontinuance of the Services.
                            <br />
                            <br />
                            We cannot guarantee the Services will be available at all times. We may experience hardware, software, or
                            other problems or need to perform maintenance related to the Services, resulting in interruptions, delays,
                            or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the
                            Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever
                            for any loss, damage, or inconvenience caused by your inability to access or use the Services during any
                            downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to
                            maintain and support the Services or to supply any corrections, updates, or releases in connection
                            therewith.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">15. Governing law</div>
                    <div className="div-2">
                        <p className="p">
                            These Legal Terms are governed by and interpreted following the laws of Cyprus, and the use of the United
                            Nations Convention of Contracts for the International Sales of Goods is expressly excluded. If your habitual
                            residence is in the EU, and you are a consumer, you additionally possess the protection provided to you by
                            obligatory provisions of the law in your country to residence. GoSound and yourself both agree to submit to
                            the non-exclusive jurisdiction of the courts of Limassol, which means that you may make a claim to defend
                            your consumer protection rights in regards to these Legal Terms in Cyprus, or in the EU country in which you
                            reside.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">16. Dispute resolution</div>
                    <div className="div-2">
                        <div className="text-wrapper-3">Informal Negotiations</div>
                        <p className="text-wrapper-2">
                            To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal
                            Terms (each a &#39;Dispute&#39; and collectively, the &#39;Disputes&#39;) brought by either you or us
                            (individually, a &#39;Party&#39; and collectively, the &#39;Parties&#39;), the Parties agree to first
                            attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least
                            thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from
                            one Party to the other Party.
                        </p>
                    </div>
                    <div className="div-2">
                        <div className="text-wrapper-3">Binding Arbitration</div>
                        <p className="text-wrapper-2">
                            Any dispute arising from the relationships between the Parties to these Legal Terms shall be determined by
                            one arbitrator who will be chosen in accordance with the Arbitration and Internal Rules of the European
                            Court of Arbitration being part of the European Centre of Arbitration having its seat in Strasbourg, and
                            which are in force at the time the application for arbitration is filed, and of which adoption of this
                            clause constitutes acceptance. The seat of arbitration shall be Limassol, Cyprus. The language of the
                            proceedings shall be English. Applicable rules of substantive law shall be the law of Cyprus.
                        </p>
                    </div>
                    <div className="div-2">
                        <div className="text-wrapper-3">Restrictions</div>
                        <p className="text-wrapper-2">
                            The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To
                            the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is
                            no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class action
                            procedures; and (c) there is no right or authority for any Dispute to be brought in a purported
                            representative capacity on behalf of the general public or any other persons.
                        </p>
                    </div>
                    <div className="div-2">
                        <p className="text-wrapper-3">Exceptions to Informal Negotiations and Arbitration</p>
                        <p className="text-wrapper-2">
                            The Parties agree that the following Disputes are not subject to the above provisions concerning informal
                            negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity
                            of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from,
                            allegations of theft, piracy, invasion of privacy, or unauthorised use; and (c) any claim for injunctive
                            relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to
                            arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and
                            such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction
                            above, and the Parties agree to submit to the personal jurisdiction of that court.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">17. Corrections</div>
                    <div className="div-2">
                        <p className="p">
                            There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
                            including descriptions, pricing, availability, and various other information. We reserve the right to
                            correct any errors, inaccuracies, or omissions and to change or update the information on the Services at
                            any time, without prior notice.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">18. Disclaimer</div>
                    <div className="div-2">
                        <p className="p">
                            The services are provided on an as-is and as-available basis. You agree that your use of the services will
                            be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or
                            implied, in connection with the services and your use thereof, including, without limitation, the implied
                            warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties
                            or representations about the accuracy or completeness of the services&#39; content or the content of any
                            websites or mobile applications linked to the services and we will assume no liability or responsibility for
                            any (1) errors, mistakes, or inaccuracies of content and materials, (2) personal injury or property damage,
                            of any nature whatsoever, resulting from your access to and use of the services, (3) any unauthorised access
                            to or use of our secure servers and/or any and all personal information and/or financial information stored
                            therein, (4) any interruption or cessation of transmission to or from the services, (5) any bugs, viruses,
                            trojan horses, or the like which may be transmitted to or through the services by any third party, and/or
                            (6) any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a
                            result of the use of any content posted, transmitted, or otherwise made available via the services. We do
                            not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered
                            by a third party through the services, any hyperlinked website, or any website or mobile application
                            featured in any banner or other advertising, and we will not be a party to or in any way be responsible for
                            monitoring any transaction between you and any third-party providers of products or services. As with the
                            purchase of a product or service through any medium or in any environment, you should use your best
                            judgement and exercise caution where appropriate.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">19. Limitations of liability</div>
                    <div className="div-2">
                        <p className="p">
                            In no event will we or our directors, employees, or agents be liable to you or any third party for any
                            direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit,
                            lost revenue, loss of data, or other damages arising from your use of the services, even if we have been
                            advised of the possibility of such damages.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">20. Indemnification</div>
                    <div className="div-2">
                        <p className="p">
                            You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
                            respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim,
                            or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out
                            of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and
                            warranties set forth in these Legal Terms; (4) your violation of the rights of a third party, including but
                            not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the
                            Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at
                            your expense, to assume the exclusive defence and control of any matter for which you are required to
                            indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use
                            reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this
                            indemnification upon becoming aware of it.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">21. User data</div>
                    <div className="div-2">
                        <p className="p">
                            We will maintain certain data that you transmit to the Services for the purpose of managing the performance
                            of the Services, as well as data relating to your use of the Services. Although we perform regular routine
                            backups of data, you are solely responsible for all data that you transmit or that relates to any activity
                            you have undertaken using the Services. You agree that we shall have no liability to you for any loss or
                            corruption of any such data, and you hereby waive any right of action against us arising from any such loss
                            or corruption of such data.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <p className="text-wrapper">22. Electronic communications, transactions, and signatures</p>
                    <div className="div-2">
                        <p className="p">
                            Visiting the Services, sending us emails, and completing online forms constitute electronic communications.
                            You consent to receive electronic communications, and you agree that all agreements, notices, disclosures,
                            and other communications we provide to you electronically, via email and on the Services, satisfy any legal
                            requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                            CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
                            TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements
                            under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an
                            original signature or delivery or retention of non-electronic records, or to payments or the granting of
                            credits by any means other than electronic means.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">23. Miscellaneous</div>
                    <div className="div-2">
                        <p className="p">
                            These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the
                            Services constitute the entire agreement and understanding between you and us. Our failure to exercise or
                            enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
                            provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of
                            our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                            damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part
                            of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or
                            part of the provision is deemed severable from these Legal Terms and does not affect the validity and
                            enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency
                            relationship created between you and us as a result of these Legal Terms or use of the Services. You agree
                            that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive
                            any and all defences you may have based on the electronic form of these Legal Terms and the lack of signing
                            by the parties hereto to execute these Legal Terms.
                        </p>
                    </div>
                </div>
                <div className="div">
                    <div className="text-wrapper">24. Contact us</div>
                    <div className="div-2">
                        <p className="p">
                            In order to resolve a complaint regarding the Services or to receive further information regarding use of
                            the Services, please contact us at:
                            <br />
                            <br />
                            GoSound
                            <br />
                            Kolokotroni 28, Limassol
                            <br />
                            Limassol 3032
                            <br />
                            Cyprus
                            <br />
                            <a href={'mailto:hello@gscoworking.com'} className={'text-white text-14'}>hello@gscoworking.com</a>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PrivacyPage;
export {};
