import React, { useEffect, useState } from 'react';
import MessageComponent from "./Message.component";
import styles from './Notifications.module.scss'
import axios from "axios";
import { useDispatch } from "react-redux";
import { checkLocalStorageForLoggedInStatus } from "../../../reducers/authSlice";
import {ReactComponent as Folded} from '../../../assets/images/svg/FoldedRoomIcon.svg';
import {ReactComponent as Unfolded} from '../../../assets/images/svg/UnfoldedRoomIcon.svg';

const NotificationsComponent = (props: { user: any, notifications: any }) => {

    const [readNotifications, setReadNotifications] = useState<any>([]);
    const [unreadNotifications, setUnreadNotifications] = useState<any>([]);
    const [expandedDate, setExpandedDate] = useState<string | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const unreadNotifications = props.notifications.filter(
            (notification: { isRead: any }) => !notification.isRead
        );
        const readNotifications = props.notifications.filter(
            (notification: { isRead: any }) => notification.isRead
        );

        setReadNotifications(readNotifications);
        setUnreadNotifications(unreadNotifications);

        const timer = setTimeout(async () => {
            try {
                if (unreadNotifications.length>0) {
                    await axios.put(`https://gscoworking.com/api/notifications/mark-all-as-read/${props.user}`);
                    // @ts-ignore
                    dispatch(checkLocalStorageForLoggedInStatus())
                    setReadNotifications((prev:any) => [...prev, ...unreadNotifications]);
                    setUnreadNotifications([]);
                }
            } catch (err) {
                console.error('Error marking all notifications as read:', err);
            }
        }, 5000);

    }, [props.notifications]);

    const groupedNotifications = props.notifications.reduce((groups: any, notification: any) => {
        const date = notification.date.split('T')[0];
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(notification);
        return groups;
    }, {});

    const toggleExpand = (date: string) => {
        setExpandedDate(prevDate => (prevDate === date ? null : date));
    };

    return (
        <div>
            <div className={`${styles.container} col mt-50 gap-30 col-24`}>
                <span className={'h2-text'}>Notifications: </span>

                {/* Sort date keys in reverse order */}
                {Object.entries(groupedNotifications)
                    .sort((a, b) => b[0].localeCompare(a[0]))
                    .map(([date, messages]: any) => (
                        <div key={date} className={'col gap-20 col-24'}>
                            <div
                                className={'row align-items-center justify-content-start pointer gap-20'}
                                onClick={() => toggleExpand(date)}
                            >
                                {expandedDate === date && (
                                    <Unfolded />
                                )}
                                {expandedDate !== date && (
                                    <Folded />
                                )}
                                <span className={'text-14 pointer'} style={expandedDate === date ? { color: '#fff' } : { color: '#858e9c' }}>{date} ({messages.length})</span>
                            </div>
                            {expandedDate === date && (
                                <div className={'col align-items-center justify-content-center gap-20'}>
                                    {messages
                                        .sort((a: any, b: any) => b.date.localeCompare(a.date)) // Sort messages by date in descending order
                                        .map((notification: any) => (
                                            <MessageComponent
                                                key={notification._id}
                                                message={notification.message}
                                                read={notification.isRead}
                                            />
                                        ))}
                                </div>
                            )}
                        </div>
                    ))}

            </div>
        </div>
    );
};

export default NotificationsComponent;
export {};
