import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styles from "../assets/scss/VerifyEmai.module.scss";
import PopupComponent from "../components/common/Popup/Popup.component";

const VerifyEmailPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const navigation = useNavigate()
    function verifyEmailWithToken(token: string) {
    }

    useEffect(() => {
        // Do something with the token (e.g., verify the email)
        if (token) {
            // Call a function to verify the email with the token
            verifyEmailWithToken(token);
        }
    }, [token]);
    return (
        <div>
            <PopupComponent title={'Thank you for your registration'} text={'We send you a letter with your login and password to the e-mail you’ve left'} onClose={()=>navigation('/')} />
            <span className={'h2-text'}>{token}</span>
        </div>
    );
};

export default VerifyEmailPage;
export {};
