import React, {useState} from 'react';
import styles from './Draft.module.scss';
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/CloseIconOutline.svg';
import {ReactComponent as InfoIcon} from '../../../assets/images/svg/InfoIcon.svg';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectBuildingById} from "../../../reducers/buildingSlice";

interface DraftComponentProps {
    id?: string,
    popupText?: string,
    onRoomPressHandler?: ((id: string) => void) | undefined;
    interactive?: boolean;
    selected?: string;
    current?: string;
}

const DraftComponent:React.FC<DraftComponentProps> = ({
                            id,
                            popupText = 'Tap on any room to see it’s free time',
                            onRoomPressHandler,
                            interactive = true,
                            selected = '',
                            current= ''
                        }) => {

    const draft = useSelector(selectBuildingById(id))

    const navigate = useNavigate()

    const defaultOnRoomPressHandler = (room: any) => navigate(`/rooms/${room}`);

    // If onRoomPressHandler is not provided, use the default implementation
    const handleRoomPress = onRoomPressHandler || defaultOnRoomPressHandler;

    const [popupOpened, setPopupOpened] = useState<boolean>(true)
    function _handleClosePopup() {
        setPopupOpened(false)
    }
    const [hoveredRoom, setHoveredRoom] = useState<any>(null);

    // Handler for mouseover event
    const handleMouseOver = (roomId: any) => {
        setHoveredRoom(roomId);
        setTimeout(()=>{
            setPopupOpened(false)
        },2000)
    };

    // Handler for mouseout event
    const handleMouseOut = () => {
        setHoveredRoom(null);
    };

    function getRoomColor(id: string) {
        // selected && selected === room.id ? '#76CCCCaa' : current === room.id ? '#10876588' : 'transparent'
        if (selected && selected === id) {
            return '#76CCCC80'
        }
        if (current && interactive && current === id) {
            return '#D83E5980'
        }
        if (selected && !current && selected !== id) {
            return 'transparent'
        }
        return '#76CCCC80'

    }
    return (
        <div className={`relative ${styles['draft-image']}`}>
            <img className={styles['draft-image']} src={`https://gscoworking.com/${draft.draft}`} />
            {popupOpened && popupText && (
                <>
                    <CloseIcon className={`${styles['close-icon']} pointer`} onClick={_handleClosePopup}/>
                    <div className={styles.popup} style={{zIndex: 111}}>
                        <span className={'text-black main-text text-align-center'}>{popupText}</span>
                    </div>
                </>
            )}
            {!popupOpened && popupText &&(
                <InfoIcon className={`${styles['close-icon']} pointer`} onClick={()=>setPopupOpened(true)}/>
            )}
            {draft.rooms.map((room: any)=>(
                <div
                    key={`${draft.id}-room-${room.id}`}
                    onMouseOver={() => {
                        if (interactive) {
                            handleMouseOver(room.id)
                        }
                    }}
                    onMouseOut={handleMouseOut}
                    onClick={()=> {
                        if (interactive) {
                            handleRoomPress(room.id)
                        }
                    }}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: `${room.coordsOnDraft.top}px`, // Replace with the actual coordinates of the room
                        left: `${room.coordsOnDraft.left}px`, // Replace with the actual coordinates of the room
                        width: `${room.coordsOnDraft.width}px`, // Replace with the actual width of the room
                        height: `${room.coordsOnDraft.height}px`, // Replace with the actual height of the room
                        border: hoveredRoom === room.id ? '2px solid #D53B5C' : 'none',
                        background: getRoomColor(room.id),
                    }}
                >
                    {/* Content of room 1 */}
                </div>
            ))}
        </div>
    );
};

export default DraftComponent;
export {};
