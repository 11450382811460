import React, { useEffect, useState } from 'react';
import {ReactComponent as Icon} from '../../assets/images/svg/Guitar.svg';

interface DynamicIconProps {
    iconName: string;
    width?: number | string;
    height?: number | string;
}

const DynamicIcon: React.FC<DynamicIconProps> = ({ iconName, height= 40 }) => {
    const [IconComponent, setIconComponent] = useState<React.ComponentType<any> | null>(null);

    // useEffect(() => {
    //     let isMounted = true;
    //
    //     async function getIcon() {
    //         try {
    //             const svgModule = await import(`../../assets/images/svg/Guitar.svg`);
    //             const Icon = svgModule.ReactComponent;
    //
    //             if (isMounted) {
    //                 setIconComponent(Icon);
    //             }
    //         } catch (error) {
    //             console.error(`Error loading ${iconName} icon:`, error);
    //         }
    //     }
    //
    //     getIcon();
    //
    //     return () => {
    //         isMounted = false;
    //     };
    // }, [iconName]);

    // if (!IconComponent) return null;

    return <img src={iconName} style={{height}} />;
};

export default DynamicIcon
export {};
