import React, {useRef, useState} from 'react';
import styles from './PopularSpots.module.scss'
import SpotCardComponent from "../../common/SpotCard/SpotCard.component";
import {ReactComponent as RightArrow} from "../../../assets/images/svg/ArrowRightRounded.svg";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {useNavigate} from "react-router-dom";

const PopularSpotsComponent = () => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const maxCount = 3;
    const spots = useSelector((state: RootState) =>
        state.buildings.buildings.flatMap((building) => building.rooms)
    );    const navigation = useNavigate()
    const containerRef = useRef<any>(null);

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % spots.length);
        const container = containerRef.current;
        if (container) {
            container.scrollLeft += container.offsetWidth; // Scroll the container to the next set of cards
        }
    };


    const visibleSpots = spots.slice(currentIndex, currentIndex + maxCount);

    // Fill the remaining spots with the first cards to always have 3 cards displayed
    while (visibleSpots.length < maxCount) {
        visibleSpots.unshift(spots[visibleSpots.length]);
    }

    return (
        <div className={styles.container}>
            <p className={'h2-text'}>All spots</p>
            <div className={'row align-items-center justify-content-between'}>
                <span>Choose the room</span>
                <span className={'pointer blue-text'} onClick={()=>navigation('/spots')}>See all spots</span>
            </div>
            {spots && (
                <div className={`row align-items-center my-30 ${styles['card-container']}`} ref={containerRef}>
                    {visibleSpots.map((item) => (
                        <SpotCardComponent key={item?.id} title={item?.title} image={item?.image} id={item?.id} />
                    ))}
                    {spots.length > maxCount && (
                        <RightArrow className={styles['right-arrow']} onClick={handleNextClick} />
                    )}
                </div>
            )}
        </div>
    );
};

export default PopularSpotsComponent;
export {};
