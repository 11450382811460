import React from 'react';
import styles from './GDPR.module.scss'

const GdprComponent = (props: {accept: any, deny: any}) => {
    const _handleAcceptClick = () => {
      props.accept()
    }
    return (
        <div className={`${styles.container} col`}>
            <p className={styles['inner-wrapper']}>This website uses cookies</p>
            <p className={styles['inner-wrapper']}>
                We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</p>
            <div className={`row justify-content-start ${styles['inner-wrapper']} mt-25`}>
                <div className={'black-button row align-items-center justify-content-center'} onClick={props.deny}>
                    <span>Deny</span>
                </div>
                <div className={'red-button row justify-content-center align-items-center ml-25'} onClick={_handleAcceptClick}>
                    <span className={'red-button-text'}>Allow and close</span>
                </div>
            </div>
        </div>
    );
};

export default GdprComponent;
export {};
