import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAllReviewsByBuildingId, selectBuildingById} from "../../../reducers/buildingSlice";
import ImageSliderComponent from "../../../components/common/ImageSlider/ImageSlider.component";
import PageTitleComponent from "../../../components/common/PageTitle/PageTitle.component";
import MapComponent from "../../../components/common/Map/Map.component";
import DraftComponent from "../../../components/common/Draft/Draft.component";
import {ReactComponent as RightArrow} from "../../../assets/images/svg/RightArrow.svg";
import {ReactComponent as RatingStar} from '../../../assets/images/svg/RedStar.svg';
import {ReactComponent as Folded} from '../../../assets/images/svg/FoldedRoomIcon.svg';
import {ReactComponent as Unfolded} from '../../../assets/images/svg/UnfoldedRoomIcon.svg';
import styles from '../../../assets/scss/BuildingPage.module.scss'
import BreadcrumbsComponent from "../../../components/common/Breadcrumbs/Breadcrumbs.component";
import DynamicIcon from "../../../components/common/DynamicIcon.component";
import TopBar from "../TopBar/TopBar";
import {ReactComponent as NewBadge} from "../../../assets/images/svg/NewBage.svg";

const ReviewItem = (props: {review: any}) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < Math.round(props.review.rating); i++) {
            stars.push(<RatingStar key={i} />);
        }
        return stars;
    };
  return (
      <div className={'col gap-10 mb-30 col-24  pa-0 pl-10'} style={{boxSizing: 'border-box'}}>
          <div className={'row align-items-center justify-content-between'}>
              <span className={'menu-name-chosen'}>{`${props.review.author.name} ${props.review.author.lastName}`}</span>
              <div className={'row align-items-center gap-20'}>
                  <span>{props.review.date}</span>
                  <div className={'row align-items-center'}>
                      {renderStars()}
                  </div>
              </div>

          </div>
          <span className={'main-text'}>{props.review.review}</span>
      </div>
  )
}

const IncludesSection = (props: {includes: any[]}) => {

  return (
      <div className={'col gap-20 mt-20 col-20'}>
          {props.includes.map((item: any)=>(
                  <div className={`row align-items-center gap-20 `}>
                      <DynamicIcon iconName={item.icon} />
                      <span>{item.name}</span>
                  </div>
          ))}
      </div>

  )
}

const BuildingPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigation = useNavigate()
    const building = useSelector(selectBuildingById(id))
    const [moreReviews, setMoreReviews] = useState<number>(3)
    const [selectedRoom, setSelectedRoom] = useState<string>('')
    const reviews = useSelector(getAllReviewsByBuildingId(id))
    const [displayedReviews, setDisplayedReviews] = useState<any>([])
    const [crumbs, setCrumbs] = useState<any[]>([
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Studios',
            link: '/spots'
        }
    ])
    function handleRoomSelect(id: string) {
        setSelectedRoom(id)
    }


    function increaseReviewsCount() {
        setMoreReviews(old=>old+3)
    }

   useEffect(()=>{
       if (moreReviews + 3 > reviews.length) {
           setDisplayedReviews(reviews.splice(0, reviews.length-1))
       } else {
           setDisplayedReviews(reviews.splice(0, moreReviews-1))
       }
   }, [moreReviews])

   useEffect(()=>{
       if (building) {
           if (building.seo && building.seo.title && building.seo.description) {
               document.title = building.seo.title; // Устанавливаем заголовок страницы
               const metaDescription = document.querySelector('meta[name="description"]');
               if (metaDescription) {
                   metaDescription.setAttribute('content', building.seo.description); // Устанавливаем описание страницы
               }
           }
           setCrumbs(old=>([ {
               text: 'Main',
               link: '/'
           },
               {
                   text: 'Studios',
                   link: '/spots'
               }, {
               text: building.address,
               link: `/building/${id}`
           }]))
       }
       return ()=> {
           document.title = 'GoSound: the first music coworking space in Cyprus'; // Устанавливаем заголовок страницы
           const metaDescription = document.querySelector('meta[name="description"]');
           if (metaDescription) {
               metaDescription.setAttribute('content', 'GoSound is a music spaces rental service with simple convenient booking system. Our studios are perfect for musicians, vocalists, DJs and music teachers.'); // Устанавливаем описание страницы
           }
       }
   }, [])

    return (
       <>
           {!building && (
               <TopBar backLink={'/spots'} />
           )}
           {building && (
               <>
                   <TopBar backLink={'/spots'} MiddlePart={building?.address} />
                   <div className={'wrapper col gap-30'}>
                       <ImageSliderComponent images={building?.images} width={'100vw'} height={510}/>
                       <div className={'col gap-30 pa-0'} >
                           <div className={'col-24 px-16'} style={{boxSizing: 'border-box'}}>
                               <span className={'main-text'}>{building?.description} </span>
                           </div>
                           <div className={'col-24'}>
                               <MapComponent width={'98vw'} height={160} openPopup={false}/>
                           </div>
                       </div>
                       <div className={'relative col-24'}>
                           <div className={'col col-24 pa-0 ml-0'} style={{transform: `scale(0.64)`, position: 'absolute', top: -20, left: '-55px'}}>
                               <DraftComponent popupText={'Tap on any room to see\n' +
                                   'it’s description'} id={id} onRoomPressHandler={(id:string)=>handleRoomSelect(id)} current={selectedRoom}/>
                           </div>
                       </div>

                       <div className={'row'} style={{marginTop: 250}}>
                           <div className={'col col-24'}>
                               {building.rooms.map((room: any)=>(
                                   <>
                                       <div className={styles.seaDivider} />
                                       <div className={'col'}>
                                           <div className={'row align-items-center gap-10 align-items-center justify-content-between'}>
                                               <div className={'row wrap align-items-center gap-10'} onClick={() => selectedRoom === room._id ? setSelectedRoom('') : setSelectedRoom(room._id)}>
                                                   {selectedRoom === room._id ? (
                                                       <Unfolded className={'pointer'} />
                                                   ) : (
                                                       <Folded className={'pointer'} />
                                                   )}
                                                   <span className={`h3-text ${selectedRoom === room._id ? 'sea-text' : 'sea-text'}`}>{room.title}</span>
                                                   <span className={'spot-subtitle text-white'}>{room.subtitle}</span>
                                                   {room.rating ? (
                                                       <div className="row align-items-center">
                                                           <RatingStar />
                                                           <span>{room.rating}</span>
                                                       </div>
                                                   ) : (
                                                       <NewBadge />
                                                   )}
                                               </div>

                                               <RightArrow className={'ml-10 pointer'} onClick={()=>navigation(`/rooms/${room.id}`)}/>
                                           </div>
                                           {selectedRoom===room.id && (
                                               <div className={'col ml-20'}>
                                                   <span className={'spot-subtitle'}>Include:</span>
                                                   <IncludesSection includes={room.includes} />
                                               </div>
                                           )}
                                       </div>
                                   </>
                               ))}
                               <div className={styles.seaDivider} />

                           </div>
                       </div>

                       <div className={'col-22 col px-10'} style={{boxSizing: 'border-box'}}>
                           <span className={'spot-title mb-20'}>Reviews: </span>
                           {reviews.splice(0, moreReviews).map((r: any)=>(
                               <ReviewItem review={r} />
                           ))}
                           <span className={'pointer blue-text'} onClick={increaseReviewsCount}>More reviews</span>
                       </div>
                   </div>
               </>
           )}
       </>
    );
};

export default BuildingPage;
export {};
