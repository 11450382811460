import React, {useEffect, useState} from 'react';
import styles from "./Popup.module.scss";
import axios from "axios";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconWhite.svg";
import {clearBooking} from "../../../reducers/bookingSlice";

const ForgotPasswordPopup = (props: {onClose: any, token: string, success: any, error: any}) => {
    const [password, setPassword] = useState<string>('')
    const [passwordCopy, setPasswordCopy] = useState<string>('')
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    function handleForgot() {
        if (password !== passwordCopy || password.length < 6) {
            console.error('Please enter a valid passwords.');
            return;
        }

        const apiUrl = 'https://gscoworking.com/api/user/recover';

        axios.post(apiUrl, {token: props.token, password})
            .then((response) => {
                props.success()
                props.onClose()
                // You can perform any additional actions after placing the order here
            })
            .catch((error) => {
                props.error()
                props.onClose()
                console.error('Error placing the order:', error);
            });
    }

    useEffect(()=>{
        document.body.classList.add('popup-open');
        return ()=>document.body.classList.remove('popup-open');
    }, [])


    return (
        <div className={styles['popup-overlay']} style={{zIndex: 999}}>
            <div className={`pointer relative ${styles['popup-container']}`}>
                <div className={styles['popup-header']}>
                    <span className={'text-price text-align-center col-24'}>Set new password</span>
                </div>
                <CloseIcon className={styles['close-icon']} onClick={props.onClose} />
                <div className={`col gap-20 ${styles['popup-content']}`}>
                    <div className={'booking-input-wrapper col'} style={{width: '90%', border: '1px solid #D3D3D3'
                    }}>
                        <span>New password</span>
                        <div className={'row align-items-center'}>
                            <input
                                type={'password'}
                                className={'booking-input col-24'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                    </div>
                    <div className={'booking-input-wrapper col'} style={{width: '90%', border: '1px solid #D3D3D3'
                    }}>
                        <span>Repeat password </span>
                        <div className={'row align-items-center'}>
                            <input
                                type={'password'}
                                className={'booking-input col-24'}
                                value={passwordCopy}
                                onChange={(e) => setPasswordCopy(e.target.value)}
                            />
                        </div>

                    </div>
                </div>
                <div className={styles['popup-footer'] }>
                    <div className={'red-button row center-content mb-10 pointer'} style={{width: '100%'}} onClick={handleForgot}>
                        <span className={'red-button-text'}>Confirm</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPopup;
export {};
