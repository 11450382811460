import React, {useEffect, useState} from 'react';
import styles from './AboutUs.module.scss'
import {ReactComponent as Card1} from "../../../assets/images/svg/card1.svg";
import {ReactComponent as Card2} from "../../../assets/images/svg/card2.svg";
import {ReactComponent as Card3} from "../../../assets/images/svg/card3.svg";
import {ReactComponent as Card4} from "../../../assets/images/svg/card4.svg";
import EventsComponent from "../../common/Events/events.component";
import axios from "axios";

const AboutUsComponent = () => {
    const [events, setEvents] = useState<any[]>([])
    useEffect(()=>{
        axios.get('https://gscoworking.com/api/events/get-all').then((answer: any)=>{
            setEvents(answer.data)
        }).catch((e:any)=>{
            console.log(e.message)
        })
    }, [])
    return (
        <>
            <div className={'row my-70 gap-30 justify-content-center'}>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card1  />
                    </div>
                    <div className={'col align-items-center'}>
                        <span className={'text-16 bold'}>Booking</span>
                        <p className={'text-14'}>Unique and user-friendly system for booking allows you to choose any time you wish
                        </p>
                    </div>                </div>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card2  />
                    </div>
                    <div className={'col align-items-center' } style={{maxHeight: '40%'}}>
                       <span className={'text-16 bold'}>Equipment</span>
                       <p className={'text-14'}>In our spaces we have all up to date music and DJ equipment for vocalists, drummers, bands and teachers
                       </p>
                   </div>
                </div>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card3  />
                    </div>
                    <div className={'col align-items-center'}>
                        <span className={'text-16 bold'}>Locks</span>
                        <p className={'text-14'}>For your safety and and comfort we have digital locks in each room, so no one disturbs you during your time
                        </p>
                    </div>
                </div>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card4  />
                    </div>
                    <div className={'col align-items-center'}>
                        <span className={'text-16 bold'}>Cozy</span>
                        <p className={'text-14'}> Our space made for your convenience and you find there equipped kitchen, lecture hall and places for relaxing
                        </p>
                    </div>
                </div>
            </div>

            <EventsComponent  events={events}/>


            <div className={`${styles.container} row`}>
                <div className={'col-12 col mr-20'}>
                    <span className={'h2-text'}>We are</span>
                    <p className={'main-text'}>GoSound is a space for creation of your any ideas. Our space differs from usual coworkings in that here you have the opportunity to make music and also gather like-minded people in the lecture hall and in an amazing way to get all these in one perfect place. We are sure that the collaboration of artists and other creative people is as effective as possible in such personal format. GoSound brings together those who are willing to know about the latest modern art trends.
                    </p>

                    <p className={'main-text'}>Our studios are perfect for musicians, vocalists, DJs and actors looking for a space to practise their craft.</p>
                </div>
                <div className={'col-11'}>
                    <img src={require('../../../assets/images/weare2.png')} height={270} className={'col-24'} style={{objectFit: 'cover'}}/>
                </div>
            </div>
        </>
    );
};

export default AboutUsComponent;
export {};
