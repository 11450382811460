import React from 'react';
import RoomCardComponent from "./RoomCard/RoomCard.component";
import {ReactComponent as RightArrow} from '../../../assets/images/svg/RightArrow.svg';
import {Link} from "react-router-dom";

const AllSpotsBuildingSectionComponent = (props: {rooms: any, title: any, buildingId: string}) => {
    return (
        <div>
            <div className={'row align-items-center'}>
                <Link to={`/building/${props.buildingId}`} className={'text-price text-white'} style={{textDecoration:'none'}}>{props.title}</Link>
                <RightArrow className={'ml-10 pointer'} />
            </div>
            <div className={'row mt-30 gap-25 wrap'}>
                {props.rooms.map((room: any)=>(
                    <RoomCardComponent room={room} />
                ))}
            </div>
        </div>
    );
};

export default AllSpotsBuildingSectionComponent;
export {};
