import React from 'react';
import styles from './Popup.module.scss';
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/CloseIconWhite.svg';

interface UniversalPopupProps {
    title: string;
    text: string;
    buttonText1?: string;
    buttonText2?: string;
    onClose: () => void;
    onButton1Click?: () => void;
    onButton2Click?: () => void;
}

const UniversalPopup: React.FC<UniversalPopupProps> = ({
                                                           title,
                                                           text,
                                                           buttonText1,
                                                           buttonText2,
                                                           onClose,
                                                           onButton1Click,
                                                           onButton2Click,
                                                       }) => {
    return (
        <div className={styles['popup-overlay']}>
            <div className={`pointer relative ${styles['popup-container']}`}>
                <div className={styles['popup-header']}>
                    <span className={'text-price text-align-center col-24'}>{title}</span>
                </div>
                <CloseIcon className={styles['close-icon']} onClick={onClose} />
                <div className={styles['popup-content']}>
                    <p>{text}</p>
                </div>
                <div className={styles['popup-footer'] }>
                    {buttonText1 && (
                        <div className={'magenta-text  text-16'} style={{fontWeight: 700}} onClick={onButton1Click}>
                            {buttonText1}
                        </div>
                    )}
                    {buttonText2 && (
                        <div className={styles.buttonOne} onClick={onButton2Click}>
                            {buttonText2}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UniversalPopup;
export {};
