import React from 'react';

const AllRoomsPage = () => {
    return (
        <div>

        </div>
    );
};

export default AllRoomsPage;
export {};
