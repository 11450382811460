import React, {useState} from 'react';
import {ReactComponent as Minus} from "../../../assets/images/svg/TimeMinus.svg";
import {ReactComponent as Plus} from "../../../assets/images/svg/TimePlus.svg";
import styles from './TimeSelect.module.scss'
import stylesDark from './CalendarDark.module.scss'

const TimeSelectComponent = (props: {date: string, skip:any,  ok:any}) => {
    const [amPm, setAmPm] = useState<string>('am')
    const [time, setTime] = useState(1);

    const handleMinusClick = () => {
        if (time > 0) {
            setTime(time - 1);
        } else {
            setTime(12);
        }
    };

    const handlePlusClick = () => {
        if (time < 12) {
            setTime(time + 1);
        } else {
            setTime(0);
        }
    };


    const handleInputChange = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            setTime(parseInt(inputValue, 10));
        }
    };


    return (
        <div className={` ${styles['container']} col`}>
        <span className={'text-price text-black mt-60 text-align-center'}>{props.date}</span>
        <span className={'breadcrumbs-text mt-24 mb-10 ml-37 text-black'}>Enter hour</span>
        <div className={'row align-items-center gap-10 mb-33 ml-37'}>
            <div className={`row align-items-center ${styles['input-wrapper']}`}>
                <Minus className={'pointer'} onClick={handleMinusClick}/>
                <input className={`${styles.time}`}  value={time.toString().padStart(2, '0')}
                       onChange={handleInputChange} />
                <Plus className={'pointer'} onClick={handlePlusClick}/>
            </div>
            <div className={`col ${styles['ampm-wrapper']}`}>
                <div className={`pointer  ${amPm === 'am' ? styles['selected-wrapper'] : styles['not-selected-wrapper']}`} onClick={()=>setAmPm('am')}>AM</div>
                <div className={`pointer  ${amPm === 'pm' ? styles['selected-wrapper'] : styles['not-selected-wrapper']}`} onClick={()=>setAmPm('pm')}>PM</div>
            </div>
        </div>
        <div style={{height:1,  width: '100%', background: '#D3D3D3'}}>

        </div>
        <div className={'row gap-8 justify-content-end align-items-center mt-10'}>
            <span className={'pa-12 magenta-text pointer bold'} onClick={props.skip}>Skip</span>
            <span className={'pa-12 magenta-text pointer bold'} onClick={()=>props.ok({time, amPm})}>Ok</span>
        </div>
        </div>
    );
};

export default TimeSelectComponent;
export {};
