import React from 'react';
import styles from './RoomCard.module.scss'
import InCardImageSliderComponent from "./InCardImageSlider.component";
import {ReactComponent as MusicIcon} from '../../../../assets/images/svg/MusicIconWhite.svg';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../reducers/types";
import {addToFavorites, removeFromFavorites} from "../../../../reducers/authSlice";


const RoomCardComponent = (props: {room: any, width?: any, height?: any, handlePress: any}) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.auth.userFull)
    const isFavorite = user?.favoriteRooms?.findIndex((room: { _id: any; }) => room._id === props.room._id) !== -1 || false;

    function getIncludesNames() {
        return props.room.includes.map((include: { name: any; quantity: any})=>{
            return include.quantity && include.quantity > 1 ? `2 x ${include.name}` : include.name
        }).join(', ')
    }


    return (
        <div className={` ${styles.card} col`} style={{width: props.width, height: props.height}}>
            <InCardImageSliderComponent images={props.room.images} />
            <div className={`row align-items-center justify-content-between mt-25 `}>
                <span className={`${styles.spotsName}`}>{props.room.name}</span>
            </div>
            <div className={`row gap-15 align-items-start mt-25`}>
                <MusicIcon />
                <span className={`text-white main-text`}>{props.room.skills.join(', ')}</span>
            </div>
            <span className={'mt-10 mb-1 text-white main-text'} dangerouslySetInnerHTML={{__html: props.room.description}}/>

            {/*<div className={`row mt-25 gap-5`}>*/}
            {/*        <span className={`text-price magenta-text`}>{Math.floor(props.room.price)}€/h</span>*/}
            {/*</div>*/}
            {/*<div className={'red-button row center-content mt-15 pointer'} style={{width: '100%'}} onClick={()=>props.handlePress(props.room)}>*/}
            {/*    <span className={'red-button-text'}>Contact</span>*/}
            {/*</div>*/}
        </div>
    );
};

export default RoomCardComponent;
export {};
