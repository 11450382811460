import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../../assets/images/svg/Logo.svg';
import {ReactComponent as Bell} from '../../../assets/images/svg/BellIcon.svg';
import {ReactComponent as User} from '../../../assets/images/svg/UserNotLoggedIcon.svg';
import {Link, useNavigate} from "react-router-dom";
import styles from './TopBar.module.scss'
import {useDispatch, useSelector} from "react-redux";
import { RootState } from '../../../reducers/types';
import LoginRegisterComponent from "../LoginRegister/LoginRegister.component";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice"; // Replace 'path/to/reducers/types' with the correct path to your types file


const TopBarComponent = () => {
    const navigation = useNavigate()
    const usr = useSelector((state:RootState)=>state.auth.user)
    const user = useSelector((state:RootState)=>state.auth.userFull)
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state: RootState) => state.auth);
    const [userLog, setUserLog] = useState<any>(null)
    const [loginRegisterMode, setLoginRegisterMode] = useState<any>(null)
    const [loggedUser, setLoggedUser] = useState<any>(null)
    const [notReadCount, setNotReadCount] = useState(0)
    const handleUserClick = () => {
        setLoginRegisterMode('login')
        if (isLoggedIn) {
            setUserLog('yezzzz')
        } else {
            setUserLog('nooooo')
        }
    };

    function closeLoginRegister() {
        setLoginRegisterMode(null)
    }

    function loginSuccess (user: any) {
        setLoggedUser(user)
    }

    function handleChangeMode(mode: string) {
        setLoginRegisterMode(mode)
    }
    useEffect(() => {
        // @ts-ignore
        setLoggedUser(old=>usr)
    }, []);

    useEffect(()=>{
        if (user && user.notifications) {
            const unreadNotifications = user.notifications.filter(
                (notification: { isRead: any }) => !notification.isRead
            );
            setNotReadCount(unreadNotifications.length)
        }
    }, [user])

    // @ts-ignore
    return (
        <div className={`row justify-content-between align-items-center ${styles.container}`} >
            <Logo className={'pointer'} onClick={()=>navigation('/')} />
            <nav className={'row align-items-center mt-15'}>
                <ul className={'row gap-40'}>
                    <li className={styles['nav-item']}>
                        <Link to="/spots">All spots</Link>
                    </li>
                    <li className={styles['nav-item']}>
                        <Link to="/about-us">About Us</Link>
                        {/*ijrig*/}
                    </li>
                    <li className={styles['nav-item']}>
                        <Link to="/partners">Partners</Link>
                    </li>
                    <li className={styles['nav-item']}>
                        <Link to="/how-it-works">How it works</Link>
                    </li>

                    <li className={styles['nav-item']}>
                        <Link to="/contacts">Contacts</Link>
                    </li>
                    <li className={styles['nav-item']}>
                        <Link to="/buy-certificate">Certificates & Deposits</Link>
                    </li>
                </ul>
            </nav>
            <div className={'row align-items-center justify-content-between '}>
                <div className={'red-button row center-content pointer mr-20'} style={{width: 220}} onClick={()=>navigation(`/spots`)}>
                    <span className={'red-button-text'}>Choose room</span>
                </div>
                <div className={'relative'} onClick={()=>{
                    if (user && user._id) {
                        navigation('/private?tab=3')
                    }
                }}>
                    {notReadCount > 0 && (
                        <div style={{width: 16, height: 16, background: '#D53B5C', borderRadius: 8, position: 'absolute', right: 10, top: -8}} className={'row align-items-center justify-content-center'}>
                            <span className={'text-10'}>{notReadCount}</span>
                        </div>
                    )}
                    <Bell className={'pointer mr-20'} />
                </div>
                {loggedUser && user && user.deposit && user.fa2 && (
                    <span className={`mx-5 ${styles.balance}`}>{user.deposit.totalAmount} €</span>
                )}
                {(!loggedUser || !loggedUser._id) && (
                    <>
                        <User className={'pointer'} onClick={handleUserClick}/>
                    </>
                )}
                {loggedUser && loggedUser._id && (
                    <>
                        <div className={styles.avatar} onClick={()=>navigation('/private')}>{loggedUser.name[0]}</div>
                    </>
                )}
            </div>
            {loginRegisterMode && (
                <LoginRegisterComponent mode={loginRegisterMode} changeMode={handleChangeMode} loginSuccess={loginSuccess} onClose={closeLoginRegister}/>
            )}
        </div>
    );
};

export default TopBarComponent;
export {};
