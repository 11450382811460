import React from 'react';
import styles from './Breadcrumbs.module.scss'
import {Link} from "react-router-dom";

const BreadcrumbsComponent = (props: {crumbs: any[]}) => {
    return (
        <div className={'row'}>
            {props.crumbs.map((item, idx)=>(
                <>
                    <Link className={styles['breadcrumbs-text']} to={item.link}>{item.text}</Link>
                    {idx<props.crumbs.length-1 && (
                        <span className={`${styles['breadcrumbs-text']} mx-6`}>/</span>
                    )}
                </>
            ))}
        </div>
    );
};

export default BreadcrumbsComponent;
export {};
