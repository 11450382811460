// src/reducers/rootReducer.ts

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import RoomsSlice, {selectRoomById} from './roomsSlice';
import BuildingSlice, {BuildingState} from "./buildingSlice";
import BookingSlice from "./bookingSlice";
import OrderSlice from "./orderSlice";
// Import other reducers here

const rootReducer = combineReducers({
    auth: authReducer,
    rooms: RoomsSlice,
    buildings: BuildingSlice,
    booking: BookingSlice,
    orders: OrderSlice
    // Add other reducers here
});

export default rootReducer;

// Define RootState type for the entire application state
export type RootState = ReturnType<typeof rootReducer>;
