import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {useLocation, useNavigate} from "react-router-dom";
import PrivateMenuComponent from "../../../components/desktop/Private/PrivateMenu.component";
import {checkLocalStorageForLoggedInStatus, logout} from "../../../reducers/authSlice";
import PrivateInfoWindowComponent from "../../../components/desktop/Private/PrivateInfoWindow.component";
import TopBar from "../TopBar/TopBar";

const PrivatePage = () => {
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const navigation = useNavigate()
    const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0)
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramValue:any = searchParams.get('tab');
    function handleLogout () {
        dispatch(logout)
        try {
            localStorage.removeItem('user')
            localStorage.removeItem('logged')
        } catch (e: any) {
            console.log(e.message)
        }
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        window.location.href = '/'
    }

    function selectMenuItem(idx: number) {
        if (idx===0) {
            navigation('/private/account')
        }
        if (idx===1) {
            navigation('/private/funds')
        }
        if (idx===3) {
            navigation('/private/bookings')
        }
        if (idx===2) {
            navigation('/private/favorites')
        }
        if (idx===4) {
            navigation('/private/messages')
        }
        if (idx===5) {
            navigation('/private/reviews')
        }
        if (idx===6) {
            navigation('/private/settings')
        }
    }

    useEffect(()=>{
        if (!user || !user._id) {
            navigation('/')
        }
        if (parseInt(paramValue) > 0) {
            selectMenuItem(parseInt(paramValue))
        }
    }, [])

    return (
       <div className={'pb-100'}>
           <TopBar backLink={'/'} MiddlePart={`${user?.name} ${user?.lastName ? user?.lastName?.substring(0, 1) : ''}`}  />
           <PrivateMenuComponent  handleMenuItemClick={selectMenuItem}  handleLogout={handleLogout}/>
       </div>
    );
};

export default PrivatePage;
export {};
