import React, {useEffect, useState} from 'react';
import styles from './MyFunds.module.scss'
import {ReactComponent as CopyIcon} from '../../../../assets/images/svg/CopyWhite.svg';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../../../../reducers/authSlice";
import DepositeCardComponent from "./DepositeCard.component";
import PaymentForm from "../../../common/PaymentForm/PaymentForm";
import axios from "axios";
import TopBar from "../../TopBar/TopBar";
import LoaderComponent from "../../Loader/Loader.component";
import {useNavigate} from "react-router-dom";

const MyFundsComponent = () => {
    const menuItems = [
        'Add funds',
        'Withdrawal',
        'Cashback',
        'Payment history'
    ]
    const packages = [
        {
            _id: '1',
            amount: 100,
            bonus: 10,
            type: 'percentage'
        },
        {
            _id: '2',
            amount: 300,
            bonus: 15,
            type: 'percentage'
        },
        {
            _id: '3',
            amount: 500,
            bonus: 20,
            type: 'percentage'
        },
        {
            _id: '4',
            amount: 1000,
            bonus: 20,
            type: 'percentage'
        }
    ]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);
    const [activeMenuItem, setActiveMenuItem] = useState<number>(0);
    //const [userObject, setUserObject] = useState<any>(null)
    const [amount, setAmount] = useState<number>(50)
    const [selectedPackage, setSelectedPackage] = useState<string>('1')
    const [pageMode, setPageMode] = useState<string>('display')
    const [useLoader, setUseLoader] = useState(true)
    const [loader, setLoader] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
    const [referalStats, setReferalStats] = useState<any>(null)
    const [referalOrders, setReferalOrders] = useState<any[]>([])
    const [deposites, setDeposites] = useState<any[]>([])
    const [referal, setReferal] = useState<any>(null)


    async function copyToClipboard(code: string) {
        try {
            await navigator.clipboard.writeText(code);
        } catch (error) {
            console.error('Не удалось скопировать текст: ', error);
        }
    }

    function handleDepositCardPress(deposit: any) {
        setSelectedPackage(deposit._id)
        setAmount(deposit.amount)
    }

    const calculateBonusAmount = () => {
        const sortedPackages = [...packages].sort((a, b) => a.amount - b.amount);
        for (let i = 0; i < sortedPackages.length; i++) {
            if (i === sortedPackages.length - 1 || amount < sortedPackages[i + 1].amount) {
                if (amount >= sortedPackages[i].amount) {
                    const bonusPercentage = sortedPackages[i].bonus;
                    return amount * bonusPercentage / 100;
                }
            }
        }
        return 0;
    };

    async function handleDepositBuy(paymentIntent: any) {
        setLoader(true)
        setLoading(true)
        setError(null)
        const apiUrl = 'https://gscoworking.com/api/deposit/buy';
        let answer = await axios.post(apiUrl, {
            userId: fromStore._id,
            amount: amount,
            tax: paymentIntent?.taxCalculation,
            paymentIntentId: paymentIntent.id
        }).catch((e:any)=>{
            setUseLoader(false)
            setLoading(false)
            setLoader(false)
            setError(error)
        })
        if (answer && answer.data) {
            setLoader(false)
            setLoading(false)
            setError(null)
            setUseLoader(false)
            navigate('/private/account')
        }
    }

    const formatDate = (dateString: string) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        // @ts-ignore
        return new Date(dateString).toLocaleDateString('ru-RU', options);
    };

    const formatDateHHmm = (dateString: string) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        // @ts-ignore
        return new Date(dateString).toLocaleString('ru-RU', options);
    };

    const Succeeded = () => (
        <span className={'sea-text text-14'}>Succeeded</span>
    )

    const Failed = () => (
        <span className={'magenta-text text-14'}>Succeeded</span>
    )

    useEffect(()=>{
        if (fromStore && fromStore.referalCode) {
            axios.get('https://gscoworking.com/api/promo-codes/get-code/' + fromStore.referalCode).then((r: any) => {
                setReferal(r.data)
            })
            axios.get(`https://gscoworking.com/api/referal-stat/${fromStore.referalCode}`).then((resp: any)=>{
                setReferalStats(resp.data)
                axios.get(`https://gscoworking.com/api/referal-orders/${fromStore.referalCode}`).then((orders: any)=>{
                    setReferalOrders(orders.data)
                })
            }).catch((e:any)=>{
                console.log(e.message)
            })
        }
        if (fromStore && fromStore._id) {
            axios.get(`https://gscoworking.com/api/deposit/user/${fromStore._id}`).then((deposites: any)=>{
                setDeposites(deposites.data.deposits)
            })

        }
    }, [])

    // useEffect(() => {
    //     if (fromStore) {
    //         setUserObject(fromStore);
    //     }
    // }, [fromStore]);

    useEffect(() => {
        // Диспатчим действие для проверки статуса входа в систему и загрузки данных пользователя с сервера
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        // @ts-ignore
    }, [dispatch]);

    useEffect(()=>{
        if (amount===0 && packages && packages.length>0) {
            setAmount((packages.find((p: any) => p._id === selectedPackage)?.amount) ?? 0);
        }
    }, [])

    return (
        <>
            <TopBar backLink={'/private'} MiddlePart={`My Funds`}  />

            {pageMode === 'display' && (
                <div className={`col pt-10 ${styles.container} pb-100`}>
                    {fromStore && fromStore.fa2 && (
                        <>
                            <div className={`${styles.menuRow}`}>
                                {menuItems.map((item:string, index: number)=>(
                                    <span className={`pointer spot-title ${styles['menu-item']} ${index===activeMenuItem ? styles.activeMenuItem : styles.inactiveMenuItem}`} onClick={()=>{setActiveMenuItem(index)}}>{item}</span>
                                ))}
                            </div>
                            <div style={{width: '100%', height: 1, background: '#525460'}} />
                            {activeMenuItem === 0 && (
                                <div className={`mt-30 col align-items-between`}>
                                    <div className={`col col-15`}>
                                        <span className={`spot-title`}>Balance:</span>
                                        <span className={`text-price mt-20 sea-text`}>{Math.floor(fromStore.deposit.totalAmount)} €</span>
                                        <span className={`main-text mt-10`}><span className={'bold'}>{referalStats?.sum} €</span> Cashback Earned</span>
                                        <span className={`main-text mt-10`}><span className={'bold'}>0 €</span> Promo Purchases</span>
                                        <span className={`spot-title mt-60`}>My promocode:</span>
                                        <div className={`mt-20 relative ${styles.promo}`}>
                                            <CopyIcon className={`${styles.copyButton}`} onClick={()=>copyToClipboard(fromStore.referalCode)}/>
                                            <span className={`${styles.promocode}`}>{fromStore.referalCode}</span>
                                        </div>
                                        {referal && (
                                            <span className={'mt-5 sea-text'}>Your referral code: {referal.discount.profit.value}% cashback for you, {referal.discount.value}% off for
                                    others</span>
                                        )}
                                        {fromStore && fromStore.discount && (
                                            <div className={styles['discount-container']} style={{backgroundImage: `url(${require('../../../../assets/images/discountBlockBackground.png')})`, backgroundPosition: 'right', backgroundRepeat: 'no-repeat'}}>
                                                <span className={'text-16 sea-text'}>My discount</span>
                                                <span className={'text-38 sea-text bold'}>{fromStore.discount}%</span>
                                            </div>
                                        )}

                                    </div>
                                    <div className={`col  gap-10 mt-30`}>
                                        <span className={`spot-title`}>Buy deposit:</span>
                                        <span>Buy any amount you want </span>
                                        <input className={`${styles.amountInput}`} value={amount} onFocus={()=>{setSelectedPackage('null')}} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setAmount(Number(e.target.value))
                                        }} onBlur={()=>{amount <50 ? setAmount(50) : console.log('')}}/>
                                        {fromStore && fromStore.deposit && fromStore.deposit.totalAmount && (
                                            <span>Balance after replenishment {Number(fromStore.deposit.totalAmount*1 + Number(amount) + calculateBonusAmount()).toFixed(2)} €</span>
                                        )}
                                        <span className={`menu-name-chosen my-10`}>Or buy package:</span>
                                        {packages.map((pack: any)=>(
                                            <DepositeCardComponent  item={pack} currentItem={selectedPackage} handlePress={handleDepositCardPress}/>
                                        ))}
                                        <div className={`mt-20 ${styles['header-button']} red-button pointer row justify-content-center align-items-center`} style={{width: 320, boxShadow: 'none'}} onClick={()=>{
                                            if (amount<1) {
                                                return
                                            }
                                            setPageMode('payment')
                                        }}>
                                            <span className={'red-button-text'}>Buy deposit</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeMenuItem === 2 && (
                                <div className={'px-10 col align-items-start'}>
                                    <span className={`bold text-white ${styles['promo-sea']}`}>Promocode:</span>
                                    <span className={`bold mt-2 sea-text ${styles['promo-sea']}`}>{fromStore.referalCode}</span>
                                    <div className={'row wrap  gap-10 mt-10'}>
                                        <span>Created {formatDate(referalStats?.createdAt)}</span>
                                        <span>Active till {formatDate(referalStats?.expiryDate)}</span>
                                        <span>{referalStats?.discount}% discount</span>
                                        <span>{referalStats?.cashback}% cashback</span>
                                        <span>{referalStats?.q} purchased</span>
                                        <span>Cashback amount {referalStats?.sum} €</span>
                                    </div>
                                    <div className={'my-5'} style={{width: '100%', height: 1, background: '#525460'}} />
                                    {referalOrders.map((order: any,  index: number)=>(
                                        <div className={'col col-24'}>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>User Name & Email</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>{order.userID.name} {order.userID.lastnme}<br/>{order.userID.email}</span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Date & Time</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>{formatDateHHmm(order.date)}</span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Room Name</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>{formatDateHHmm(order.date)}</span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Order Amount</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>{order.roomID.title}</span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Cashback Amount</span>
                                                <span className={'col-12 sea-text bold'} style={{textAlign: 'center'}}>{parseFloat((order.amount*referalStats.cashback/100).toFixed(2))} €</span>
                                            </div>
                                            {index < referalOrders.length-1 && (
                                                <div className={'my-5'} style={{width: '100%', height: 1, background: '#525460'}} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {activeMenuItem === 3 && (
                                <div className={'px-10 col align-items-start'}>
                                    {deposites.map((dep: any,  index: number)=>(
                                        <div className={'col col-24'}>

                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Date & Time</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>{formatDateHHmm(dep.createdAt)}</span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Amount</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>
                                                     {dep.type === 'buy' && (
                                                         <span className={'text-14 sea-text'}>{dep.amount} €</span>
                                                     )}
                                                    {dep.type === 'booking' && (
                                                        <span className={'text-14 magenta-text'}>- {dep.amount} €</span>
                                                    )}
                                                    {dep.type === 'certificate' && (
                                                        <span className={'text-14 magenta-text'}>- {dep.amount} €</span>
                                                    )}
                                                    {dep.type === 'referal' && (
                                                        <span className={'text-14 sea-text'}>{dep.amount} €</span>
                                                    )}
                                                </span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Operation Type</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>
                                                    {dep.type === 'buy' && (
                                                        <span className={'text-14'}>Deposit</span>
                                                    )}
                                                    {dep.type === 'booking' && (
                                                        <span className={'text-14'}>Space payment</span>
                                                    )}
                                                    {dep.type === 'certificate' && (
                                                        <span className={'text-14'}>Buy certificate</span>
                                                    )}
                                                    {dep.type === 'referal' && (
                                                        <span className={'text-14'}>Cashback</span>
                                                    )}
                                                </span>
                                            </div>
                                            <div className={'row mb-20 align-items-center justify-content-between'} style={{height: 50}}>
                                                <span className={'col-12 bold'}>Status</span>
                                                <span className={'col-12'} style={{textAlign: 'center'}}>
                                                     {dep.status === 'approved' && (
                                                         <Succeeded />
                                                     )}
                                                    {dep.status === 'declined' && (
                                                        <Failed />
                                                    )}
                                                </span>
                                            </div>
                                            {index < deposites.length-1 && (
                                                <div className={'my-5'} style={{width: '100%', height: 1, background: '#525460'}} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                    {(!fromStore || !fromStore.fa2) && (
                        <div className={`center-content`}>
                            <span className={`sea-text center-content spot-title bold`}>Please turn on Two factor authorization in account settings and try again</span>
                        </div>
                    )}
                </div>
            )}
            {pageMode==='payment' && (
                <div className={'col mt-30'} style={{overflowY: 'scroll', paddingBottom: 100}}>
                    <div
                        className={'row align-items-center gap-30 text-21 magenta-text bold justify-content-between'}>
                        <span>Subtotal:</span>
                        <div className={'row gap-10 align-items-center'}>
                            {/* Рассчитываем базовую сумму (сумму без НДС) */}
                            <span>{(amount / 1.19).toFixed(2)}€</span>
                        </div>
                    </div>
                    <div
                        className={'row align-items-center gap-30 text-20 magenta-text bold justify-content-between'}>
                        <span className={'text-18'}>VAT 19%</span>
                        <div className={'row gap-10 align-items-center'}>
                            {/* Рассчитываем НДС как 19% от базовой суммы */}
                            <span>{(amount / 1.19 * 0.19).toFixed(2)}€</span>
                        </div>
                    </div>
                    <div
                        className={'row align-items-center gap-30 text-20 magenta-text bold justify-content-between'}>
                        <span className={'text-18'}>Total</span>
                        <div className={'row gap-10 align-items-center'}>
                            {/* Общая сумма уже включает НДС и равна currentDeposit.amount */}
                            <span>{amount.toFixed(2)}€</span>
                        </div>
                    </div>
                    <PaymentForm withDiscount={false} buttonText={'Add Funds'} useLoader={useLoader} user={fromStore}
                                 order={{amount: parseFloat((amount / 1.19).toFixed(2))}} total={{amount: parseFloat((amount / 1.19).toFixed(2)), amountWithDiscount: parseFloat((amount / 1.19).toFixed(2))}}
                                 callback={handleDepositBuy} reference={'Add funds'}/>
                    {loader && (
                        <LoaderComponent text={'Please wait. Your booking is processing'} loading={loading}>
                            <span>{error?.message}</span>
                            <div className={'red-button row justify-content-center align-items-center'} onClick={() => {
                                setLoader(false)
                                setLoading(true)
                                setError(null)
                            }}>Ok
                            </div>
                        </LoaderComponent>
                    )}
                </div>
            )}
        </>
    );
};

export default MyFundsComponent;
