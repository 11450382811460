import React from 'react';
import styles from './RoomCard.module.scss'
import InCardImageSliderComponent from "./InCardImageSlider.component";
import {ReactComponent as RatingStar} from '../../../../assets/images/svg/SeaStar.svg';
import {ReactComponent as MusicIcon} from '../../../../assets/images/svg/MusicIcon.svg';
import {ReactComponent as FavoriteEmpty} from '../../../../assets/images/svg/FavoriteEmpty.svg';
import {ReactComponent as FavoriteFilled} from '../../../../assets/images/svg/FavoriteFilled.svg';
import {ReactComponent as NewBadge} from '../../../../assets/images/svg/NewBage.svg';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../reducers/types";
import {addToFavorites, removeFromFavorites} from "../../../../reducers/authSlice";


const RoomCardComponent = (props: {room: any, width?: any, height?: any}) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.auth.userFull)
    const isFavorite = user?.favoriteRooms?.findIndex((room: { _id: any; }) => room._id === props.room._id) !== -1 || false;

    function getIncludesNames() {
        return props.room.includes.map((include: { name: any; quantity: any})=>{
            return include.quantity && include.quantity > 1 ? `2 x ${include.name}` : include.name
        }).join(', ')
    }

    function handleToggleFavorite() {
        const userId = user._id; // Assuming you have the user's ID

        if (isFavorite) {
            // Remove from favorites
            // @ts-ignore
            dispatch(removeFromFavorites(userId, props.room._id));
        } else {
            // Add to favorites
            // @ts-ignore
            dispatch(addToFavorites(userId, props.room._id));
        }
    }

    const renderRating = () => {
        if (props.room.rating && props.room.rating > 0) {
            const roundedRating = props.room.rating.toFixed(1);
            return (
                <div className={'row align-items-center'}>
                    <RatingStar className={'mr-5'} />
                    <span className={'main-text text-black'}>{roundedRating}</span>
                </div>
            )
        } else {
            return (
                <NewBadge />
            )
        }

    }

    return (
        <div className={` ${styles.card} col`} style={{width: props.width, height: props.height}}>
            {isFavorite && user && (
                <FavoriteFilled className={styles.favorite} onClick={handleToggleFavorite} />
            )}
            {!isFavorite && user && (
                <FavoriteEmpty className={styles.favorite} onClick={handleToggleFavorite} />
            )}
            <InCardImageSliderComponent images={props.room.images} />
            <div className={`row align-items-center justify-content-between mt-25 `}>
                <span className={`${styles.spotsName}`}>{props.room.title}</span>
                {renderRating()}
            </div>
            <div className={`row gap-15 align-items-start mt-25`}>
                <MusicIcon />
                <span className={`text-black main-text`}>{getIncludesNames()}</span>
            </div>
            <div className={`row mt-25 gap-5`}>
                {user && user.discount && user.discount > 0 && (
                    <span className={`text-price magenta-text`}>{Math.floor((props.room.price - props.room.price * user.discount / 100) * 100 ) /100}€</span>
                )}
                {(!user || !user.discount || user.discount === 0) && (
                    <span className={`text-price magenta-text`}>{props.room.price}€</span>
                )}
                <span className={`text-price magenta-text`} style={{fontWeight: 300}}>/h</span>
                {user && user.discount && user.discount > 0 && (
                    <span className={'text-black'} style={{textDecoration: 'line-through'}}>{props.room.price}€/h</span>
                )}
            </div>
            <div className={'red-button row center-content mt-15 pointer'} style={{width: '100%'}} onClick={()=>navigation(`/rooms/${props.room.id}`)}>
                <span className={'red-button-text'}>Choose time</span>
            </div>
        </div>
    );
};

export default RoomCardComponent;
export {};
