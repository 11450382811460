import React, {useEffect, useState} from 'react';
import TopBar from "../TopBar/TopBar";
import styles from "./MainPage.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import SpotCardComponent from "../SpotCard/SpotCard.component";
import MapComponent from "../Map/Map.component";
import OnMapInfoWindowComponent from "../Map/OnMapInfoWindow.component";
import SearchComponent from "../SearchComponent/Search.component";
import GDPRComponent from "../../common/GDPR/GDPR.component";
import RestorePasswordPopup from "../../common/Popup/RestorePassword.popup";
import PopupComponent from "../../common/Popup/Popup.component";
import {ReactComponent as Card1} from "../../../assets/images/svg/card1.svg";
import {ReactComponent as Card2} from "../../../assets/images/svg/card2.svg";
import {ReactComponent as Card3} from "../../../assets/images/svg/card3.svg";
import {ReactComponent as Card4} from "../../../assets/images/svg/card4.svg";
import EventsComponent from "../Events/events.component";
import axios from "axios";

// ssh-add --apple-use-keychain ~/.ssh/new_gitlab


const MainPage = () => {
    const navigation = useNavigate()
    const [fullAboutUs, setFullAboutUs] = useState(false)
    const [selectedBuilding, setSelectedBuilding] = useState<any>(null)
    const buildings = useSelector((state: RootState)=>state.buildings.buildings) || []
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const recovery = searchParams.get('recovery')
    const [token, setToken] = useState<string>('')
    const [locations, setLocations] = useState<any[]>([])
    const [successRecovery, setSuccessRecovery] = useState<boolean>(false)
    const [errorRecovery, setErrorRecovery] = useState<boolean>(false)
    const spots = useSelector((state: RootState) =>
        state.buildings.buildings.flatMap((building) => building.rooms)
    );
    let showComponent = !!selectedBuilding; // Assuming selectedBuilding is defined and not null or undefined
    const [gdpr, setGdpr] = useState(true)

    function getGDPRStatus() {
        try {
            const gdprValue = localStorage.getItem('@gdpr');
            // Do something with the gdprValue
            if (gdprValue === 'accepted') {
                setGdpr(true)
            } else {
                setGdpr(false)
            }
        }  catch (e:any) {
            console.log(e.message)
        }

    }

    function _handleAcceptGDPR() {
        try {
            localStorage.setItem('@gdpr', 'accepted')
            setGdpr(true)
        }  catch (e:any) {
            console.log(e.message)
        }

    }

    function _handleDenyGDPR() {
        try {
            localStorage.setItem('@gdpr', 'declined')
            setGdpr(true)
        }   catch (e:any) {
            console.log(e.message)
        }

    }

    useEffect(()=>{
        getGDPRStatus();
        if (recovery) {
            setToken(recovery)
        }
    }, [])

    const [events, setEvents] = useState<any[]>([])
    useEffect(()=>{
        axios.get('https://gscoworking.com/api/events/get-all').then((answer: any)=>{
            setEvents(answer.data)
        }).catch((e:any)=>{
            console.log(e.message)
        })
    }, [])

    useEffect(()=>{
        if (buildings) {
            const locations = buildings.map((building) => {
                // Extracting the properties from each building
                const { id, coords, address, rooms } = building;

                // Extracting the 'title' from each room and putting them into an array
                const options = rooms.map((room: { title: any; }) => room.title);

                // Creating a new object with the required properties
                return { id, lat: coords.lat, lng: coords.lng, address, options };
            });
            setLocations(locations)
        }
    }, [buildings])

    function closeInfoWindow() {
        showComponent = false
        setTimeout(()=>{
            setSelectedBuilding(null)
        }, 500)
    }

    return (
        <div className={'pb-100'}>
            <TopBar />
            <div className={styles['overlay-image']}>
                {/*<a href={'/buy-certificate'} className={`col align-items-center justify-content-center ${styles['comingSoon']}`}>*/}
                {/*    <p className={'text-price ma-0'}>Gift Certificates</p>*/}
                {/*</a>*/}
                <img className={styles['header-image']} src={require('../../../assets/images/mobile/header.png')}/>
                <div className={`col justify-content-center ${styles['head-wrapper']}`}>
                    <span className={`text-align-center ${styles['head-phrase']} text-38 mb-20`}>Rent music-point of your dreams</span>

                    <div className={`col-24 ${styles['header-button']} red-button pointer row justify-content-center align-items-center`}  onClick={()=>navigation('/spots/')}>
                        <span className={'red-button-text'}>Choose spot</span>
                    </div>
                    <div className={`col-24 mt-20 sea-button pointer row justify-content-center align-items-center`}  onClick={()=>navigation('/buy-certificate')} style={{marginInline: 'auto'}}>
                        <span className={'red-button-text'}>Gift certificates</span>
                    </div>
                </div>
            </div>
            <SearchComponent />
            <div className={'col my-40 gap-30 justify-content-center align-items-center'}>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card1  />
                    </div>
                    <div className={'col align-items-center'}>
                        <span className={'text-16 bold'}>Booking</span>
                        <p className={'text-14'}>Unique and user-friendly system for booking allows you to choose any time you wish
                        </p>
                    </div>                </div>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card2  />
                    </div>
                    <div className={'col align-items-center' } style={{maxHeight: '40%'}}>
                        <span className={'text-16 bold'}>Equipment</span>
                        <p className={'text-14'}> In our spaces we have all up to date music and DJ equipment for vocalists, drummers, bands and teachers
                        </p>
                    </div>
                </div>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card3  />
                    </div>
                    <div className={'col align-items-center'}>
                        <span className={'text-16 bold'}>Locks</span>
                        <p className={'text-14'}>For your safety and and comfort we have digital locks in each room, so no one disturbs you during your time
                        </p>
                    </div>
                </div>
                <div className={`${styles.card}`}>
                    <div className={styles.cardImg}>
                        <Card4  />
                    </div>
                    <div className={'col align-items-center'}>
                        <span className={'text-16 bold'}>Cozy</span>
                        <p className={'text-14'}> Our space made for your convenience and you find there equipped kitchen, lecture hall and places for relaxing
                        </p>
                    </div>
                </div>
            </div>

              <div className={'mb-50'}>
                  <EventsComponent events={events} />
              </div>

                <section className={'px-20 my-40'}>
                    <p className={'spot-title'}>We are</p>
                    <p className={'main-text'}>GoSound is a space for creation of your any ideas. Our space differs from usual coworkings in that here you have the opportunity to make music and also gather like-minded people in the lecture hall and in an amazing way to get all these in one perfect place. We are sure that the collaboration of artists and other creative people is as effective as possible in such personal format. GoSound brings together those who are willing to know about the latest modern art trends.
                    </p>

                    <p className={'main-text'}>Our studios are perfect for musicians, vocalists, DJs and actors looking for a space to practise their craft.</p>
                    <div className={`${styles.aboutImage}`} style={{backgroundImage: `url(${require('../../../assets/images/weare2.png')})`, backgroundPosition: 'bottom', backgroundSize: 'cover'}}></div>

                </section>
            <div className={'col gap-20'}>
                <span className={'ml-20 spot-title'}>All spots</span>
                <div className={'row mx-20 justify-content-between'}>
                    <span>Choose the room</span>
                    <span className={'blue-text pointer'} onClick={()=>navigation('/spots')}>Show all spots</span>
                </div>
                <div className={'row gap-10 pa-0 no-scroll border-box pl-20'} style={{overflowX: 'auto', width: '100%'}}>

                    {spots.map((spot: any)=>(
                        <SpotCardComponent title={spot.title} image={`https://gscoworking.com/${spot.image}`} id={spot._id} />
                    ))}
                </div>
            </div>
            <div className={'border-box pa-0 mx-20 col mt-40 '} style={{overflow: 'hidden', borderRadius: 8, width: '90%', height: 125}}>
                <MapComponent openPopup={false} width={'100%'} height={125} locations={locations} handleMarkerClick={(location: any)=>setSelectedBuilding(location)} />
            </div>
            {selectedBuilding && (
                                    <OnMapInfoWindowComponent
                                        address={selectedBuilding.address}
                                        options={selectedBuilding.options}
                                        handleBook={() => {}}
                                        handleClose={closeInfoWindow}
                                    />

            )}
            {!gdpr && (
                <GDPRComponent  accept={_handleAcceptGDPR} deny={_handleDenyGDPR}/>
            )}
            {token && (
                <RestorePasswordPopup onClose={()=>setToken('')}  token={token} error={()=>setErrorRecovery(true)} success={()=>setSuccessRecovery(true)}/>
            )}
            {successRecovery && (
                <PopupComponent title={'Password changed'} text={'You password was changed success'} onClose={()=>setSuccessRecovery(false)} buttonText1={'Ok'} onButton1Click={()=>setSuccessRecovery(false)} />
            )}
            {errorRecovery && (
                <PopupComponent title={'Password recovery error'} text={'Error occurred during password recovery. Check provided data and try again'} onClose={()=>setErrorRecovery(false)} buttonText1={'Ok'} onButton1Click={()=>setErrorRecovery(false)} />
            )}
        </div>
    );
};

export default MainPage;
export {};
