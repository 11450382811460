import React from 'react';
import styles from './SpotCard.module.scss'
import {useNavigate} from "react-router-dom";

const SpotCardComponent = (props: {title: string, image: any, id: string}) => {
    const navigation = useNavigate()
    return (
        <div className={styles['spot-card']} onClick={()=>navigation(`/rooms/${props.id}`)}>
            <img className={styles['spot-card-image']} src={`https://gscoworking.com${props.image}`} />
            <span className={styles['spot-card-badge']}>{props.title}</span>
        </div>
    );
};

export default SpotCardComponent;
export {};
